import React, { useState } from "react";

import { Grid, Typography } from "@mui/material";

import { tonsCo2eFromEquipmentFuelGallons } from "@aclymatepackages/calcs/travel";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { Select, ToggleButtons } from "@aclymatepackages/atoms";
import { ucFirstLetters } from "@aclymatepackages/formatters";

import FuelVolumeInput from "../FuelVolumeInput";

import {
  isEmissionsInputSchemaEnabled,
  buildInitialTransactionInputValueFromSchema,
  convertVolumeToGallons,
} from "../../../helpers/components/inputs";

const equipmentTypes = [
  { label: "Ships and Boats", value: "boats" },
  { label: "Locomotives", value: "locomotives" },
  {
    label: "Agricultural Equipment A",
    value: "agricultural-equipment-a",
  },
  {
    label: "Agricultural Offroad Trucks",
    value: "agricultural-offroad-trucks",
  },
  {
    label: "Construction/Mining Equipment B",
    value: "construction-equipment-b",
  },
  {
    label: "Construction/Mining Offroad Trucks",
    value: "construction-offroad-trucks",
  },
  { label: "Lawn and Garden Equipment", value: "lawn-garden" },
  {
    label: "Industrial/Commercial Equipment",
    value: "industrial",
  },
  { label: "Logging Equipment", value: "logging" },
  { label: "Railroad Equipment", value: "railroad" },
  { label: "Recreational Equipment", value: "recreational" },
];

const fuelTypes = [
  {
    value: "gas",
    name: "Gasoline",
    id: "gas",
  },
  {
    value: "diesel",
    name: "Diesel",
    id: "diesel",
  },
  {
    value: "lpg",
    name: "LPG",
    id: "lgp",
  },
];

const EquipmentFuelInputBlock = ({
  editEquipmentFuelData,
  equipmentType,
  billGallons,
  fuelType,
  fuelVolume,
  volumeUnit,
}) => {
  const getAvailableFuelTypesForEquipment = (equipmentType) => {
    const fuelOptionsForEquipments = {
      boats: ["gas", "diesel"],
      locomotives: ["diesel"],
      industrial: ["gas", "diesel", "lpg"],
      logging: ["gas", "diesel"],
      railroad: ["gas", "diesel", "lpg"],
      recreational: ["gas", "diesel", "lpg"],
      "lawn-garden": ["gas", "diesel", "lpg"],
      "agricultural-equipment-a": ["gas", "diesel", "lpg"],
      "agricultural-offroad-trucks": ["gas", "diesel"],
      "construction-equipment-b": ["gas", "diesel", "lpg"],
      "construction-offroad-trucks": ["gas", "diesel"],
    };

    if (equipmentType) {
      const types = fuelOptionsForEquipments[equipmentType];

      return types.map((type) => ({
        value: type,
        name: ucFirstLetters(type),
        id: type,
      }));
    }

    return fuelTypes;
  };

  const onChangeEquipmentType = (newEquipmentType) => {
    const availableFuelTypes =
      getAvailableFuelTypesForEquipment(newEquipmentType);
    if (!availableFuelTypes.find((type) => type.value === fuelType)) {
      editEquipmentFuelData("fuelType")(availableFuelTypes[0].value);
    }

    return editEquipmentFuelData("equipmentType")(newEquipmentType);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6" gutterBottom>
          What kind of equipment is this?
        </Typography>
        <Select
          options={equipmentTypes}
          size="small"
          label="Select Equipment"
          value={equipmentType}
          editValue={onChangeEquipmentType}
        />
      </Grid>
      <Grid item>
        <Typography variant="h6" gutterBottom>
          Gallons of Fuel Purchased
        </Typography>
        <FuelVolumeInput
          billGallons={billGallons}
          fuelVolume={fuelVolume}
          volumeUnit={volumeUnit}
          editTransaction={editEquipmentFuelData}
        />
      </Grid>
      <Grid item style={{ alignSelf: "center" }}>
        <ToggleButtons
          size="small"
          value={fuelType}
          onChange={editEquipmentFuelData("fuelType")}
          buttons={getAvailableFuelTypesForEquipment(equipmentType)}
          style={{ margin: "auto" }}
        />
      </Grid>
    </Grid>
  );
};

const useEquipmentFuelInput = ({ transaction, onSave, setCalcLoading }) => {
  const inputSchema = [
    { field: "fuelType", defaultValue: "gas" },
    { field: "equipmentType" },
    { field: "billGallons" },
    { field: "fuelVolume" },
    { field: "volumeUnit" },
  ];

  const [equipmentFuelData, setEquipmentFuelData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );

  const { fuelType, equipmentType, fuelVolume, volumeUnit, billGallons } =
    equipmentFuelData;

  const convertFuelVolumeToGallons = () => {
    if (fuelVolume && volumeUnit) {
      return convertVolumeToGallons(fuelVolume, volumeUnit);
    }

    return billGallons || 0;
  };

  const tonsCo2e = tonsCo2eFromEquipmentFuelGallons({
    equipmentType,
    fuelType,
    gallons: convertFuelVolumeToGallons(),
  });

  const editEquipmentFuelData = (field) => (value) =>
    editObjectData(setEquipmentFuelData, field, value);

  const onTransactionSave = () => onSave({ ...equipmentFuelData, tonsCo2e });

  const saveEnabled = isEmissionsInputSchemaEnabled(
    equipmentFuelData,
    inputSchema
  );

  return {
    inputBlock: (
      <EquipmentFuelInputBlock
        setCalcLoading={setCalcLoading}
        editEquipmentFuelData={editEquipmentFuelData}
        {...equipmentFuelData}
      />
    ),
    saveEnabled,
    onTransactionSave,
    tonsCo2e,
  };
};
export default useEquipmentFuelInput;
