import React, { useState } from "react";

import { Grid, Button, Typography, Grow } from "@mui/material";

import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { DEFAULT_TONS_CO2E_PER_MILE } from "@aclymatepackages/constants";
import { kmToMiles } from "@aclymatepackages/converters";

import DistanceInput from "../DistanceInput";

import { buildInitialTransactionInputValueFromSchema } from "../../../helpers/components/inputs";

const RentalCarInputBlock = ({
  onRejectTransaction,
  showMileageInput,
  setShowMileageInput,
  fuelFullWarning,
  setFuelFullWarning,
  milesDriven,
  distance,
  editInputData,
}) => {
  const onFuelPurchasedClick = () => {
    if (onRejectTransaction) {
      return onRejectTransaction();
    }
    return setFuelFullWarning(
      "You should enter a fuel transaction instead of a rental car"
    );
  };

  return (
    <Grid
      item
      container
      spacing={2}
      justifyContent="center"
      direction="column"
      alignItems="center"
      style={{ height: "100%" }}
      wrap="nowrap"
    >
      {!showMileageInput ? (
        <Grid item container justifyContent="center">
          <Grid item>
            <Typography variant="h6">
              Did you purchase fuel for this vehicle?
            </Typography>
          </Grid>
          <Grid item container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                onClick={() => {
                  setShowMileageInput(true);
                  setFuelFullWarning(false);
                }}
              >
                No
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={onFuelPurchasedClick}
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grow in={showMileageInput}>
          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">Enter Estimated Mileage</Typography>
            </Grid>
            <Grid item>
              <DistanceInput
                distance={distance}
                editTransaction={editInputData}
                oldDbMileageValue={milesDriven}
              />
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={onFuelPurchasedClick}
              >
                Fuel was purchased for this vehicle
              </Button>
            </Grid>
          </Grid>
        </Grow>
      )}
      {!!fuelFullWarning && (
        <Grid item>
          <Typography
            variant="subtitle2"
            align="center"
            id="rental-car-fuel-full-warning"
          >
            {fuelFullWarning}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const useRentalCarInput = ({
  transaction = {},
  onSave,
  onRejectTransaction,
}) => {
  const { milesDriven: dbMilesDriven, distance: dbDistance } = transaction;

  const [fuelFullWarning, setFuelFullWarning] = useState("");
  const [showMileageInput, setShowMileageInput] = useState(
    !!dbMilesDriven || !!dbDistance
  );

  const inputSchema = [
    { field: "milesDriven" },
    { field: "distance" },
    { field: "distanceUnit" },
  ];
  const [inputData, setInputData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );

  const { milesDriven, distance, distanceUnit } = inputData;

  const editInputData = (field) => (value) =>
    editObjectData(setInputData, field, value);

  const findTonsCo2e = () => {
    if (distance) {
      const distanceMiles =
        distanceUnit === "miles" ? distance : kmToMiles(distance);

      return distanceMiles * DEFAULT_TONS_CO2E_PER_MILE;
    }

    return (milesDriven || 0) * DEFAULT_TONS_CO2E_PER_MILE;
  };

  const tonsCo2e = findTonsCo2e();

  const inputBlock = (
    <RentalCarInputBlock
      onRejectTransaction={onRejectTransaction}
      showMileageInput={showMileageInput}
      setShowMileageInput={setShowMileageInput}
      fuelFullWarning={fuelFullWarning}
      setFuelFullWarning={setFuelFullWarning}
      editInputData={editInputData}
      {...inputData}
    />
  );

  const onTransactionSave = () =>
    onSave({
      ...inputData,
      tonsCo2e,
      milesDriven,
    });

  const saveEnabled =
    (!!milesDriven && numbersRegExpTest(milesDriven)) || !!tonsCo2e;

  return {
    inputBlock,
    onTransactionSave,
    saveEnabled,
    tonsCo2e,
  };
};
export default useRentalCarInput;
