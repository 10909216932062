import React from "react";

import { Grid, Typography, useTheme } from "@mui/material";

import {
  faChartBar,
  faChartScatter,
  faCalculator,
  faCalendarEdit,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DefaultPaper, CategoriesAvatar } from "@aclymatepackages/atoms";
import { formatDecimal } from "@aclymatepackages/formatters";
import { subcategories } from "@aclymatepackages/subcategories";

import ViewBarChart from "../../../../modules/charts/ViewBarChart";
import ViewScatterPlot from "../../../../modules/charts/ViewScatterPlot";
import EmissionsCustomTooltip from "../../../../modules/charts/EmissionsCustomTooltip";
import ViewGraphCardLayout from "../../../../layouts/ViewGraphCard";

import useMeasurementSystem from "../../../../../helpers/hooks/measurementSystem";

const EmployeesScatterPlotCustomTooltip = ({ payload }) => {
  const { carbonUnitLabel } = useMeasurementSystem();

  const {
    name,
    totalMonthlyVolume,
    averageMonthlyNonCommuteEmissionsVolume,
    monthlyCommuteEmissionsVolume,
  } = payload[0]?.payload || {};

  const displayData = [
    {
      title: "Average Monthly Commuting Emissions",
      subtitle: `${formatDecimal(
        monthlyCommuteEmissionsVolume
      )} ${carbonUnitLabel}`,
    },
    {
      title: "Average Monthly Other Emissions",
      subtitle: `${formatDecimal(
        averageMonthlyNonCommuteEmissionsVolume
      )} ${carbonUnitLabel}`,
    },
  ];

  return (
    <DefaultPaper>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h6">{name}</Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
          >{`${formatDecimal(
            totalMonthlyVolume
          )} avg. monthly ${carbonUnitLabel} (Bubble Size)`}</Typography>
        </Grid>
        {displayData.map(({ title, subtitle }, idx) => (
          <Grid item key={`tooltip-display-data-${idx}`}>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="body2" color="textSecondary">
              {subtitle}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </DefaultPaper>
  );
};

const EmployeeBarChart = ({ dataArray, barAreasArray, setSelectedObject }) => {
  const filteredFormattedData = dataArray
    .filter(({ status }) => status !== "terminated")
    .map(
      ({
        id,
        name,
        totalMonthlyVolume,
        monthlyCommuteEmissionsVolume,
        monthlyUtilitiesEmissionsVolume,
        avgMonthlyFlightsEmissions,
        avgMonthlyRentalCarEmissions,
        avgMonthlyRidesEmissions,
        avgMonthlyMileageEmissions,
        avgMonthlyFuelEmissions,
        mostRecentEmissionsVolume,
        defaultTotalEmissions,
      }) => ({
        id,
        emissionsSumTons: totalMonthlyVolume,
        label: name,
        monthlyCommuteEmissionsVolume,
        monthlyUtilitiesEmissionsVolume,
        avgMonthlyFlightsEmissions,
        avgMonthlyRentalCarEmissions,
        avgMonthlyRidesEmissions,
        avgMonthlyMileageEmissions,
        avgMonthlyFuelEmissions,
        mostRecentEmissionsVolume,
        defaultTotalEmissions,
      })
    );

  return (
    <ViewBarChart
      setSelectedObject={setSelectedObject}
      data={filteredFormattedData}
      barAreasArray={barAreasArray}
      tooltipComponent={
        <EmissionsCustomTooltip
          categoriesArray={barAreasArray}
          labelAnnotation="(Monthly Average)"
        />
      }
    />
  );
};

const EmployeeScatterPlot = ({ dataArray, setSelectedObject }) => {
  const { carbonUnitLabel } = useMeasurementSystem();

  return (
    <ViewScatterPlot
      setSelectedObject={setSelectedObject}
      dataArray={dataArray}
      xKey="averageMonthlyNonCommuteEmissionsVolume"
      xName="Monthly Transaction Emissions"
      xLabel={`Monthly Transaction Emissions (${carbonUnitLabel} CO2)`}
      yKey="monthlyCommuteEmissionsVolume"
      yName="Monthly Commuting Emissions"
      yLabel={`Monthly Commute Emissions (${carbonUnitLabel} CO2)`}
      zKey="totalMonthlyVolume"
      zName="Total Monthly Emissions (bubble size)"
      tooltipComponent={
        <EmployeesScatterPlotCustomTooltip type={"employees"} />
      }
      name="Employees"
      color="employees"
    />
  );
};

const EmployeesGraph = ({ arrayOfData, setSelectedObject }) => {
  const { palette } = useTheme();

  const possibleEmployeeSubcategories = subcategories.filter(({ tags = [] }) =>
    tags.includes("employees")
  );

  const allPossibleChartSubcategories = [
    { dataKey: "monthlyCommuteEmissionsVolume", subcategory: "commuting-auto" },
    { dataKey: "monthlyUtilitiesEmissionsVolume", subcategory: "home-office" },
    { dataKey: "avgMonthlyFlightsEmissions", subcategory: "flights" },
    { dataKey: "avgMonthlyRentalCarEmissions", subcategory: "rental-cars" },
    { dataKey: "avgMonthlyRidesEmissions", subcategory: "rides" },
    { dataKey: "avgMonthlyMileageEmissions", subcategory: "mileage" },
    { dataKey: "avgMonthlyFuelEmissions", subcategory: "fuel" },
    {
      dataKey: "mostRecentEmissionsVolume",
      color: palette.primary.light,
      icon: faCalendarEdit,
      name: "Most Recent Emissions",
    },
    {
      dataKey: "defaultTotalEmissions",
      color: palette.backgroundGray.main,
      icon: faCalculator,
      name: "Default Emissions",
    },
  ];

  const uniqueEmployeeSubcategories = [
    ...new Set(
      arrayOfData.flatMap((employee) => {
        const objectEntries = Object.entries(employee);
        return objectEntries
          .filter(
            ([key, value]) =>
              allPossibleChartSubcategories.some(
                (category) => category.dataKey === key
              ) && value
          )
          .map(([key]) => key);
      })
    ),
  ];

  const barAreasArray = uniqueEmployeeSubcategories.map((dataKey) => {
    const { subcategory, ...otherCategoryProps } =
      allPossibleChartSubcategories.find(
        (dataRow) => dataRow.dataKey === dataKey
      );

    if (!subcategory) {
      const { icon } = otherCategoryProps;
      return {
        dataKey,
        subcategory: dataKey,
        ...otherCategoryProps,
        icon: <FontAwesomeIcon icon={icon} size="sm" />,
      };
    }

    const { name, color, shade, icon } = possibleEmployeeSubcategories.find(
      (subcategoryObj) => subcategoryObj.subcategory === subcategory
    );

    const returnColor = color
      ? palette[color][shade || "main"]
      : palette[subcategory].main;

    return {
      name,
      color: returnColor,
      icon,
      dataKey,
      subcategory: dataKey,
      customAvatar: <CategoriesAvatar subcategory={subcategory} />,
    };
  });

  const graphs = [
    {
      title: "Average Monthly Emissions per Employee",
      type: "bar",
      icon: faChartBar,
      Graph: EmployeeBarChart,
      graphProps: {
        barAreasArray,
        setSelectedObject,
      },
      sortable: true,
    },
    {
      title:
        "Average Monthly Commuting Emissions vs. Other Emissions per Employee",
      type: "scatter",
      icon: faChartScatter,
      Graph: EmployeeScatterPlot,
      graphProps: { setSelectedObject },
    },
  ];

  return (
    <ViewGraphCardLayout
      subtitle="NOTE: Former employees aren't shown in the graph since they no longer contribute to your carbon footprint"
      graphs={graphs}
      color="employees"
      dataArray={arrayOfData}
      analyticsCta="to see how each of your employees contributes to your monthly emissions"
    />
  );
};
export default EmployeesGraph;
