import React, { useState } from "react";
import dayjs from "dayjs";

import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  Paper,
  IconButton,
  useTheme,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { ToggleButtons, LoadingButton } from "@aclymatepackages/atoms";
import { EmissionsPieChart } from "@aclymatepackages/modules";
import { formatDecimal } from "@aclymatepackages/formatters";
import { sumTonsCo2e } from "@aclymatepackages/other-helpers";
import {
  convertFootprintToIce,
  footprintToHamburgers,
} from "@aclymatepackages/converters";
import { filterByDateRange } from "@aclymatepackages/date-helpers";

import VendorHeader from "./VendorHeader";

import { useLogout } from "../../helpers/components/accountAccess";
import { useAccountData, useAuth } from "../../helpers/firebase";
import useEmissionsContext from "../../helpers/contexts/emissions";
import { getAccountCollectionAndId } from "../../helpers/otherHelpers";
import { fetchOurApi } from "../../helpers/utils/apiCalls";
import { extractScopeThreeEmissions } from "../../helpers/components/emissions";

const SURVEY_YEAR = dayjs().year() - 1;

const FootprintDataVisualization = ({ emissions, totalSurveyTons }) => {
  const theme = useTheme();

  const [selectedChart, setSelectedChart] = useState("subcategories");

  const comparisonCards = [
    {
      description: `That's equivalent to eating ${formatDecimal(
        footprintToHamburgers(totalSurveyTons)
      )} hamburgers.`,
      image: "64b8458adb4e88d48db9852f_eating-hamburgers",
    },
    {
      description: `That's enough to melt ${formatDecimal(
        convertFootprintToIce(totalSurveyTons)
      )} sq. feet of arctic sea ice.`,
      image: "64b845a4325c7e3eef9f4b10_melting-polar-bear",
    },
  ];

  return (
    <Box
      p={4}
      style={{
        borderRadius: theme.spacing(4),
        backgroundColor: theme.palette.backgroundGray.main,
      }}
    >
      <Grid container spacing={4} alignItems="stretch" wrap="nowrap">
        <Grid item md={5}>
          <Grid container direction="column" spacing={2}>
            <Grid item container justifyContent="center">
              <Grid item>
                <ToggleButtons
                  buttons={[
                    { name: "Categories", value: "subcategories" },
                    { name: "Scopes", value: "scopes" },
                  ]}
                  value={selectedChart}
                  onChange={setSelectedChart}
                />
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <EmissionsPieChart
                viewMode={selectedChart}
                dataArray={emissions}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="center">
                *Hover over the chart to see the breakdown
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={7} container direction="column" spacing={2}>
          {comparisonCards.map(({ image, description }, idx) => (
            <Grid item key={`comparison-card-${idx}`}>
              <Paper elevation={0}>
                <Box p={4}>
                  <Grid container spacing={2} wrap="nowrap" alignItems="center">
                    <Grid item sm={3}>
                      <img
                        alt={description}
                        src={`https://uploads-ssl.webflow.com/646be42f49d4d6d23104386c/${image}.svg`}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item sm={9}>
                      <Typography variant="h5">{description}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

const CreateTrialButton = () => {
  const { id: companyId } = getAccountCollectionAndId();

  const [{ name, employeeCount, geography, industry }] = useAccountData();
  const [user] = useAuth();
  const { email, displayName } = user;

  const [isLoading, setIsLoading] = useState(false);

  const onCreateFreeTrial = async () => {
    setIsLoading(true);
    await fetchOurApi({
      user,
      path: "/onboarding/upgrade-vendor-account",
      method: "POST",
      data: {
        name,
        employeeCount,
        geography,
        industry,
        email,
        displayName,
        companyId,
      },
    });

    return window.location.reload();
  };

  return (
    <LoadingButton
      isLoading={isLoading}
      label="Start Free Trial"
      onClick={onCreateFreeTrial}
      color="primary"
    />
  );
};

const VendorDashboard = () => {
  const theme = useTheme();
  const { id: companyId } = getAccountCollectionAndId();

  const [{ name }] = useAccountData();
  const { allEmissions } = useEmissionsContext();
  const logout = useLogout();

  const formattedEmissions = extractScopeThreeEmissions(allEmissions);
  const filteredEmissions = formattedEmissions.filter((emission) =>
    filterByDateRange({
      filterStartDate: new Date(SURVEY_YEAR, 0, 1),
      filterEndDate: new Date(SURVEY_YEAR, 11, 31),
      ...emission,
    })
  );

  const totalSurveyTons = sumTonsCo2e(filteredEmissions);

  const bullets = [
    "Measure, reduce, & offset your footprint in one place",
    "Customized emissions reports",
    "Pricing for every business size",
  ];

  return (
    <>
      <VendorHeader
        action={
          <IconButton onClick={logout}>
            <LogoutIcon />
          </IconButton>
        }
      />
      <Box p={8}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Typography
              variant="h4"
              align="center"
              color="secondary"
              gutterBottom
            >
              Thank you {name}
            </Typography>
            <Typography variant="h1" align="center" paragraph>
              Survey Completed!
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4" align="center" color="textSecondary">
              Your report has been submitted. If you want to change or update
              your answers, click the button below.
            </Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                href={`/vendor-survey?companyId=${companyId}`}
              >
                Update Answers
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box p={8}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Typography variant="h4" color="secondary" gutterBottom>
              Results
            </Typography>
            <Typography variant="h2">
              Your total emissions for {SURVEY_YEAR}:{" "}
              {formatDecimal(totalSurveyTons)} tons
            </Typography>
            <Typography variant="h6" color="textSecondary">
              Below is a breakdown of your emissions based on the data you
              provided in the survey.{" "}
            </Typography>
          </Grid>
          <Grid item>
            <FootprintDataVisualization
              emissions={filteredEmissions}
              totalSurveyTons={totalSurveyTons}
            />
          </Grid>
        </Grid>
      </Box>
      <Box p={8}>
        <Grid container spacing={4}>
          <Grid
            item
            md={6}
            container
            direction="column"
            spacing={2}
            alignItems="stretch"
          >
            <Grid item>
              <Typography variant="h4" color="secondary" gutterBottom>
                Aclymate's Solution
              </Typography>
              <Typography variant="h2">
                The climate solution for your business
              </Typography>
            </Grid>
            <Grid item>
              {bullets.map((bullet, idx) => (
                <Grid
                  key={`bullet-${idx}`}
                  container
                  spacing={2}
                  alignItems="center"
                  wrap="nowrap"
                >
                  <Grid item>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: theme.palette.secondary.main }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">{bullet}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item>
              <CreateTrialButton />
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Box
              style={{
                height: "100%",
                width: "100%",
                borderRadius: theme.spacing(2),
                backgroundImage: "url('/images/website/vendor-report-cta.jpg')",
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default VendorDashboard;
