import React, { useContext, useState } from "react";

import {
  Avatar,
  Box,
  Button,
  Grid,
  List,
  Typography,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";

import { formatDollars, ucFirstLetters } from "@aclymatepackages/formatters";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";

import ErrorBoundary from "../../atoms/ErrorBoundary";
import ListItem from "../../atoms/mui/ListItem";
import Card from "../../atoms/mui/Card";

import {
  useCategoriesSteps,
  useLocationSteps,
  useMechanismsSteps,
  usePricingSteps,
  useUnSdgsSteps,
  useVerificationStatusSteps,
} from "../../hooks/offsetSurvey";
import SlideLayout from "../../layouts/SlideLayout";
import PlatformOffsetSurvey from "../../modules/projects/PlatformOffsetsSurvey";

import useEmissionsContext from "../../../helpers/contexts/emissions";
import { useCurrentCarbonBalanceTons } from "../../../helpers/hooks/companyData";
import { projectIcons } from "../../../helpers/components/display-lists/projects";
import {
  useCachedFirebaseCrud,
  useAccountData,
  useCachedDisplayData,
} from "../../../helpers/firebase";
import {
  createFactorText,
  formatOffsetPreferencesForScoring,
} from "../../../helpers/components/offsetSurvey";
import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";

const OffsetPreferenceSlideLayoutHeader = ({ icon, title, avatarColor }) => (
  <Grid container spacing={2} wrap="nowrap" alignItems="center">
    <Grid item>
      <Avatar style={{ backgroundColor: avatarColor }}>{icon}</Avatar>
    </Grid>
    <Grid item>
      <Typography variant="h6">{title}</Typography>
    </Grid>
  </Grid>
);

const OffsetPreferenceRow = ({ offsetPreference, allOffsetPreferences }) => {
  const [accountData] = useAccountData();
  const [companyOffices] = useCachedDisplayData("offices");
  const { updateAccountData } = useCachedFirebaseCrud();
  const { activateSnackbar } = useContext(PlatformLayoutContext) || {};
  const [currentCarbonBalanceTons, currentCarbonBalanceTonsLoading] =
    useCurrentCarbonBalanceTons();
  const { averageMonthlyRecurringEmissionsTons, emissionsLoading } =
    useEmissionsContext();
  const { palette } = useTheme();

  const [isEditingFactor, setIsEditingFactor] = useState(false);
  const [editingFactorObj, setEditingFactorObj] = useState(offsetPreference);

  const { factor, rank, ...subPreferences } = offsetPreference;
  const { locations, subcategories, unSdgs, mechanisms, idealPrice, maxPrice } =
    subPreferences;
  const factorList = locations || subcategories || unSdgs;

  const setPreference = (field, value) =>
    editObjectData(setEditingFactorObj, field, value);

  const factorHooks = {
    location: useLocationSteps({
      preference: offsetPreference,
      setPreference,
      showYesNoQuestion: false,
      showRanking: true,
      accountData,
      companyOffices,
    }),
    mechanism: useMechanismsSteps({
      preference: offsetPreference,
      setPreference,
      showYesNoQuestion: false,
      showRanking: true,
    }),
    category: useCategoriesSteps({
      preference: offsetPreference,
      setPreference,
      showYesNoQuestion: false,
      showRanking: true,
    }),
    pricing: usePricingSteps({
      preference: offsetPreference,
      setPreference,
      showYesNoQuestion: false,
      showRanking: true,
      dbDataLoading: currentCarbonBalanceTonsLoading || emissionsLoading,
      accountData: {
        currentCarbonBalanceTons,
        averageMonthlyRecurringEmissionsTons,
      },
    }),
    unSdgs: useUnSdgsSteps({
      preference: offsetPreference,
      setPreference,
      showYesNoQuestion: false,
      showRanking: true,
    }),
    verificationStatus: useVerificationStatusSteps({
      preference: offsetPreference,
      setPreference,
      showYesNoQuestion: false,
      showRanking: true,
    }),
  };
  const { title, input, helperText, rankedPreferences } =
    factorHooks[factor] || {};

  const generateSecondaryText = () => {
    if (!rank) {
      return "We will not use this preference to find offset projects for you.";
    }

    if (factor === "mechanism") {
      return mechanisms?.length > 1
        ? "All mechanisms"
        : ucFirstLetters(mechanisms[0]);
    }

    if (factor === "pricing") {
      return `Ideal price: ${formatDollars(
        idealPrice
      )}; Max price: ${formatDollars(maxPrice)}`;
    }

    if (factor === "verificationStatus") {
      return "You prefer that offset projects should be verified";
    }

    return factorList.map(
      ({ factor }, idx) =>
        `${ucFirstLetters(factor)}${idx !== factorList?.length - 1 ? `, ` : ``}`
    );
  };

  const preferenceIcon = projectIcons.find(({ tags }) =>
    tags.includes(factor)
  )?.icon;

  const saveEditingFactorChanges = async () => {
    const { factor: editedFactor, rank: editedRank } = editingFactorObj;
    const { rank: originalFactorRank } = offsetPreference;

    const filteredOffsetPreferences = allOffsetPreferences.filter(
      ({ factor }) => factor !== editedFactor
    );
    const rankedItemsName =
      editedFactor === "location"
        ? "locations"
        : editedFactor === "category"
        ? "subcategories"
        : editedFactor;
    const newRankedItemsObj = rankedPreferences
      ? { [rankedItemsName]: rankedPreferences }
      : {};
    const newFactorObj = {
      ...editingFactorObj,
      ...newRankedItemsObj,
      rank: !editedRank ? 0 : originalFactorRank,
    };
    const newOffsetPreferences = [...filteredOffsetPreferences, newFactorObj];

    setIsEditingFactor(false);
    activateSnackbar({
      message: `Your ${createFactorText(
        factor
      )} preferences were successfully saved.`,
      alert: "success",
    });

    const newFormattedOffsetPreferences =
      await formatOffsetPreferencesForScoring(newOffsetPreferences);
    return updateAccountData({
      offsetPreferences: newFormattedOffsetPreferences,
    });
  };

  return (
    <>
      {isEditingFactor && (
        <SlideLayout
          isSlideOpen
          setIsSlideOpen={setIsEditingFactor}
          header={
            <OffsetPreferenceSlideLayoutHeader
              icon={preferenceIcon}
              title={title}
              avatarColor={palette.primary.main}
            />
          }
          content={
            <Box p={2}>
              <Grid container justifyContent="center" spacing={2}>
                {helperText && (
                  <Grid item xs={12}>
                    <Typography variant="h6" align="center">
                      {helperText}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {input}
                </Grid>
              </Grid>
            </Box>
          }
          footer={
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => saveEditingFactorChanges()}
                >
                  Save changes
                </Button>
              </Grid>
            </Grid>
          }
        />
      )}
      <ListItem
        avatar={preferenceIcon}
        avatarColor={rank ? palette.primary.main : palette.primary.greyScale}
        primaryText={createFactorText(factor)}
        secondaryText={generateSecondaryText()}
        actions={[
          {
            tooltip: `Edit ${createFactorText(factor)} preference`,
            icon: <EditIcon />,
            onClick: () => setIsEditingFactor(true),
            disabled: !rank,
          },
        ]}
      />
    </>
  );
};

const OffsetPreferencesCard = ({
  companyDataLoading,
  offsetPreferences = [],
}) => {
  const [showOffsetSurvey, setShowOffsetSurvey] = useState(false);

  const sortedOffsetPreferences = [
    ...offsetPreferences
      .filter(({ rank }) => rank)
      .sort((a, b) => a.rank - b.rank),
    ...offsetPreferences.filter(({ rank }) => !rank),
  ];

  return (
    <>
      {showOffsetSurvey && (
        <PlatformOffsetSurvey setShowSurvey={setShowOffsetSurvey} />
      )}
      <Grid container item xs={6}>
        <Card
          cardType="layout"
          title="Offset Preferences"
          subtitle={
            sortedOffsetPreferences?.length
              ? "These are the preferences you created when you filled out the offset preferences quiz. They are displayed in the order that you ranked them. "
              : "Your preferences will appear here once you complete the offset preferences quiz."
          }
          icon={<FontAwesomeIcon icon={faLeaf} style={{ color: "white" }} />}
          isLoading={companyDataLoading}
          contentType="list"
          style={{ paddingBottom: "25px" }}
        >
          <Grid container spacing={2}>
            {sortedOffsetPreferences?.length && (
              <Grid item xs={12}>
                <List>
                  {sortedOffsetPreferences.map((offsetPreference) => (
                    <OffsetPreferenceRow
                      offsetPreference={offsetPreference}
                      allOffsetPreferences={sortedOffsetPreferences}
                    />
                  ))}
                </List>
              </Grid>
            )}
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowOffsetSurvey(true)}
              >
                {sortedOffsetPreferences?.length
                  ? "Reset preferences"
                  : "Take the quiz"}
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

const OffsetsPreferences = () => {
  const [{ offsetPreferences }, companyDataLoading] = useAccountData();

  return (
    <ErrorBoundary>
      <OffsetPreferencesCard
        companyDataLoading={companyDataLoading}
        offsetPreferences={offsetPreferences}
      />
    </ErrorBoundary>
  );
};
export default OffsetsPreferences;
