import { useState } from "react";

import { Grid, Typography } from "@mui/material";

import { ToggleButtons } from "@aclymatepackages/atoms";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { isStateInNortheastCorridor } from "@aclymatepackages/other-helpers";
import { trainTransportationCarbon } from "@aclymatepackages/calcs/travel";

import DistanceInputOptions from "../DistanceInputOptions";

import {
  buildInitialTransactionInputValueFromSchema,
  findDistanceMiles,
} from "../../../helpers/components/inputs";

const TrainInput = ({
  emissionData,
  editEmissionData,
  setCalculatedMileage,
  setCalcLoading,
  rideMileageEstimate,
  trainType,
  setTrainType,
}) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <DistanceInputOptions
          type="Train"
          inputData={emissionData}
          editInputData={editEmissionData}
          setCalculatedMileage={setCalculatedMileage}
          setCalcLoading={setCalcLoading}
          oldDbMileageValue={rideMileageEstimate}
        />
      </Grid>
      <Grid item>
        <Typography variant="h6" gutterBottom>
          What type of train are you riding?
        </Typography>
        <ToggleButtons
          buttons={[
            { name: "Commuter Rail", value: "commuterRail" },
            { name: "Metro Rail", value: "metroRail" },
            { name: "Amtrak", value: "intercityRail" },
          ]}
          value={trainType}
          onChange={setTrainType}
        />
      </Grid>
    </Grid>
  );
};

const useTrainInput = ({ transaction, onSave, setCalcLoading }) => {
  const inputSchema = [
    { field: "rideFrom" },
    { field: "from" }, //Same as RidesInputBlock for why we need this
    { field: "rideTo" },
    { field: "to" },
    { field: "rideMileageEstimate" },
    { field: "distance" },
    { field: "distanceUnit" },
    { field: "trainType" },
  ];

  const [emissionData, setEmissionData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );
  const [calculatedMileage, setCalculatedMileage] = useState(0);

  const {
    from,
    rideFrom,
    to,
    rideTo,
    rideMileageEstimate,
    distance,
    distanceUnit,
    trainType,
  } = emissionData;

  const editEmissionData = (field) => (value) =>
    editObjectData(setEmissionData, field, value);

  const isRideToAndRideFromInNortheastCorridor = () => {
    if (to && from) {
      const { state: rideToState } = to || {};
      const { state: rideFromState } = from || {};

      return (
        isStateInNortheastCorridor(rideToState) &&
        isStateInNortheastCorridor(rideFromState)
      );
    }

    return false;
  };

  const tonsCo2e = trainTransportationCarbon(
    trainType,
    findDistanceMiles(emissionData, calculatedMileage) || rideMileageEstimate,
    isRideToAndRideFromInNortheastCorridor()
  );

  const descriptionObj =
    rideTo && rideFrom
      ? { description: `${rideFrom.description} -> ${rideTo.description}` }
      : {};

  const onTransactionSave = () =>
    onSave({
      ...descriptionObj,
      ...emissionData,
      tonsCo2e,
    });

  const saveEnabled =
    (rideTo && rideFrom && trainType) ||
    (!!distance && numbersRegExpTest(distance) && trainType && distanceUnit) ||
    tonsCo2e;

  return {
    inputBlock: (
      <TrainInput
        emissionData={{
          ...emissionData,
          from: from || rideFrom,
          to: to || rideTo,
        }}
        editEmissionData={editEmissionData}
        setCalculatedMileage={setCalculatedMileage}
        setCalcLoading={setCalcLoading}
        rideMileageEstimate={rideMileageEstimate}
        trainType={trainType}
        setTrainType={editEmissionData("trainType")}
      />
    ),
    saveEnabled,
    onTransactionSave,
    tonsCo2e,
  };
};

export default useTrainInput;
