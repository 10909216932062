import React, { useState, useContext } from "react";
import {
  Avatar,
  Box,
  Grid,
  Button,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
  Chip,
  StyledEngineProvider,
  useTheme,
  ThemeProvider,
} from "@mui/material";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import WarningIcon from "@mui/icons-material/Warning";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCarBus } from "@fortawesome/pro-solid-svg-icons";

import { faLaptopHouse } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TextField } from "@aclymatepackages/atoms";
import {
  formatDate,
  formatDecimal,
  ucFirstLetters,
} from "@aclymatepackages/formatters";
import { mergeDarkTheme } from "@aclymatepackages/themes";
import {
  findMonthlyUtilitiesEmissionsFromCommuteSchedules,
  buildMonthlyCommuteEmissionsArray,
  findEmployeesCommuteSchedules,
} from "@aclymatepackages/calcs/recurring";
import { STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES } from "@aclymatepackages/constants";
import { truncateText } from "@aclymatepackages/other-helpers";

import EmployeeDetailsSlider from "./EmployeesDetailsSlider";
import EmployeesGraph from "./EmployeesGraph";

import EditRowTransactions from "../../EditRowTransactions";

import DownloadButton from "../../../../atoms/buttons/DownloadButton";
import ErrorBoundary from "../../../../atoms/ErrorBoundary";
import useStatusFilterChips from "../../../../hooks/statusFilterChips";
import DashboardViewLayout from "../../../../layouts/DashboardViewLayout";
import { MissingEmployees } from "../../../../layouts/missingSettingsLayouts";
import AddEmployeesInputs from "../../../../inputs/employees/AddEmployeesInputs";
import SortableTable, {
  makeColumnObj,
} from "../../../../modules/tables/SortableTable";
import ReductionsBlogModule from "../../../../modules/ReductionsBlogModule";

import {
  useEmployeesData,
  sendEmployeeSurvey,
} from "../../../../../helpers/components/employees";
import { useAllVehicleTypes } from "../../../../../helpers/components/vehicles";
import { useAllOfficeTypes } from "../../../../../helpers/components/offices";
import { splitAggregatedTransactionsBreakdownSubcategories } from "../../../../../helpers/components/emissions";

import {
  useAccountData,
  useCollectionDataListener,
} from "../../../../../helpers/firebase";
import useEmissionsContext from "../../../../../helpers/contexts/emissions";
import { PlatformLayoutContext } from "../../../../../helpers/contexts/platformLayout";
import { useStarterTierSubscriptionFlags } from "../../../../../helpers/hooks/companyData";
import { employeeStatuses } from "../../../../../helpers/components/employees";
import useMeasurementSystem from "../../../../../helpers/hooks/measurementSystem";

const EmployeeDownloadCSVButton = ({ displayEmployees }) => {
  const prepareCSV = () => {
    const csvHeader = ["NAME", "EMAIL", "STATUS"];

    const csvRows = displayEmployees
      .sort((a, b) => (a.status === "unconfirmed" ? -1 : 1))
      .map((employee) => [
        employee.name,
        employee.email,
        employee.status === "confirmed" ? "Done" : "Not Completed",
      ]);

    const csvContent = [
      csvHeader.join(","),
      ...csvRows.map((row) => row.join(",")),
    ].join("\n");

    return new Blob([csvContent], { type: "text/csv" });
  };
  return (
    <DownloadButton
      tooltip={"Download Employee CSV"}
      buildFile={prepareCSV}
      fileName="employee-list.csv"
    />
  );
};

const EmployeeRowCommuteAvatars = ({
  status,
  currentEndpoints = [],
  electricRenewablesPercentage,
}) => {
  const { palette } = useTheme();

  const officeTypes = useAllOfficeTypes("sm");

  if (status === "terminated" || !currentEndpoints) {
    return <></>;
  }

  const findOfficeObj = (type) =>
    officeTypes.find((categoryObj) => categoryObj.type === type);

  return (
    <Grid container spacing={1} wrap="nowrap">
      {status !== "confirmed" && !!currentEndpoints.length && (
        <Grid item>
          <Tooltip title="This is only this employee's most recent schedule and not their current schedule">
            <Avatar
              style={{
                backgroundColor: palette.error.main,
                height: "36px",
                width: "36px",
              }}
            >
              <ErrorOutlineIcon />
            </Avatar>
          </Tooltip>
        </Grid>
      )}
      {[...currentEndpoints]
        .sort((a, b) => b.daysPerWk - a.daysPerWk)
        .map(({ type, name, daysPerWk }, idx, arr) => (
          <Grid item key={`endpoint-display-avatar-${idx}`}>
            <Tooltip
              title={`${name || "Home"}- ${daysPerWk} days per week. ${
                electricRenewablesPercentage && type === "homeOffice"
                  ? ` ${electricRenewablesPercentage}% of this home office is covered by renewable energy source`
                  : ""
              }  `}
            >
              <Avatar
                style={{
                  backgroundColor:
                    electricRenewablesPercentage && type === "homeOffice"
                      ? palette.secondary.main
                      : findOfficeObj(type)?.color,
                  height: "36px",
                  width: "36px",
                }}
              >
                {findOfficeObj(type)?.icon}
              </Avatar>
            </Tooltip>
          </Grid>
        ))}
    </Grid>
  );
};

const EmployeeRowVehicleChips = ({ vehicles = [] }) => {
  const { palette } = useTheme();

  const vehicleTypes = useAllVehicleTypes();
  const { icon: vehicleIcon } = vehicleTypes.find(
    ({ type }) => type === "personal"
  );

  return (
    <>
      {!!vehicles.length && (
        <Grid container spacing={1}>
          {vehicles
            .filter(({ archived }) => !archived)
            .map(({ name, description, make, model, year }, idx) => (
              <Grid item key={`employee-row-vehicle-chip-${idx}`}>
                <Tooltip title={`${year} ${make} ${model}`}>
                  <span>
                    <Chip
                      style={{
                        backgroundColor: palette.vehicles.main,
                        color: "white",
                      }}
                      label={ucFirstLetters(truncateText(name || description))}
                      icon={
                        <FontAwesomeIcon
                          icon={vehicleIcon}
                          style={{ color: "white" }}
                        />
                      }
                      size="small"
                    />
                  </span>
                </Tooltip>
              </Grid>
            ))}
        </Grid>
      )}
    </>
  );
};

const EmployeeRowMonthlyVolume = ({
  status,
  totalMonthlyVolume,
  mostRecentEmissionsVolume,
  defaultTotalEmissions,
}) => {
  const { carbonUnitLabel } = useMeasurementSystem();

  const buildBaseString = (value) =>
    `${formatDecimal(value)} ${carbonUnitLabel}`;

  if (status === "confirmed") {
    return <>{buildBaseString(totalMonthlyVolume)}</>;
  }

  if (status === "unconfirmed") {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>{buildBaseString(defaultTotalEmissions)}</Grid>
        <Grid item>
          <Tooltip title="Emissions for unconfirmed employees are estimated from your company's industry, size and location.">
            <HelpOutlineIcon />
          </Tooltip>
        </Grid>
      </Grid>
    );
  }

  const tooltip =
    status === "onLeave"
      ? "Employees on leave don't count towards your current balance but this is how much they were emitting when they last worked"
      : "We're estimating this employee's emissions based on the recent commuting schedule since their profile is currently incomplete";

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        {buildBaseString(mostRecentEmissionsVolume || totalMonthlyVolume)}
      </Grid>
      <Grid item>
        <Tooltip title={tooltip}>
          <HelpOutlineIcon />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const EmployeeRow = ({ onClick, employee }) => {
  const {
    status,
    name,
    email,
    vehicles = [],
    commuteSchedules,
    mostRecentEmissionsVolume,
    totalMonthlyVolume,
    defaultTotalEmissions,
    endDate,
  } = employee;

  const { palette } = useTheme();

  const [isSurveySent, setIsSurveySent] = useState(false);

  const { icon, tooltip, color } = employeeStatuses[status] || {};

  const safelyDestructureCommuteSchedules = () => {
    if (!Array.isArray(commuteSchedules)) {
      return { home: {}, currentEndpoints: [] };
    }
    const [{ commuteEndpoints: currentEndpoints = [], home = {} }] =
      commuteSchedules || [{}];

    return { currentEndpoints, home };
  };

  const { currentEndpoints, home } = safelyDestructureCommuteSchedules();

  const {
    isElectricRenewablesPercentageGiven = false,
    electricRenewablesPercentage = 0,
  } = home;

  const buildCellsByStatus = () => {
    if (status === "terminated") {
      return (
        <TableCell colSpan={3}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            align="center"
          >{`This employee hasn't contributed to your emissions since their last day on ${formatDate(
            endDate
          )}`}</Typography>
        </TableCell>
      );
    }

    if (status === "unconfirmed") {
      return (
        <>
          <TableCell colSpan={2}>
            <Box
              p={1}
              style={{
                backgroundColor: palette.error.main,
                borderRadius: "5px",
              }}
            >
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={mergeDarkTheme}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    wrap="nowrap"
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      container
                      spacing={1}
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <WarningIcon style={{ color: "white" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2" color="textPrimary">
                          This employee still hasn't filled out their employee
                          survey
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      {!isSurveySent ? (
                        <Button
                          size="small"
                          variant="outlined"
                          style={{ whiteSpace: "nowrap" }}
                          onClick={(e) => {
                            sendEmployeeSurvey({ employee });
                            setIsSurveySent(true);
                            return e.stopPropagation();
                          }}
                        >
                          Resend Survey
                        </Button>
                      ) : (
                        <>
                          <Grid item style={{ marginRight: "16px" }}>
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="2x"
                              style={{ color: palette.secondary.main }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2" color="textPrimary">
                              Survey has been sent!
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </StyledEngineProvider>
            </Box>
          </TableCell>
          <TableCell>
            <EmployeeRowMonthlyVolume
              status={status}
              totalMonthlyVolume={totalMonthlyVolume}
              mostRecentEmissionsVolume={mostRecentEmissionsVolume}
              defaultTotalEmissions={defaultTotalEmissions}
            />
          </TableCell>
        </>
      );
    }

    return (
      <>
        <TableCell>
          <EmployeeRowVehicleChips vehicles={vehicles} />
        </TableCell>
        <TableCell>
          <EmployeeRowCommuteAvatars
            status={status}
            currentEndpoints={currentEndpoints}
            electricRenewablesPercentage={electricRenewablesPercentage}
            isElectricRenewablesPercentageGiven={
              isElectricRenewablesPercentageGiven
            }
          />
        </TableCell>
        <TableCell>
          <EmployeeRowMonthlyVolume
            status={status}
            totalMonthlyVolume={totalMonthlyVolume}
            mostRecentEmissionsVolume={mostRecentEmissionsVolume}
            defaultTotalEmissions={defaultTotalEmissions}
          />
        </TableCell>
      </>
    );
  };

  return (
    <TableRow hover style={{ cursor: "pointer" }} onClick={onClick}>
      <TableCell>
        <Tooltip title={tooltip || ""}>
          <div>
            <FontAwesomeIcon icon={icon} size="2x" style={{ color }} />
          </div>
        </Tooltip>
      </TableCell>
      <TableCell>
        <div>{email}</div>
        <Typography variant="caption" color="textSecondary">
          {name}
        </Typography>
      </TableCell>
      {buildCellsByStatus()}
    </TableRow>
  );
};

const EmployeesDisplayBlock = ({
  employeeSearchString,
  setEmployeeSearchString,
  setAddEmployees,
  setSelectedEmployee,
  displayEmployees,
  filterPopperProps,
  chips,
  viewLoading,
  employeeNotifications,
}) => {
  const { viewMode } = useContext(PlatformLayoutContext);
  const { isEmployeeLimitReached } = useStarterTierSubscriptionFlags();

  const isNotCompanyViewMode = viewMode !== "company";

  const tableColumns = [
    makeColumnObj("STATUS", "status", true),
    makeColumnObj("EMAIL/NAME", "email", true),
    makeColumnObj("VEHICLES"),
    makeColumnObj("CURRENT WORKSPACES"),
    makeColumnObj("MONTHLY EMISSIONS", "totalMonthlyVolume", true),
  ];

  const setSelectedEmployeeFromGraphs = (id) =>
    setSelectedEmployee(
      displayEmployees.find((employee) => employee.id === id)
    );

  return (
    <>
      <DashboardViewLayout
        viewLoading={viewLoading}
        alerts={employeeNotifications}
        type="employees"
        title="Employees"
        filterPopperProps={filterPopperProps}
        chips={chips}
        primaryAction={
          <Tooltip
            title={
              isEmployeeLimitReached
                ? `You have reached the ${STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES} employee limit. Upgrade your subscription to add more employees`
                : "Adding new employees is disabled in non-company view modes"
            }
            disableHoverListener={
              !isNotCompanyViewMode && !isEmployeeLimitReached
            }
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setAddEmployees(true)}
                disabled={isNotCompanyViewMode || isEmployeeLimitReached}
              >
                Add New Employees
              </Button>
            </span>
          </Tooltip>
        }
        premiumActions={[
          <EmployeeDownloadCSVButton displayEmployees={displayEmployees} />,
          <TextField
            label="Search Employees"
            value={employeeSearchString}
            setValue={setEmployeeSearchString}
          />,
        ]}
        graph={
          <EmployeesGraph
            arrayOfData={[...displayEmployees].sort(
              (a, b) => a.totalMonthlyVolume - b.totalMonthlyVolume
            )}
            setSelectedObject={setSelectedEmployeeFromGraphs}
          />
        }
        table={
          <SortableTable
            columns={tableColumns}
            rows={[...displayEmployees].sort((a, b) => b.severity - a.severity)}
            rowComponent={(employee, idx) => (
              <EmployeeRow
                key={`employee-row-${employee?.id || idx}`}
                onClick={() => setSelectedEmployee(employee)}
                employee={employee}
              />
            )}
          />
        }
        footer={<ReductionsBlogModule type="employee" />}
      />
    </>
  );
};

//TODO: BUG- check the filtering, it doesn't seem like it's working properly
const EmployeesViewBlock = ({ viewLoading }) => {
  const [employeesAggregatedTransactions] = useCollectionDataListener(
    "employees-aggregated-transactions"
  );
  const { emissionsMonths } = useEmissionsContext();

  const [{ startDate }] = useAccountData();

  const {
    employeesLoading,
    addEmployees,
    setAddEmployees,
    employeeNotifications,
    employees,
  } = useEmployeesData();

  const { filterChips, filterByChips } = useStatusFilterChips(
    employees,
    employeeStatuses
  );

  const pageLoading = employeesLoading || viewLoading;

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [selectedCommutingMethods, setSelectedCommutingMethods] = useState([]);
  const [employeeSearchString, setEmployeeSearchString] = useState("");

  const resetFilters = () => {
    setSelectedWorkspaces([]);
    return setSelectedCommutingMethods([]);
  };

  const buildFilterFunctions = () => {
    const findVehiclesByType = (type, commuteEndpoints = []) => {
      const endpointsWithVehicles = commuteEndpoints.filter(
        (endpoint) => endpoint.vehicles
      );

      const vehiclesFromEndpoints = endpointsWithVehicles.map(
        ({ vehicles }) => vehicles
      );

      const formattedVehicles = vehiclesFromEndpoints.reduce(
        (acc, vehicles) => [...acc, ...vehicles],
        []
      );

      return formattedVehicles.filter((vehicle) => {
        if (type === "car") {
          return (
            vehicle.id !== "carpool" &&
            vehicle.id !== "walkBike" &&
            vehicle.id !== "lightRail" &&
            vehicle.id !== "bus" &&
            vehicle.id !== "regionalRail" &&
            vehicle.id !== "intercityRail"
          );
        }
        return vehicle.id === type;
      });
    };

    const findCommuteVehicles = (type) =>
      employees.map(({ commuteSchedules = [{}] }) => {
        if (!Array.isArray(commuteSchedules)) {
          return [];
        }

        const [{ commuteEndpoints }] = commuteSchedules;
        return findVehiclesByType(type, commuteEndpoints);
      });

    const vehicleCommuteEndpoints = findCommuteVehicles("car");
    const lightRailCommuteEndpoints = findCommuteVehicles("lightRail");
    const regionalRailCommuteEndpoints = findCommuteVehicles("regionalRail");
    const intercityRailCommuteEndpoints = findCommuteVehicles("intercityRail");
    const busCommuteEndpoints = findCommuteVehicles("bus");
    const walkBikeCommuteEndpoints = findCommuteVehicles("walkBike");
    const carpoolCommuteEndpoints = findCommuteVehicles("carpool");

    const typesOfCommuting = [
      { type: vehicleCommuteEndpoints, caption: "Car", slug: "car" },
      {
        type: lightRailCommuteEndpoints,
        caption: "Light Rail/Subway",
        slug: "lightRail",
      },
      {
        type: regionalRailCommuteEndpoints,
        caption: "Regional Rail",
        slug: "regionalRail",
      },
      {
        type: intercityRailCommuteEndpoints,
        caption: "Intercity Rail",
        slug: "intercityRail",
      },
      { type: busCommuteEndpoints, caption: "Bus", slug: "bus" },
      { type: carpoolCommuteEndpoints, caption: "Carpool", slug: "carpool" },
      {
        type: walkBikeCommuteEndpoints,
        caption: "Walking/Biking",
        slug: "walkBike",
      },
    ];

    const findCommutingTypes = () => {
      const commutingOptions = typesOfCommuting.filter(({ type }) => {
        const nonEmptyEndpoints = type?.filter(
          (endpoints) => endpoints?.length
        );

        return nonEmptyEndpoints?.length;
      });

      return commutingOptions.map((commuteType) => ({
        name: commuteType.caption,
      }));
    };

    const findWorkspace = (officeType) =>
      employees.map(({ commuteSchedules = [{}] }) => {
        if (!Array.isArray(commuteSchedules)) {
          return [];
        }

        const [{ commuteEndpoints = [{}] }] = commuteSchedules;
        return commuteEndpoints.filter(({ type }) =>
          officeType === "other"
            ? type !== "companyOffice" && type !== "homeOffice"
            : type === officeType
        );
      });

    const homeOfficeCommuteEndpoints = findWorkspace("homeOffice");
    const companyOfficeCommuteEndpoints = findWorkspace("companyOffice");
    const otherOfficeCommuteEndpoints = findWorkspace("other");

    const typesOfWorkspaces = [
      {
        type: companyOfficeCommuteEndpoints,
        caption: "Company Workspaces",
        slug: "companyOffice",
      },
      {
        type: homeOfficeCommuteEndpoints,
        caption: "Home Office",
        slug: "homeOffice",
      },
      {
        type: otherOfficeCommuteEndpoints,
        caption: "Other Workspaces",
        slug: "otherOffice",
      },
    ];

    const findWorkspaceTypes = () => {
      const workspacesOptions = typesOfWorkspaces.filter(({ type }) => {
        const nonEmptyEndpoints = type.filter((endpoints) => endpoints.length);
        return nonEmptyEndpoints.length;
      });

      return workspacesOptions.map((workspace) => ({
        name: workspace.caption,
      }));
    };

    const findWorkspaceByType = (workspaceSlug, commuteEndpoints) =>
      commuteEndpoints.find((endpoint) => endpoint.type === workspaceSlug);

    const commuteSchedulesFilterFunction =
      (filterVariable, filterFunction, filterVariableList) =>
      ({ commuteSchedules }) => {
        if (!filterVariable.length) {
          return true;
        }

        if (!Array.isArray(commuteSchedules) || !commuteSchedules?.length) {
          return false;
        }

        const [{ commuteEndpoints }] = commuteSchedules;

        const filterVariableSlugs = filterVariable.map(
          ({ name }) =>
            filterVariableList.find((type) => type.caption === name)?.slug
        );

        return filterVariableSlugs.some((methodSlug) =>
          filterFunction(methodSlug, commuteEndpoints)
        );
      };

    const filterByCommuteVehicle = commuteSchedulesFilterFunction(
      selectedCommutingMethods,
      findVehiclesByType,
      typesOfCommuting
    );
    const filterByWorkspace = commuteSchedulesFilterFunction(
      selectedWorkspaces,
      findWorkspaceByType,
      typesOfWorkspaces
    );

    const filterBySearch = ({ email, name }) => {
      if (!employeeSearchString) {
        return true;
      }

      return (
        name.includes(employeeSearchString) ||
        email.includes(employeeSearchString)
      );
    };

    const applyFilters = () =>
      employees.filter(
        (employee) =>
          filterByChips(employee) &&
          filterByCommuteVehicle(employee) &&
          filterByWorkspace(employee) &&
          filterBySearch(employee)
      );

    const filterRows = [
      {
        icon: faLaptopHouse,
        title: "Workspaces",
        autocomplete: {
          availableOptions: findWorkspaceTypes(),
          value: selectedWorkspaces,
          setValue: setSelectedWorkspaces,
          label: "Workspace Type",
        },
      },
      {
        icon: faCarBus,
        title: "Commuting Method",
        autocomplete: {
          availableOptions: findCommutingTypes(),
          value: selectedCommutingMethods,
          setValue: setSelectedCommutingMethods,
          label: "Commuting Methods",
        },
      },
    ];

    return {
      filterRows,
      applyFilters,
      displayEmployeesWithEmissions: employees,
    };
  };

  const { filterRows, applyFilters } = buildFilterFunctions();

  const filteredEmployees = applyFilters();

  const filterPopperProps = {
    filterRows,
    resetFilters,
    title: "Filter Employees",
  };

  const buildSelectedEmployeeEmissionsArray = (selectedEmployee) => {
    const { startDate: employeeStartDate, id } = selectedEmployee;
    const { aggregatedTransactions: taggedEmployeesTransactions = [] } =
      employeesAggregatedTransactions.find(
        ({ individualId }) => individualId === id
      ) || {};

    const emissionsMonthsWithoutDefaults = emissionsMonths.map((month) => ({
      ...month,
      defaultEmissions: {},
    }));

    const selectedEmployeeCommuteSchedules = findEmployeesCommuteSchedules(
      employeeStartDate || startDate,
      [selectedEmployee]
    );

    const monthlyCommuteEmissionsTons = buildMonthlyCommuteEmissionsArray({
      employeesSchedules: selectedEmployeeCommuteSchedules,
      emissionsMonths: emissionsMonthsWithoutDefaults,
    });

    const homeOfficeEmissions =
      findMonthlyUtilitiesEmissionsFromCommuteSchedules({
        employees: [selectedEmployee],
        additionalProps: {
          name: "Home Office",
        },
        emissionsMonths,
        companyStartDate: startDate,
      }).map((emission) => ({ ...emission, subcategory: "home-office" }));

    return [
      ...splitAggregatedTransactionsBreakdownSubcategories(
        taggedEmployeesTransactions
      ),
      ...monthlyCommuteEmissionsTons,
      ...homeOfficeEmissions,
    ];
  };

  return (
    <>
      {addEmployees && <AddEmployeesInputs onClose={setAddEmployees} />}
      {selectedEmployee && (
        <EmployeeDetailsSlider
          emissions={buildSelectedEmployeeEmissionsArray(selectedEmployee)}
          setSelectedTransaction={setSelectedTransaction}
          employee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
        />
      )}
      {selectedTransaction && (
        <EditRowTransactions
          transaction={selectedTransaction || {}}
          setIsSlideOpen={setSelectedTransaction}
          saveButtonText="Save Changes"
        />
      )}
      {pageLoading || employees.length ? (
        <EmployeesDisplayBlock
          viewLoading={pageLoading}
          employeeNotifications={employeeNotifications}
          employeeSearchString={employeeSearchString}
          setEmployeeSearchString={setEmployeeSearchString}
          displayEmployees={filteredEmployees}
          setAddEmployees={setAddEmployees}
          setSelectedEmployee={setSelectedEmployee}
          filterPopperProps={filterPopperProps}
          chips={filterChips}
        />
      ) : (
        <MissingEmployees setAddEmployees={setAddEmployees} />
      )}
    </>
  );
};

const EmployeesView = (props) => (
  <ErrorBoundary>
    <EmployeesViewBlock {...props} />
  </ErrorBoundary>
);
export default EmployeesView;
