import React, { useState } from "react";

import { Grid } from "@mui/material";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { Checkbox } from "@aclymatepackages/atoms";
import { DEFAULT_TONS_CO2E_PER_MILE } from "@aclymatepackages/constants";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";

import DistanceInputOptions from "../DistanceInputOptions";
import RentalOrCompanyVehicleButtons from "../../atoms/buttons/RentalOrCompanyVehicleButtons";

import {
  buildInitialTransactionInputValueFromSchema,
  findDistanceMiles,
} from "../../../helpers/components/inputs";

const MileageInput = ({
  inputData,
  purchaseType,
  editInputData,
  setCalcLoading,
  mileage,
  setCalculatedMileage,
}) => {
  const { roundTrip } = inputData;
  return (
    <Grid container direction="column" spacing={2}>
      <RentalOrCompanyVehicleButtons
        purchaseType={purchaseType}
        setPurchaseType={editInputData("purchaseType")}
      />
      <Grid item>
        <DistanceInputOptions
          type="mileage"
          inputData={inputData}
          editInputData={editInputData}
          setCalcLoading={setCalcLoading}
          oldDbMileageValue={mileage}
          setCalculatedMileage={setCalculatedMileage}
        />
      </Grid>
      <Grid item>
        <Checkbox
          label="Was this a round Trip?"
          value={roundTrip}
          editValue={editInputData("roundTrip")}
        />
      </Grid>
    </Grid>
  );
};

export const useMileageInput = ({ transaction, onSave, setCalcLoading }) => {
  const { vehicle } = transaction;
  const { tonsCo2ePerMile = DEFAULT_TONS_CO2E_PER_MILE } = vehicle || {};

  const inputSchema = [
    { field: "purchaseType", defaultValue: "company-vehicle" },
    { field: "roundTrip", defaultValue: false },
    { field: "to" },
    { field: "from" },
    { field: "mileage" },
    { field: "distance" },
    { field: "distanceUnit" },
  ];

  const [calculatedMileage, setCalculatedMileage] = useState(0);
  const [inputData, setInputData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );

  const editInputData = (field) => (value) =>
    editObjectData(setInputData, field, value);

  const { distance, roundTrip, mileage } = inputData;

  const calculateTonsCo2e = () => {
    const distanceMiles =
      findDistanceMiles(inputData, calculatedMileage) || mileage;

    if (roundTrip) {
      return distanceMiles * tonsCo2ePerMile * 2;
    }

    return distanceMiles * tonsCo2ePerMile;
  };

  const tonsCo2e = calculateTonsCo2e();
  const onTransactionSave = () => onSave({ ...inputData, tonsCo2e });

  return {
    inputBlock: (
      <MileageInput
        inputData={inputData}
        editInputData={editInputData}
        setCalcLoading={setCalcLoading}
        setCalculatedMileage={setCalculatedMileage}
      />
    ),
    saveEnabled:
      (!!distance && numbersRegExpTest(distance)) || !!calculatedMileage,
    onTransactionSave,
    tonsCo2e,
  };
};
export default useMileageInput;
