import React from "react";

import { Grid, Typography, Paper, Box, ThemeProvider } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DefaultPaper } from "@aclymatepackages/atoms";
import { formatDecimal } from "@aclymatepackages/formatters";
import { mergeDarkTheme } from "@aclymatepackages/themes";

import CustomTooltipDisplayRow from "../../atoms/CustomTooltipDisplayRow";

import useMeasurementSystem from "../../../helpers/hooks/measurementSystem";

const StatusDisplay = ({ dataArray, id }) => {
  const { status } = dataArray.find((dataObj) => dataObj?.id === id) || {};
  if (!status) {
    return <></>;
  }

  const { color, icon, tooltip } = status;
  return (
    <Grid item>
      <Paper style={{ backgroundColor: color }}>
        <Box p={1}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <FontAwesomeIcon
                icon={icon}
                style={{ color: "white" }}
                size="2x"
              />
            </Grid>
            <Grid item>
              <ThemeProvider theme={mergeDarkTheme}>
                <Typography variant="subtitle2" color="textPrimary">
                  {tooltip}
                </Typography>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

const EmissionsCustomTooltip = ({
  active,
  payload: passedPayload,
  categoriesArray,
  labelAnnotation = "",
  dataArray = [],
  alternateTooltip,
}) => {
  const { carbonUnitLabel } = useMeasurementSystem();

  if (!active || !passedPayload.length) {
    return <></>;
  }

  const buildSubtitle = (carbonValue) =>
    `${formatDecimal(carbonValue)} ${carbonUnitLabel} CO2`;

  const { payload: columnDetails } = passedPayload[0];
  const { label = "", id } = columnDetails || {};

  const payloadDisplayArray = passedPayload.filter(
    ({ value, dataKey }) => !!value && dataKey !== "trendline"
  );

  const formattedDisplayArray = payloadDisplayArray.map(
    ({ dataKey, value }) => {
      const chartSubcategory = categoriesArray.find(
        ({ subcategory }) => subcategory === dataKey
      );

      return { ...chartSubcategory, subtitle: buildSubtitle(value) };
    }
  );

  const emissionsSumTons = payloadDisplayArray.reduce(
    (sum, { value }) => value + sum,
    0
  );

  return (
    <DefaultPaper style={{ zIndex: 99999, borderColor: "transparent" }}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography
            variant="h4"
            color="textPrimary"
          >{`${label} Emissions`}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {`${buildSubtitle(emissionsSumTons)} ${labelAnnotation}`}
          </Typography>
        </Grid>
        {alternateTooltip ? (
          <Grid item>
            <Typography variant="h6" align="center">
              Click on the bar to see a breakdown of emissions
            </Typography>
          </Grid>
        ) : (
          formattedDisplayArray.map((displayData, idx) => (
            <CustomTooltipDisplayRow
              key={`tooltip-display-row-${idx}`}
              {...displayData}
            />
          ))
        )}
        <StatusDisplay id={id} dataArray={dataArray} />
      </Grid>
    </DefaultPaper>
  );
};
export default EmissionsCustomTooltip;
