import React from "react";

import {
  Box,
  Grid,
  Typography,
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material";

import { useLayoutHelpers, mergePartnerTheme } from "@aclymatepackages/themes";

import TopBarSupportIcon from "../atoms/buttons/TopBarSupportIcon";

const Footer = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
      }}
    >
      <Box>
        <img
          src="/images/aclymate-full-logo.png"
          alt="aclymate logo"
          style={{ width: "100%", maxWidth: "250px" }}
        />
      </Box>
    </Box>
  );
};

const EventLayoutBlock = ({
  name,
  branding,
  locationName,
  startDate,
  endDate,
  imageUrl,
  eventLogoUrl,
  children,
  otherContent,
  attendee,
  companyName,
}) => {
  const { theme, isMobile } = useLayoutHelpers();
  const { name: userName, email } = attendee || {};

  const { logoUrl: companyLogoUrl, primaryColor } = branding || {};

  const logoUrl =
    eventLogoUrl || companyLogoUrl || "/images/aclymate-full-logo.png";

  return (
    <div>
      <Box
        style={{ height: isMobile ? "auto" : "100vh", overflow: "hidden" }}
        display="flex"
        flexDirection="column"
      >
        <Box flexGrow={0} p={isMobile ? 1 : 2}>
          <Grid
            container
            justifyContent="space-between"
            spacing={isMobile ? 1 : 2}
            alignItems="center"
          >
            <Grid item xs="auto">
              {logoUrl ? (
                <img
                  alt="event-organizer-logo"
                  src={logoUrl}
                  style={{ height: "50px" }}
                />
              ) : (
                <Typography variant="h1" color="texPrimary">
                  {name}
                </Typography>
              )}
            </Grid>
            <Grid item xs="auto">
              <Grid container alignItems="center">
                <Grid item>
                  <TopBarSupportIcon
                    companyName={companyName}
                    email={email}
                    userName={userName}
                    showHelpCenter={false}
                    color={primaryColor}
                    origin="survey"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          style={{ overflow: "hidden auto" }}
        >
          <Box
            position="relative"
            style={{
              overflowY: "auto",
              overflowX: "hidden",
            }}
            flexGrow={1}
            display="flex"
            flexDirection="column"
          >
            <Box
              style={{
                backgroundColor: theme.palette.backgroundGray.main,
              }}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              flexGrow={1}
              p={1}
            >
              {children}
            </Box>
            {!otherContent && <Footer />}
          </Box>
        </Box>
      </Box>
      {otherContent}
      {otherContent && <Footer />}
    </div>
  );
};

const EventLayout = ({ branding, ...otherProps }) => {
  const { palette } = useTheme();

  const { primaryColor, secondaryColor } = branding || {};

  const theme = {
    palette: {
      primary: { main: primaryColor || palette.primary.main },
      secondary: { main: secondaryColor || palette.secondary.main },
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mergePartnerTheme(createTheme(theme))}>
        <EventLayoutBlock branding={branding} {...otherProps} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default EventLayout;
