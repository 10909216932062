import React, { useState } from "react";

import { ucFirstLetters } from "@aclymatepackages/formatters";
import { ToggleButtons } from "@aclymatepackages/atoms";

import CompanyDataListItem from "./CompanyDataListItem";

import { useCachedFirebaseCrud } from "../../../helpers/firebase";

const MeasurementSystemRow = ({ measurementSystem: dbMeasurementSystem }) => {
  const { updateAccountData } = useCachedFirebaseCrud();

  const [measurementSystem, setMeasurementSystem] =
    useState(dbMeasurementSystem);

  return (
    <CompanyDataListItem
      editingComponent={
        <ToggleButtons
          size="small"
          value={measurementSystem}
          onChange={setMeasurementSystem}
          buttons={[
            {
              value: "imperial",
              name: "Imperial",
            },
            {
              value: "metric",
              name: "metric",
            },
          ]}
        />
      }
      onCancel={() => setMeasurementSystem(dbMeasurementSystem)}
      onSave={() => updateAccountData({ measurementSystem })}
      primaryText={`${ucFirstLetters(measurementSystem)} Measurement Units`}
      secondaryText="Preferred measurement system"
    />
  );
};
export default MeasurementSystemRow;
