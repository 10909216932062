import React, { useState } from "react";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";

import CompanyDataListItem from "./CompanyDataListItem";

import PlacesAutocomplete from "../../inputs/autocomplete/PlacesAutocomplete";
import DbAutocomplete from "../../inputs/autocomplete/DbAutocomplete";

import {
  useCachedFirebaseCrud,
  useCachedDisplayData,
} from "../../../helpers/firebase";
import { setAddressUpdateAirport } from "../../../helpers/utils/geography";
import { fetchNearestAirportFromCoordinates } from "../../../helpers/utils/apiCalls";

const CompanyAddressRow = ({ geography = {}, isRemote, primaryOffice }) => {
  const { updateAccountData } = useCachedFirebaseCrud();

  const [saveAddressLoading, setSaveAddressLoading] = useState(false);
  const [modifyData, setModifyData] = useState(geography);
  const { address: modifyAddress, id } = modifyData || {};
  const { description: addressDescription } = geography?.address || {};

  const [offices] = useCachedDisplayData("offices");

  const editModifyData = (field) => (value) =>
    editObjectData(setModifyData, field, value);

  const updateAddress = async (modifyData) => {
    if (isRemote) {
      return updateAccountData({ geography: modifyData });
    }
    setSaveAddressLoading(true);

    const { address } = offices.find((office) => office.id === id);

    const defaultAirport = await fetchNearestAirportFromCoordinates(
      address.coordinates
    );

    const geography = { address, defaultAirport };

    updateAccountData({
      geography,
      primaryOffice: modifyData,
    });
    setModifyData(geography);
    return setSaveAddressLoading(false);
  };

  return (
    <CompanyDataListItem
      editingComponent={
        isRemote && !primaryOffice ? (
          <PlacesAutocomplete
            place={modifyAddress}
            size={"small"}
            editPlace={setAddressUpdateAirport(editModifyData)}
            label="Choose a new address"
            style={{ width: "250px" }}
            variant="standard"
          />
        ) : (
          <DbAutocomplete
            collection="offices"
            value={modifyData || ""}
            setValue={setModifyData}
            queries={[["type", "==", "companyOffice"]]}
            size="small"
            style={{ width: "250px" }}
            label="Choose a new primary office"
            variant="standard"
          />
        )
      }
      saveDisabled={!modifyData}
      onCancel={() => setModifyData(geography)}
      onSave={() => {
        updateAddress(modifyData);
      }}
      primaryText={addressDescription || "Address not set"}
      secondaryText={"Company Address"}
      primaryTypographyProps={
        isRemote && !modifyData?.address?.description && { color: "error" }
      }
      saveLoading={saveAddressLoading}
    />
  );
};

export default CompanyAddressRow;
