import React from "react";

import { Grid, Typography } from "@mui/material";

import { ucFirstLetters } from "@aclymatepackages/formatters";

import DistanceInput from "./DistanceInput";

import OrDivider from "../atoms/OrDivider";
import PlacesAutocomplete from "./autocomplete/PlacesAutocomplete";

import { fetchDirectionsMileage } from "../../helpers/utils/apiCalls";
import { setAddress } from "../../helpers/utils/geography";
import { isObjectEmpty } from "../../helpers/otherHelpers";

const StartEndPointInputs = ({
  type,
  from,
  setFrom,
  to,
  setTo,
  clearOtherInputs = () => {},
  setCalculatedMileage,
  setCalcLoading,
  editTransaction,
}) => {
  const setDestination = (setter, otherDestination) => async (destination) => {
    editTransaction("distance")("");
    editTransaction("distanceUnit")("");
    if (!destination) {
      return setter("");
    }

    if (otherDestination && !isObjectEmpty(destination)) {
      clearOtherInputs();

      setCalcLoading(true);
      const { place_id: placeId } = destination;
      const totalMileage = await fetchDirectionsMileage(
        { placeId },
        otherDestination
      );
      setCalculatedMileage(totalMileage);
      setCalcLoading(false);
    }

    return setAddress(setter)(destination);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <PlacesAutocomplete
          place={from}
          editPlace={setDestination(setFrom, to)}
          label={`${ucFirstLetters(type)} Starting Point`}
          size="small"
        />
      </Grid>
      <Grid item sm={6}>
        <PlacesAutocomplete
          place={to}
          editPlace={setDestination(setTo, from)}
          label={`${ucFirstLetters(type)} Destination`}
          size="small"
        />
      </Grid>
    </Grid>
  );
};

const DistanceInputOptions = ({
  inputData,
  editInputData,
  type,
  clearOtherInputs = () => {},
  setCalculatedMileage,
  setCalcLoading,
  oldDbMileageValue,
  additionalRows = [],
}) => {
  const { from, to, distance } = inputData;

  const setFrom = editInputData("from");
  const setTo = editInputData("to");

  const distanceClearOtherInputs = () => {
    setFrom("");
    setTo("");
    clearOtherInputs();
    return setCalculatedMileage(0);
  };

  const inputRows = [
    {
      RowComponent: StartEndPointInputs,
      props: {
        editTransaction: editInputData,
        type,
        from,
        setFrom,
        to,
        setTo,
        clearOtherInputs,
        setCalculatedMileage,
        setCalcLoading,
      },
      title: "Enter Ride Start and End Points (Best)",
    },
    {
      RowComponent: DistanceInput,
      props: {
        distance,
        editTransaction: editInputData,
        oldDbMileageValue,
        clearOtherInputs: distanceClearOtherInputs,
      },
      title: "Enter Estimated Distance (Better)",
    },
  ];

  const displayRows = [...inputRows, ...additionalRows];

  return (
    <Grid container direction="column" spacing={1}>
      {displayRows.map(({ RowComponent, props, title }, idx) => (
        <>
          <Grid item key={`rides-input-row-${idx}`}>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            <RowComponent {...props} />
          </Grid>
          {idx !== displayRows.length - 1 && (
            <Grid item>
              <OrDivider />
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
};
export default DistanceInputOptions;
