import React, { useEffect, useState, useContext } from "react";

import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

import {
  Box,
  Avatar,
  Grid,
  Typography,
  CircularProgress,
  ButtonBase,
  Paper,
  IconButton,
  Tabs,
  Tab,
  Divider,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  useTheme,
  Tooltip,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faCircleCheck,
  faTree,
  faBolt,
  faCircleQuestion,
  faBan,
} from "@fortawesome/pro-solid-svg-icons";

import { DefaultPaper } from "@aclymatepackages/atoms";
import { getYearlyTonsCo2eValues } from "@aclymatepackages/calcs/myAclymate";
import { hexToRgba, nameToFirstName } from "@aclymatepackages/converters";
import { formatDollars } from "@aclymatepackages/formatters";

import Backdrop from "../atoms/mui/Backdrop";
import GrayPaper from "../atoms/GrayPaper";
import MyAclymateReportPaper from "../atoms/myAclymate/MyAcymateReportPaper";

import SlideLayout from "../layouts/SlideLayout";

import { fetchOurApi } from "../../helpers/utils/apiCalls";
import { CNAUGHT_PROJECT_DESCRIPTION } from "../../helpers/components/projects";
import { StripeCustomerContext } from "../../helpers/contexts/stripeCustomer";
import { useLayoutHelpers } from "../../helpers/otherHelpers";

const buildPriceOptions = (yearlyTotalTonsCo2e, displayPricePerTon) => {
  const totalMonthlyCost = (yearlyTotalTonsCo2e / 12) * displayPricePerTon;

  return [
    {
      name: "base",
      percentage: 10,
      price: totalMonthlyCost * 0.1,
    },
    { name: "leader", percentage: 50, price: totalMonthlyCost * 0.5 },
    { name: "champion", percentage: 100, price: totalMonthlyCost },
  ];
};

const useCNaughtPortfolios = () => {
  const [cNaughtPortfolios, setCNaughtPortfolios] = useState([]);
  const [cNaughtPortfoliosLoading, setCNaughtPortfoliosLoading] =
    useState(true);

  useEffect(() => {
    const fetchCNaughtPortfolios = async () => {
      try {
        const response = await fetchOurApi({
          path: "/integrations/cNaught/get-portfolios",
          method: "POST",
          data: {},
          callback: (res) => res,
        });
        const { success, portfolios } = response;

        if (!success) {
          return setCNaughtPortfolios([]);
        }

        return setCNaughtPortfolios(portfolios);
      } catch (error) {
        console.error("Failed to fetch cNaught portfolios:", error);
      } finally {
        setCNaughtPortfoliosLoading(false);
      }
    };

    if (cNaughtPortfoliosLoading) {
      fetchCNaughtPortfolios();
    }
  }, [cNaughtPortfoliosLoading]);

  return [cNaughtPortfolios, cNaughtPortfoliosLoading];
};

const CNaughtPieChart = ({ category_allocations }) => {
  const { palette } = useTheme();

  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <ResponsiveContainer
      width="100%"
      height={300}
      style={{ position: "relative" }}
    >
      <Box
        style={{ position: "absolute", inset: 0 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {hoveredIndex !== null && (
          <div>
            <Typography variant="h6" align="center" style={{ maxWidth: 150 }}>
              {category_allocations[hoveredIndex].category.name}
            </Typography>
            <Typography
              variant="subtitle2"
              align="center"
              color="textSecondary"
            >
              {Math.round(
                category_allocations[hoveredIndex].allocated_fraction * 100
              )}
              %
            </Typography>
          </div>
        )}
      </Box>
      <PieChart>
        <Pie
          data={category_allocations}
          dataKey="allocated_fraction"
          nameKey={"category.name"}
          outerRadius={100}
          innerRadius={80}
        >
          {category_allocations.map((_, idx) => (
            <Cell
              key={`portfolio-allocation-pie-${idx}`}
              onMouseOver={() => setHoveredIndex(idx)}
              fill={
                hoveredIndex === idx
                  ? palette.secondary.main
                  : palette.primary.main
              }
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

const CnaughtPortfolioDetailsSlider = ({
  name,
  description,
  category_allocations,
  onClose,
}) => {
  const [selectedViewIdx, setSelectedViewIdx] = useState(0);

  const views = [
    {
      label: "Overview",
      value: "overview",
      content: (
        <Grid container direction="column">
          <Grid item>
            <ResponsiveContainer width="100%" height={300}>
              <CNaughtPieChart category_allocations={category_allocations} />
            </ResponsiveContainer>
          </Grid>
          <Grid item>
            <Typography variant="body2">{description}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      label: "About CNaught",
      value: "description",
      content: (
        <Typography variant="body2">{CNAUGHT_PROJECT_DESCRIPTION}</Typography>
      ),
    },
  ];

  const onTabClick = (value) => {
    const tabValueIndex = views.findIndex((tab) => tab.value === value);
    return setSelectedViewIdx(tabValueIndex);
  };

  const { value, content } = views[selectedViewIdx] || {};

  return (
    <SlideLayout
      isSlideOpen
      setIsSlideOpen={onClose}
      header={
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <img
              src="/images/partner-logos/cnaught.svg"
              alt="cnaught logo"
              width="50px"
            />
          </Grid>
          <Grid item>
            <Typography variant="h2">{name}</Typography>
          </Grid>
        </Grid>
      }
      tagInputs={
        <>
          <Tabs
            value={value}
            onChange={(_, value) => onTabClick(value)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {views.map(({ label, value }, idx) => (
              <Tab
                label={label}
                value={value}
                key={`object-details-view-tab-${idx}`}
              />
            ))}
          </Tabs>
          <Divider />
        </>
      }
      content={content}
    />
  );
};

const OffsetBucket = ({
  hover = true,
  isSelected,
  portfolio,
  setShowPortfolioDetails,
}) => {
  const theme = useTheme();

  const { name, primary_image_url } = portfolio || {};

  return (
    <Paper
      elevation={hover ? 4 : 1}
      style={{
        width: "100%",
        paddingTop: 200,
        overflow: "hidden",
        position: "relative",
        border: isSelected
          ? `2px solid ${theme.palette.secondary.main}`
          : "none",
        backgroundImage: `url(${
          primary_image_url || "/images/project-categories/conservation1.jpg"
        })`,
        backgroundSize: "cover",
      }}
    >
      <Box
        position="absolute"
        style={{
          left: 0,
          right: 0,
          bottom: 0,
          background: `linear-gradient(135deg, ${hexToRgba(
            theme.palette.primary.main,
            0.95
          )} 0%, ${hexToRgba(theme.palette.secondary.main, 0.5)} 100%)`,
          color: "white",
        }}
        p={1}
      >
        <Typography variant="h4" color="inherit" noWrap>
          {name}
        </Typography>
      </Box>
      <IconButton
        style={{ position: "absolute", top: 0, right: 0 }}
        onClick={(e) => {
          e.stopPropagation();
          return setShowPortfolioDetails(portfolio);
        }}
      >
        <FontAwesomeIcon
          icon={faCircleInfo}
          style={{ color: "white" }}
          size="xl"
        />
      </IconButton>
    </Paper>
  );
};

const OffsetBucketButton = ({
  portfolio,
  onClick,
  setShowPortfolioDetails,
  isSelected,
}) => {
  const theme = useTheme();

  const [hover, setHover] = useState(false);

  return (
    <ButtonBase
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: "100%",
        height: "100%",
        textAlign: "left",
        position: "relative",
      }}
    >
      <OffsetBucket
        portfolio={portfolio}
        isSelected={isSelected}
        hover={hover}
        setShowPortfolioDetails={setShowPortfolioDetails}
      />
      {isSelected && (
        <div style={{ top: -5, left: -5, position: "absolute" }}>
          <FontAwesomeIcon
            icon={faCircleCheck}
            style={{ color: theme.palette.secondary.main }}
            size="2x"
          />
        </div>
      )}
    </ButtonBase>
  );
};

const CnaughtOffsetsDisplayBlock = ({
  cNaughtPortfolios,
  selectedOffsetBucketIdx,
  setSelectedOffsetBucketIdx,
  setShowPortfolioDetails,
}) => {
  return (
    <GrayPaper>
      <Grid container spacing={2}>
        {cNaughtPortfolios.map((portfolio, idx) => (
          <Grid item xs={12} sm={6} md={3} key={idx}>
            <OffsetBucketButton
              isSelected={idx === selectedOffsetBucketIdx}
              portfolio={portfolio}
              onClick={() => setSelectedOffsetBucketIdx(idx)}
              setShowPortfolioDetails={setShowPortfolioDetails}
            />
          </Grid>
        ))}
      </Grid>
    </GrayPaper>
  );
};

const PriceOption = ({
  selectedPrice,
  price,
  percentage,
  name = "",
  onSelectPrice,
}) => (
  <Grid item sm={4} xs={8}>
    <Grid container direction="column" spacing={1}>
      <Grid item>
        {onSelectPrice ? (
          <FormControl>
            <FormControlLabel
              control={
                <Radio
                  checked={selectedPrice?.price === price}
                  onChange={onSelectPrice}
                />
              }
              label={name.toUpperCase()}
            />
          </FormControl>
        ) : (
          <Typography variant="h4">{name.toUpperCase()}</Typography>
        )}
      </Grid>
      <Grid item>
        <Typography variant="h1" display="inline">
          ${Math.round(price)}
        </Typography>
        <Typography variant="h4" color="textSecondary" display="inline">
          /mo
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">
          *Covers about {percentage}% of your total footprint
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);

const PriceSelectionBlock = ({
  yearlyTotalTonsCo2e,
  selectedPrice,
  setSelectedPrice,
  displayPricePerTon,
}) => {
  const { isMobile } = useLayoutHelpers();

  const priceOptions = buildPriceOptions(
    yearlyTotalTonsCo2e,
    displayPricePerTon
  );

  return (
    <GrayPaper>
      <Grid
        id="price-options-container"
        container
        spacing={2}
        justifyContent="center"
        wrap={isMobile ? "wrap" : "nowrap"}
      >
        {priceOptions.map((price, idx) => (
          <PriceOption
            key={`project-price-option-${idx}`}
            {...price}
            selectedPrice={selectedPrice}
            onSelectPrice={() => setSelectedPrice(price)}
          />
        ))}
      </Grid>
    </GrayPaper>
  );
};

const OffsetBucketsPurchaseInterface = ({
  setShowPortfolioDetails,
  yearlyTotalTonsCo2e,
  cNaughtPortfolios,
  setSubscriptionData,
  myAclymateSubscription,
  setUpdateSubscriptionData,
  name,
  email,
}) => {
  const { isMobile } = useLayoutHelpers();
  const { subscriptionId } = myAclymateSubscription || {};

  const { palette } = useTheme();

  const [selectedOffsetBucketIdx, setSelectedOffsetBucketIdx] = useState(0);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const roundedYearlyTonsCo2e = Math.ceil(yearlyTotalTonsCo2e * 10) / 10;
  const { displayPricePerTon, name: portfolioName } =
    cNaughtPortfolios[selectedOffsetBucketIdx] || {};
  const { percentage, name: subscriptionName, price } = selectedPrice || {};

  const cancelSubscription = async () => {
    fetchOurApi({
      path: `/stripe/cancel-subscription`,
      method: "POST",
      data: {
        subscriptionId,
      },
    });
    return fetchOurApi({
      path: "/sendgrid/myAclymate-subscription-cancel",
      method: "POST",
      data: {
        email,
        firstName: nameToFirstName(name),
        percentage,
        subscriptionName,
        portfolioName,
        monthlyPrice: formatDollars(price),
      },
    });
  };

  const CancelButton = () => {
    const onCancel = () => {
      cancelSubscription();
      return window.location.reload();
    };

    return (
      <>
        {isMobile ? (
          <Tooltip title="Cancel Subscription">
            <IconButton onClick={onCancel}>
              <FontAwesomeIcon icon={faBan} />
            </IconButton>
          </Tooltip>
        ) : (
          <Button onClick={onCancel}>Cancel Subscription</Button>
        )}
      </>
    );
  };

  const onClickSubscribe = async () => {
    if (setUpdateSubscriptionData) {
      setUpdateSubscriptionData(false);
    }

    if (myAclymateSubscription) {
      await cancelSubscription();
    }

    return setSubscriptionData({
      price: selectedPrice,
      portfolio: cNaughtPortfolios[selectedOffsetBucketIdx],
    });
  };

  const offsetPurchaseSteps = [
    {
      title: "Choose your project portfolio",
      content: (
        <Grid item xs={12}>
          <CnaughtOffsetsDisplayBlock
            selectedOffsetBucketIdx={selectedOffsetBucketIdx}
            cNaughtPortfolios={cNaughtPortfolios}
            setSelectedOffsetBucketIdx={setSelectedOffsetBucketIdx}
            setShowPortfolioDetails={setShowPortfolioDetails}
          />
        </Grid>
      ),
      action: subscriptionId && <CancelButton />,
    },
    {
      title: "Choose your subscription level",
      content: (
        <Grid item xs={12}>
          <PriceSelectionBlock
            selectedPrice={selectedPrice}
            setSelectedPrice={setSelectedPrice}
            yearlyTotalTonsCo2e={roundedYearlyTonsCo2e}
            displayPricePerTon={displayPricePerTon}
          />
        </Grid>
      ),
    },
    {
      title: "Subscribe!",
      action: (
        <Grid item sm={8} xs={6} container justifyContent="center">
          <Grid item>
            <Button
              disabled={!selectedPrice}
              variant="contained"
              color="secondary"
              onClick={onClickSubscribe}
            >
              Subscribe
            </Button>
          </Grid>
        </Grid>
      ),
    },
  ];

  return (
    <MyAclymateReportPaper
      title="Offset Your Carbon Footprint"
      subtitle="Reduce your impact by supporting verified carbon offset projects"
    >
      <Box display="flex" flexDirection="column" rowGap={4}>
        {offsetPurchaseSteps.map(({ title, content, action }, idx) => (
          <Grid
            key={`purchase-step-${idx}`}
            container
            spacing={1}
            justifyContent="space-between"
          >
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Avatar sx={{ bgcolor: palette.primary.main }}>
                        <Typography variant="h3" align="center">
                          {idx + 1}
                        </Typography>
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">{title}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {action}
              </Grid>
            </Grid>
            {content}
          </Grid>
        ))}
      </Box>
    </MyAclymateReportPaper>
  );
};

const ExistingSubscriptionDisplayBlock = ({
  subscription,
  setShowPortfolioDetails,
  yearlyTotalTonsCo2e,
  setUpdateSubscriptionData,
}) => {
  const { portfolio, metadata } = subscription;
  const { displayPricePerTon } = portfolio || {};
  const { percentage } = metadata;

  const { palette } = useTheme();

  const priceOptions = buildPriceOptions(
    yearlyTotalTonsCo2e,
    displayPricePerTon
  );

  const selectedPrice = priceOptions.find(
    (price) => price.percentage === Number(percentage)
  );

  const displayBlocks = [
    {
      icon: faTree,
      title: "Your Offset Portfolio",
      content: (
        <OffsetBucket
          portfolio={portfolio}
          setShowPortfolioDetails={setShowPortfolioDetails}
        />
      ),
    },
    {
      icon: faBolt,
      title: "Your Subscription Tier",
      content: <PriceOption {...selectedPrice} />,
    },
    {
      icon: faCircleQuestion,
      title: "Update Subscription",
      content: (
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          rowGap={2}
        >
          <Button
            variant="contained"
            onClick={() => setUpdateSubscriptionData(true)}
          >
            Update Subscription
          </Button>
          <Typography variant="body2" align="center">
            Choose a different offset portfolio to support, change your
            suscription tier, or cancel your subscription.
          </Typography>
          <Typography variant="caption" align="center" color="textSecondary">
            Please note: When you update your subscription, you’ll receive two
            emails - one to confirm cancellation of your previous subscription
            and another to confirm your new subscription.
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <MyAclymateReportPaper
      title="Your Offset Subscription"
      subtitle="You are reducing your impact by supporting verified carbon offset projects"
    >
      <Grid container spacing={2} alignItems="stretch">
        {displayBlocks.map(({ icon, title, content, component }, idx) => (
          <Grid item sm={4} xs={12} key={`subscription-display-block-${idx}`}>
            <Box
              display="flex"
              flexDirection="column"
              rowGap={2}
              sx={{ height: "100%" }}
            >
              <Grid item container alignItems="center" spacing={2}>
                <Grid item>
                  <Avatar sx={{ bgcolor: palette.primary.main }}>
                    <FontAwesomeIcon icon={icon} />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="h6">{title}:</Typography>
                </Grid>
              </Grid>
              <GrayPaper style={{ height: "100%" }}>{content}</GrayPaper>
            </Box>
          </Grid>
        ))}
      </Grid>
    </MyAclymateReportPaper>
  );
};

const OffsetBucketsBox = ({
  emissionsData,
  setSubscriptionData,
  name,
  email,
}) => {
  const [cNaughtPortfolios, cNaughtPortfoliosLoading] = useCNaughtPortfolios();

  const [updateSubscriptionData, setUpdateSubscriptionData] = useState(false);
  const [showPortfolioDetails, setShowPortfolioDetails] = useState(null);

  const { yearlyTotalTonsCo2e } = getYearlyTonsCo2eValues(emissionsData);
  const { subscriptions, isSubscriptionsLoading } = useContext(
    StripeCustomerContext
  );

  const findMyAclymateSubscriptionInfo = () => {
    if (isSubscriptionsLoading || !subscriptions.length) {
      return null;
    }

    const myAclymateSubscription = subscriptions.find(
      ({ product }) => product === "myAclymate"
    );

    if (!myAclymateSubscription) {
      return null;
    }

    const { status } = myAclymateSubscription;
    if (status !== "active") {
      return null;
    }

    const subscriptionPortfolio = cNaughtPortfolios.find(
      ({ stripePriceId }) =>
        stripePriceId === myAclymateSubscription.subscriptionPriceId
    );

    return { ...myAclymateSubscription, portfolio: subscriptionPortfolio };
  };

  const myAclymateSubscription = findMyAclymateSubscriptionInfo();

  return (
    <>
      {updateSubscriptionData && (
        <Backdrop
          open={updateSubscriptionData}
          onClose={() => setUpdateSubscriptionData(false)}
          style={{ overflow: "auto", zIndex: 900 }}
        >
          <Box p={2}>
            <OffsetBucketsPurchaseInterface
              setShowPortfolioDetails={setShowPortfolioDetails}
              yearlyTotalTonsCo2e={yearlyTotalTonsCo2e}
              cNaughtPortfolios={cNaughtPortfolios}
              setSubscriptionData={setSubscriptionData}
              myAclymateSubscription={myAclymateSubscription}
              setUpdateSubscriptionData={setUpdateSubscriptionData}
              name={name}
              email={email}
            />
          </Box>
        </Backdrop>
      )}
      {showPortfolioDetails && (
        <CnaughtPortfolioDetailsSlider
          onClose={() => setShowPortfolioDetails(null)}
          {...showPortfolioDetails}
        />
      )}
      {cNaughtPortfoliosLoading ? (
        <DefaultPaper style={{ width: "100%" }}>
          <Grid container item justifyContent="center">
            <CircularProgress />
          </Grid>
        </DefaultPaper>
      ) : myAclymateSubscription ? (
        <ExistingSubscriptionDisplayBlock
          subscription={myAclymateSubscription}
          yearlyTotalTonsCo2e={yearlyTotalTonsCo2e}
          setShowPortfolioDetails={setShowPortfolioDetails}
          setUpdateSubscriptionData={setUpdateSubscriptionData}
        />
      ) : (
        <OffsetBucketsPurchaseInterface
          setShowPortfolioDetails={setShowPortfolioDetails}
          yearlyTotalTonsCo2e={yearlyTotalTonsCo2e}
          cNaughtPortfolios={cNaughtPortfolios}
          setSubscriptionData={setSubscriptionData}
          myAclymateSubscription={myAclymateSubscription}
          name={name}
          email={email}
        />
      )}
    </>
  );
};

export default OffsetBucketsBox;
