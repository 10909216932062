import React, { useState, createContext, useEffect, useContext } from "react";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";

import { fetchAdminApi } from "../utils/apiCalls";
import { useAuth, unpackFirebaseObj, useAccountData } from "../firebase";
import { getAccountCollectionAndId } from "../otherHelpers";

import { PlatformLayoutContext } from "./platformLayout";
import { formatExternalCompanyData } from "./helpers";
import { StripeCustomerContext } from "./stripeCustomer";

export const AdminDataContext = createContext();

const AdminDataContextProvider = ({ children }) => {
  const { id: companyId } = getAccountCollectionAndId();

  const { refetchCustomerSubscriptions } = useContext(StripeCustomerContext);
  const { setViewMode } = useContext(PlatformLayoutContext);

  const [user, userLoading] = useAuth();
  const [accountData] = useAccountData();

  const [isUserAclymateAdmin, setIsUserAclymateAdmin] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectableCompanies, setSelectableCompanies] = useState([]);
  const [adminData, setAdminData] = useState({
    companyData: {},
    transactions: [],
    offices: [],
    employees: [],
    vehicles: [],
    offsets: [],
    events: [],
    vendors: [],
  });
  const [isAdminDataLoading, setIsAdminDataLoading] = useState(false);

  const editAdminData = (field, value) =>
    editObjectData(setAdminData, field, value);

  useEffect(() => {
    const checkIfUserIsAclymateAdmin = async () => {
      if (!user) {
        return;
      }

      const { claims } = await user.getIdTokenResult({ forceRefresh: true });

      const { isAclymateAdmin = false } = claims;

      return setIsUserAclymateAdmin(isAclymateAdmin);
    };

    if (!userLoading) {
      checkIfUserIsAclymateAdmin();
    }
  }, [user, userLoading]);

  useEffect(() => {
    const fetchAndSetSelectableCompanies = async () => {
      const allCompanies = await fetchAdminApi({
        url: "/aclymate/fetch-all-companies-names-and-ids",
        data: { method: "GET" },
      });

      const allCompaniesWithNames = allCompanies
        .filter(({ name }) => !!name)
        .map(({ name, id }) => ({
          name: `${name} (${id})`,
          id,
        }));

      return setSelectableCompanies(allCompaniesWithNames);
    };

    if (isUserAclymateAdmin) {
      fetchAndSetSelectableCompanies();
    }
  }, [isUserAclymateAdmin]);

  const fetchAndSetAdminData = async () => {
    setIsAdminDataLoading(true);

    const { name: companyName } = accountData;

    const {
      companyData = {},
      employees = [],
      offices = [],
      transactions = [],
      vehicles = [],
      offsets = [],
      events = [],
      vendors = [],
      allAggregatedTransactions,
      matchedVendorsAggregatedTransactions,
      employeesAggregatedTransactions,
      eventsAggregatedTransactions,
    } = await fetchAdminApi({
      url: "/aclymate/fetch-admin-data",
      data: {
        method: "POST",
        body: {
          uid: user?.uid,
          companyId: selectedCompany.id,
        },
      },
    });
    const { stripeCustomerId } = companyData;
    refetchCustomerSubscriptions(stripeCustomerId);

    const { formattedEmployees, formattedTransactions } =
      formatExternalCompanyData({
        companyId,
        companyName,
        employees,
        transactions,
      });

    setAdminData({
      companyData: unpackFirebaseObj(companyData),
      employees: formattedEmployees,
      offices,
      transactions: formattedTransactions,
      vehicles,
      offsets,
      events,
      vendors,
      "all-aggregated-transactions": allAggregatedTransactions,
      "matched-vendors-aggregated-transactions":
        matchedVendorsAggregatedTransactions,
      "employees-aggregated-transactions": employeesAggregatedTransactions,
      "events-aggregated-transactions": eventsAggregatedTransactions,
    });

    setIsAdminDataLoading(false);

    return setViewMode("admin");
  };

  return (
    <AdminDataContext.Provider
      value={{
        isUserAclymateAdmin,
        selectedCompany,
        setSelectedCompany,
        selectableCompanies,
        fetchAndSetAdminData,
        isAdminDataLoading,
        adminData,
        editAdminData,
      }}
    >
      {children}
    </AdminDataContext.Provider>
  );
};
export default AdminDataContextProvider;
