import React from "react";

import { Typography } from "@mui/material";

const MyAclymateReportTitle = ({ children, align = "center" }) => (
  <Typography variant="h3" color="secondary" gutterBottom align={align}>
    {children}
  </Typography>
);
export default MyAclymateReportTitle;
