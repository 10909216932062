import React, { useState } from "react";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  Tooltip,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteForever from "@mui/icons-material/DeleteForever";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LoadingButton, TextField } from "@aclymatepackages/atoms";
import { formatDate } from "@aclymatepackages/formatters";
import {
  editObjectData,
  addDataRow,
} from "@aclymatepackages/array-immutability-helpers";

import DatePopper from "./DatePopper";
import EmployeeCommuteSchedulesView from "./EmployeeCommuteSchedulesView";

import EmissionsDetailsBlock from "../../EmissionsDetails";
import SettingsObjectDetailsSlider from "../../SettingsObjectDetailsSlider";
import SendEmployeeSurveyButton from "../../../../atoms/buttons/SendEmployeeSurveyButton";
import Link from "../../../../atoms/mui/Link";
import DatePicker from "../../../../atoms/mui/DatePicker";
import EditableVehiclesCards from "../../../../inputs/vehicles/EditableVehiclesCards";

import {
  buildNewEmployeeStatusLinks,
  sendEmployeeSurvey,
} from "../../../../../helpers/components/employees";
import {
  generateSecureId,
  getAccountCollectionAndId,
} from "../../../../../helpers/otherHelpers";
import {
  useCachedFirebaseCrud,
  useAccountData,
} from "../../../../../helpers/firebase";
import { employeeStatuses } from "../../../../../helpers/components/employees";
import useAccountingData from "../../../../../helpers/hooks/accountingData";

const editMostRecentSchedule = (commuteSchedules, date) =>
  commuteSchedules.map((schedule, idx) =>
    idx === 0
      ? { endDate: dayjs(date).subtract(1, "day").toDate(), ...schedule }
      : schedule
  );

const useConfirmedEmployeeDetails = (employee, setSelectedEmployee) => {
  const { startDate, id, status, commuteSchedules = [{}], links } = employee;

  const { updateCollectionDoc } = useCachedFirebaseCrud();
  const [{ startDate: companyStartDate, id: companyId }] = useAccountData();
  const [{ mostRecentAccountingDate }] = useAccountingData();

  const [modifyEmployee, setModifyEmployee] = useState(employee);
  const [saveLoading, setSaveLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [leaveAnchorEl, setLeaveAnchorEl] = useState(null);

  const { email: modifyEmail, startDate: modifyStartDate } = modifyEmployee;

  const editEmployee = (field) => (value) =>
    editObjectData(setModifyEmployee, field, value);

  const onEmployeeSave = async () => {
    setSaveLoading(true);
    const { startDate } = modifyEmployee;
    const employeeUpdateObj = {
      startDate: dayjs(startDate).toDate(),
    };
    await updateCollectionDoc("employees", id, employeeUpdateObj);
    setSelectedEmployee(modifyEmployee);
    return setSaveLoading(false);
  };

  const takeEmployeeOffLeave = (date) => {
    const { endDate, ...otherCommuteScheduleProps } = commuteSchedules[1] || {};
    const newCurrentCommuteSchedule = {
      ...otherCommuteScheduleProps,
      startDate: dayjs(date).toDate(),
    };
    const newCommuteSchedules = [
      newCurrentCommuteSchedule,
      ...editMostRecentSchedule(commuteSchedules, date),
    ];

    updateCollectionDoc("employees", id, {
      commuteSchedules: newCommuteSchedules,
      links: buildNewEmployeeStatusLinks(employee, "incomplete"),
    });
    return newCommuteSchedules;
  };

  const contentDatePopperProps =
    status === "onLeave"
      ? {
          title: "When did this employee's leave end?",
          buttonText: "Take Employee off Leave",
          onClick: (date) => {
            const newCommuteSchedules = takeEmployeeOffLeave(date);
            editObjectData(
              setSelectedEmployee,
              "links",
              buildNewEmployeeStatusLinks(employee, "incomplete")
            );
            editObjectData(
              setSelectedEmployee,
              "commuteSchedules",
              newCommuteSchedules
            );
            editObjectData(setSelectedEmployee, "status", "incomplete");
            setSelectedEmployee(null);
            return setAnchorEl(null);
          },
        }
      : {
          title: "When did this employee's leave start?",
          buttonText: "Set Employee on Leave",
          onClick: (date) => {
            const newCommuteSchedules = [
              { startDate: date, commuteEndpoints: [], leave: true },
              ...editMostRecentSchedule(commuteSchedules, date),
            ];
            updateCollectionDoc("employees", id, {
              commuteSchedules: newCommuteSchedules,
              links: buildNewEmployeeStatusLinks(employee, "onLeave"),
            });
            editObjectData(
              setSelectedEmployee,
              "links",
              buildNewEmployeeStatusLinks(employee, "onLeave")
            );
            editObjectData(
              setSelectedEmployee,
              "commuteSchedules",
              newCommuteSchedules
            );
            editObjectData(setSelectedEmployee, "status", "onLeave");
            setSelectedEmployee(null);
            return setAnchorEl(null);
          },
        };

  const terminateEmployee = (date) => {
    const { id: companyId } = getAccountCollectionAndId();

    const updatedLinks = links.map((link) =>
      link.id !== companyId
        ? link
        : {
            ...link,
            status: "inactive",
            employeeStatus: "terminated",
            endDate: date,
          }
    );

    updateCollectionDoc("employees", id, {
      commuteSchedules: [...editMostRecentSchedule(commuteSchedules, date)],
      links: updatedLinks,
      endDate: date,
    });
    return setSelectedEmployee(null);
  };

  const employeeSurveyUrl = `http://${window.location.host}/employee-survey/${companyId}/new-employee/${id}`;

  const content = (
    <>
      <Grid container spacing={2}>
        <Grid item container spacing={2} justifyContent="space-between">
          <Grid item>
            <SendEmployeeSurveyButton employee={employee} />
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <Tooltip title="Copy Survey Link">
                  <IconButton
                    onClick={() =>
                      navigator.clipboard.writeText(employeeSurveyUrl)
                    }
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Go to employee survey">
                  <Link href={employeeSurveyUrl}>
                    <IconButton>
                      <OpenInNewIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Tooltip title="Please contact support if you need to change this employee's email address">
            <span>
              <TextField
                value={modifyEmail}
                setValue={editEmployee("email")}
                label="Email address"
                disabled
              />
            </span>
          </Tooltip>
        </Grid>
        <Grid item sm={12}>
          <DatePicker
            disabled={
              mostRecentAccountingDate &&
              dayjs(modifyStartDate).isBefore(dayjs(mostRecentAccountingDate))
            }
            date={modifyStartDate || companyStartDate}
            editDate={editEmployee("startDate")}
            label="When did this employee start?"
          />
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                if (anchorEl) {
                  setAnchorEl(null);
                }
                return setLeaveAnchorEl(leaveAnchorEl ? null : e.currentTarget);
              }}
            >
              {status === "onLeave"
                ? "Take Employee Off Leave"
                : "Place Employee on Leave"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DatePopper
        {...contentDatePopperProps}
        open={!!leaveAnchorEl}
        anchorEl={leaveAnchorEl}
        setAnchorEl={setLeaveAnchorEl}
        selectedScheduleProps={{ prevSchedule: commuteSchedules[0] }}
        employee={employee}
      />
    </>
  );

  const footer = (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Tooltip title="Remove employee">
            <span>
              <IconButton
                onClick={(e) => {
                  if (leaveAnchorEl) {
                    setLeaveAnchorEl(null);
                  }
                  return setAnchorEl(anchorEl ? null : e.currentTarget);
                }}
                size="large"
              >
                <DeleteForever color="error" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <LoadingButton
            isLoading={saveLoading}
            label="Save Changes"
            color="primary"
            disabled={dayjs(startDate).isSame(modifyStartDate, "day")}
            onClick={onEmployeeSave}
          />
        </Grid>
      </Grid>
      <DatePopper
        title="When was this employee's last day?"
        buttonText="Remove Employee"
        onClick={terminateEmployee}
        employee={employee}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open
      />
    </>
  );

  return { content, footer };
};

const SurveyEmployeeDetailView = ({ employee, setSelectedEmployee }) => {
  const { id: companyId } = getAccountCollectionAndId();

  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const [sendSurveyLoading, setSendSurveyLoading] = useState(false);

  const { name, status, endDate, id, links, currentLinkedIds } = employee;
  const { icon: statusIcon, color: statusColor } = employeeStatuses[status];

  const [deleteEmployeeLoading, setDeleteEmployeeLoading] = useState(false);

  const onDeleteEmployee = () => {
    setDeleteEmployeeLoading(true);

    const updatedLinks = links.map((link) =>
      link.id === companyId
        ? {
            ...link,
            employeeStatus: "deleted",
            endDate: new Date(),
            status: "inactive",
          }
        : link
    );
    const updatedCurrentLinkedIds = currentLinkedIds.filter(
      (id) => id !== companyId
    );

    return updateCollectionDoc("employees", id, {
      links: updatedLinks,
      currentLinkedIds: updatedCurrentLinkedIds,
    }).then(() => setSelectedEmployee(null));
  };

  const statusProps = {
    unconfirmed: {
      title: `We're using default values for ${name}'s emissions because they haven't completed their employee survey. Would you like to resend their survey invite?`,
      buttons: [
        <LoadingButton
          isLoading={sendSurveyLoading}
          color="primary"
          label="Resend Employee Survey"
          onClick={() => sendEmployeeSurvey({ employee, setSendSurveyLoading })}
        />,
        deleteEmployeeLoading ? (
          <CircularProgress />
        ) : (
          <Tooltip title="Delete this employee">
            <IconButton onClick={onDeleteEmployee} size="large">
              <DeleteForever />
            </IconButton>
          </Tooltip>
        ),
      ],
    },
    terminated: {
      title: `${name}'s last day at your company was on ${formatDate(
        endDate
      )}. Any emissions displayed for ${name} reflect their emissions status on their last day.`,
    },
  };

  const { title, buttons } = statusProps[status];

  return (
    <Grid container direction="column" spacing={4} alignItems="center">
      <Grid item>
        <FontAwesomeIcon
          icon={statusIcon}
          size="10x"
          style={{ color: statusColor }}
        />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" align="center">
          {title}
        </Typography>
      </Grid>
      {buttons && (
        <Grid
          item
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          {buttons.map((button, idx) => (
            <Grid item key={`unconfirmed-action-button-${idx}`}>
              {button}
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

const VehiclesDetailsFooter = ({ setDisplayVehicles }) => {
  const addAnotherVehicle = () =>
    addDataRow(setDisplayVehicles, {
      id: generateSecureId(),
      isNewAndUnsaved: true,
    });

  return (
    <Grid container alignItems="center" justifyContent="flex-end">
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => addAnotherVehicle()}
        >
          Add Another Vehicle
        </Button>
      </Grid>
    </Grid>
  );
};

const EmployeeDetailsSlider = ({
  emissions,
  setSelectedTransaction,
  employee,
  setSelectedEmployee,
}) => {
  const { id, status, vehicles = [], name } = employee;

  const { updateCollectionDoc } = useCachedFirebaseCrud();
  const [companyData] = useAccountData();

  const onNameSave = (name) => updateCollectionDoc("employees", id, { name });

  const {
    content: confirmedEmployeeDetailsContent,
    footer: confirmedEmployeeDetailsFooter,
  } = useConfirmedEmployeeDetails(employee, setSelectedEmployee);

  const [displayVehicles, setDisplayVehicles] = useState(
    vehicles.filter(({ archived }) => !archived)
  );

  const buildDetailsWarning = () => {
    if (status === "incomplete") {
      return {
        text: "This employee's commute schedules are incomplete. Please update their commute endpoints or send them a new employee survey",
        action: <SendEmployeeSurveyButton employee={employee} />,
      };
    }

    if (status === "onLeave") {
      return {
        text: "This employee is currently on leave. Don't forget to take them off leave when they're done.",
      };
    }

    return;
  };

  const views =
    status === "terminated" || status === "unconfirmed"
      ? [
          {
            content: (
              <SurveyEmployeeDetailView
                employee={employee}
                setSelectedEmployee={setSelectedEmployee}
              />
            ),
          },
        ]
      : [
          {
            label: "Emissions",
            value: "emissions",
            content: (
              <EmissionsDetailsBlock
                type="employees"
                emissions={emissions}
                setSelectedTransaction={setSelectedTransaction}
                closeSelectedObjectSlider={() => setSelectedEmployee(null)}
                warning={buildDetailsWarning()}
                name={name}
                id={id}
              />
            ),
          },
          {
            label: "Details",
            value: "details",
            content: confirmedEmployeeDetailsContent,
            footer: confirmedEmployeeDetailsFooter,
          },
          {
            label: "Vehicles",
            value: "vehicles",
            content: (
              <EditableVehiclesCards
                vehicles={displayVehicles}
                setVehicles={setDisplayVehicles}
                employee={employee}
                companyData={companyData}
              />
            ),
            footer: (
              <VehiclesDetailsFooter setDisplayVehicles={setDisplayVehicles} />
            ),
          },
          {
            label: "Commuting",
            value: "commuting",
            content: (
              <EmployeeCommuteSchedulesView
                employee={employee}
                setSelectedEmployee={setSelectedEmployee}
              />
            ),
          },
        ];

  return (
    <SettingsObjectDetailsSlider
      type="employees"
      settingsObject={employee}
      onNameSave={onNameSave}
      setSelectedObject={setSelectedEmployee}
      views={views}
    />
  );
};
export default EmployeeDetailsSlider;
