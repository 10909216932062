import React, { useState } from "react";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { TextField } from "@aclymatepackages/atoms";
import { DEFAULT_TONS_CO2E_PER_MILE } from "@aclymatepackages/constants";

import DistanceInputOptions from "../DistanceInputOptions";

import {
  buildInitialTransactionInputValueFromSchema,
  findDistanceMiles,
} from "../../../helpers/components/inputs";

const DollarInput = ({ value, setValue }) => (
  <TextField
    label="Estimated Dollar Amount"
    value={value}
    setValue={setValue}
    helperText={value && !numbersRegExpTest(value) && "This must be a number"}
    id="rides-estimated-dollar-amount"
  />
);

const useRidesInput = ({ transaction, onSave, setCalcLoading }) => {
  const inputSchema = [
    { field: "rideFrom" },
    { field: "from" }, //We changed the schema from "rideFrom" to "from" so we still need to account for older transactions
    { field: "to" },
    { field: "rideTo" }, //Same as above
    { field: "mileageEstimate" },
    { field: "dollarAmount" },
    { field: "distance" },
  ];

  const [calculatedMileage, setCalculatedMileage] = useState(0);
  const [inputData, setInputData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );
  const editInputData = (field) => (value) =>
    editObjectData(setInputData, field, value);

  const {
    rideTo,
    to,
    rideFrom,
    from,
    mileageEstimate,
    dollarAmount,
    distance,
  } = inputData;

  const editDollarAmountEstimate = async (amount) => {
    editInputData("dollarAmount")(amount);
    editInputData("from")("");
    editInputData("to")("");
    editInputData("distance")("");
    editInputData("distanceUnit")("");

    const AVERAGE_RIDE_COST_PER_MILE = 1.5;
    return setCalculatedMileage(amount / AVERAGE_RIDE_COST_PER_MILE);
  };

  const descriptionObj =
    rideTo && rideFrom
      ? { description: `${rideFrom.description} -> ${rideTo.description}` }
      : {};

  const inputBlock = (
    <DistanceInputOptions
      type="Ride"
      inputData={{ ...inputData, from: from || rideFrom, to: to || rideTo }}
      editInputData={editInputData}
      clearOtherInputs={() => editInputData("dollarAmount")("")}
      setCalculatedMileage={setCalculatedMileage}
      setCalcLoading={setCalcLoading}
      oldDbMileageValue={mileageEstimate}
      additionalRows={[
        {
          RowComponent: DollarInput,
          props: { value: dollarAmount, setValue: editDollarAmountEstimate },
          title: "Enter Ride Cost (Good)",
        },
      ]}
    />
  );

  const tonsCo2e =
    (findDistanceMiles(inputData, calculatedMileage) || mileageEstimate) *
    DEFAULT_TONS_CO2E_PER_MILE;

  const onTransactionSave = () =>
    onSave({
      ...descriptionObj,
      ...inputData,
      tonsCo2e,
    });

  const saveEnabled =
    (to && from) ||
    (!!distance && numbersRegExpTest(distance)) ||
    !!dollarAmount;

  return {
    inputBlock,
    onTransactionSave,
    saveEnabled,
    tonsCo2e,
  };
};
export default useRidesInput;
