import React from "react";

import { Grid } from "@mui/material";

import {
  usGallonsToBritishGallons,
  usGallonsToLiters,
  litersToUsGallons,
  britishGallonsToLiters,
} from "@aclymatepackages/converters";
import { TextField, Select } from "@aclymatepackages/atoms";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";

import useMeasurementSystem from "../../helpers/hooks/measurementSystem";

const FuelVolumeInput = ({
  billGallons,
  fuelVolume = 0,
  volumeUnit,
  editTransaction,
}) => {
  const { measurementSystem } = useMeasurementSystem();

  const isImperial = measurementSystem === "imperial";

  const volumeLabel = isImperial ? "Gallons" : "Volume";

  const defaultVolumeUnit = volumeUnit || (isImperial ? "usGallons" : "liters");

  const displayVolumeAndUnit = () => {
    if (!fuelVolume && !billGallons) {
      return { volume: "", unit: defaultVolumeUnit };
    }

    const convertBillGallons = () => {
      if (isImperial) {
        return { volume: billGallons, unit: "usGallons" };
      }

      const volume =
        volumeUnit === "britishGallons"
          ? usGallonsToBritishGallons(billGallons)
          : usGallonsToLiters(billGallons);

      return { volume, unit: volumeUnit || "liters" };
    };

    if (billGallons) {
      return convertBillGallons();
    }

    const convertVolumeUnitToMetric = () => {
      if (volumeUnit !== "usGallons") {
        return { volume: fuelVolume, unit: volumeUnit };
      }

      return { volume: usGallonsToLiters(fuelVolume), unit: "liters" };
    };

    if (!isImperial) {
      return convertVolumeUnitToMetric();
    }

    const convertVolumeToImperial = () => {
      if (volumeUnit === "liters") {
        return litersToUsGallons(fuelVolume);
      }

      if (volumeUnit === "britishGallons") {
        return britishGallonsToLiters(fuelVolume);
      }

      return fuelVolume;
    };

    const displayVolume = convertVolumeToImperial();

    return { volume: displayVolume, unit: "usGallons" };
  };

  const { volume: displayVolume, unit: displayUnit } = displayVolumeAndUnit();

  const editVolume = (volume) => {
    editTransaction("fuelVolume")(volume);
    return editTransaction("volumeUnit")(displayUnit);
  };

  const editUnit = (unit) => {
    editTransaction(fuelVolume)(displayVolume);
    return editTransaction("volumeUnit")(unit);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={!isImperial ? 8 : 12}>
        <TextField
          label={volumeLabel}
          value={displayVolume}
          setValue={editVolume}
          error={!!displayVolume && !numbersRegExpTest(displayVolume)}
        />
      </Grid>
      {!isImperial && (
        <Grid item xs={4}>
          <Select
            label="Unit of Measure"
            options={[
              { label: "Liters", value: "liters" },
              { label: "British Gallons", value: "britishGallons" },
            ]}
            value={displayUnit}
            editValue={editUnit}
            size="small"
          />
        </Grid>
      )}
    </Grid>
  );
};
export default FuelVolumeInput;
