import React from "react";

import { Grid, Typography, Paper, Box, useTheme } from "@mui/material";

import { useLayoutHelpers } from "../../../helpers/otherHelpers";

import MyAclymateReportTitle from "./MyAclymateReportTitle";

const ReportSubtitle = ({ align = "center", children }) => (
  <Typography variant="h6" color="textSecondary" align={align} paragraph>
    {children}
  </Typography>
);

const ReportPaper = ({ align, title, subtitle, children, action }) => {
  const { isMobile } = useLayoutHelpers();
  const theme = useTheme();

  return (
    <Paper sx={{ borderRadius: theme.spacing(4), height: "100%" }}>
      <Box p={isMobile ? 2 : 6} sx={{ height: "100%" }}>
        <Grid
          container
          justifyContent={align === "center" ? "center" : "space-between"}
          alignItems="center"
          wrap={isMobile ? "wrap" : "nowrap"}
          spacing={2}
        >
          <Grid item xs={12}>
            {title && (
              <MyAclymateReportTitle align={align}>
                {title}
              </MyAclymateReportTitle>
            )}
            {subtitle && (
              <ReportSubtitle align={align}>{subtitle}</ReportSubtitle>
            )}
          </Grid>
          {action && <Grid item>{action}</Grid>}
        </Grid>
        {children}
      </Box>
    </Paper>
  );
};
export default ReportPaper;
