import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Typography,
  Button,
  Skeleton,
  IconButton,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faCircleMinus,
  faCircleCheck,
  faLock,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons";

import { DefaultPaper } from "@aclymatepackages/atoms";
import { ucFirstLetters } from "@aclymatepackages/formatters";
import { findHighestRankedTier } from "@aclymatepackages/badges";

import GrayPaper from "../../atoms/GrayPaper";
import SliderHeader from "../../atoms/SliderHeader";
import ActionLinkButton from "../../atoms/buttons/ActionLinkButton";
import ErrorBoundary from "../../atoms/ErrorBoundary";
import Link from "../../atoms/mui/Link";
import SlideLayout from "../../layouts/SlideLayout";

import {
  useClimateWiseTiers,
  useClimateLeaderTiers,
  useNetZeroTiers,
  useBadges,
  useHighestEarnedTier,
} from "../../../helpers/components/badges";

const AccordionDisplayBlock = ({
  defaultOpen,
  step,
  name,
  description,
  children,
}) => {
  const [open, setOpen] = useState(undefined);

  const isOpen = open === undefined ? defaultOpen : open;

  return (
    <Grid item container display="column" spacing={2}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h5" color="secondary">
            {step}
          </Typography>
          <Typography variant="h3">{name}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setOpen((isOpen) => !isOpen)}>
            <FontAwesomeIcon icon={isOpen ? faCircleMinus : faCirclePlus} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body1">{description}</Typography>
      </Grid>
      {isOpen && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

const CertificationAction = ({ action, text, href, onClick }) => {
  if (action) {
    return <>{action}</>;
  }

  if (href) {
    return (
      <Link href={href} color="textPrimary" underline="always">
        {text}
      </Link>
    );
  }

  if (onClick) {
    return (
      <Button onClick={onClick} style={{ textAlign: "left" }}>
        {text}
      </Button>
    );
  }

  return <Typography variant="body1">{text}</Typography>;
};

const CertificationActionRow = ({ isEarned, ...actionProps }) => (
  <Grid
    item
    container
    spacing={1}
    alignItems="center"
    justifyContent="flex-start"
    wrap="nowrap"
  >
    <Grid item>
      <FontAwesomeIcon icon={isEarned ? faSquareCheck : faSquare} />
    </Grid>
    <Grid item>
      <CertificationAction {...actionProps} />
    </Grid>
  </Grid>
);

const CertificationTierRow = ({ name, isEarned, actions }) => {
  const { palette } = useTheme();

  return (
    <Grid item>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Typography variant="h4">{`${ucFirstLetters(name)} Tier`}</Typography>
        </Grid>
        {isEarned && (
          <Grid item>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: palette.secondary.dark }}
              size="xl"
            />
          </Grid>
        )}
      </Grid>
      {!isEarned &&
        actions.map((action, key) => (
          <CertificationActionRow {...action} key={`badge-action-row-${key}`} />
        ))}
    </Grid>
  );
};

const CertificationTiersDisplay = ({
  certification,
  isEarned,
  title,
  subtitle,
  tiers,
  logoImage,
  isLoading,
  setSelectedTabIdx,
}) => {
  console.log("logo image: ", logoImage);
  const areAllTiersEarned = tiers.every(({ isEarned }) => isEarned);

  return (
    <GrayPaper>
      {isLoading ? (
        <Skeleton height={100} />
      ) : (
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={2}>
            <DefaultPaper style={{ position: "relative" }}>
              <img
                alt={certification}
                src={`/images/certifications/png/${logoImage}.png`}
                style={{ width: "100%" }}
              />
              {!isEarned && (
                <Box
                  position="absolute"
                  style={{
                    inset: 0,
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FontAwesomeIcon icon={faLock} size="4x" />
                </Box>
              )}
            </DefaultPaper>
          </Grid>
          <Grid item xs={10}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h3">{title} Certification</Typography>
                <Typography variant="body1" color="textSecondary">
                  {subtitle}
                </Typography>
              </Grid>
              {tiers.map((badge, idx) => (
                <CertificationTierRow
                  {...badge}
                  key={`badge-tier-row-${idx}`}
                />
              ))}
              {areAllTiersEarned && (
                <>
                  <Grid item>
                    <Typography variant="body2">
                      Congratulations! You've earned the highest level in the
                      Aclymate {title} Certification. To set up your custom page
                      to show your accomplishments to your stakeholders, proceed
                      to Page Setup. If your page is already set up, and you do
                      not need to make any changes, proceed to Promote to
                      download your certification logo and promotional
                      materials.
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="center" spacing={2}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setSelectedTabIdx(1)}
                      >
                        Page Setup
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setSelectedTabIdx(2)}
                      >
                        Promote
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </GrayPaper>
  );
};

const ClimateWiseDisplay = ({ setSelectedTabIdx }) => {
  const [
    { certification: highestRankedCertification },
    highestRankedCertificationLoading,
  ] = useHighestEarnedTier();
  const [climateWiseBadge, climateWiseLoading] = useClimateWiseTiers();
  const { tiers } = climateWiseBadge;
  const { level: highestRankedLevel } = findHighestRankedTier(tiers);

  return (
    <AccordionDisplayBlock
      step="Step 1:"
      name="Get Climate Wise Certified"
      defaultOpen={highestRankedCertification === "climate-wise"}
      description="The first step on your climate journey is to get your Climate Wise certification. This is Aclymate's basic level of certification and is the first step on your climate path. Plus, once you achieve the Silver Tier, you'll be able to qualify for our top certifications!"
    >
      <CertificationTiersDisplay
        {...climateWiseBadge}
        logoImage={`climate-wise-${highestRankedLevel}`}
        isLoading={highestRankedCertificationLoading || climateWiseLoading}
        setSelectedTabIdx={setSelectedTabIdx}
      />
    </AccordionDisplayBlock>
  );
};

const ClimateLeaderNetZeroTabsDisplay = ({ setSelectedTabIdx }) => {
  const [{ isFinished: isClimateWiseFinished }, climateWiseLoading] =
    useClimateWiseTiers();
  const [netZeroCertification, netZeroLoading] = useNetZeroTiers();
  const { tiers: netZeroTiers } = netZeroCertification;
  const [climateLeaderCertification, climateLeaderLoading] =
    useClimateLeaderTiers();
  const { tiers: climaterLeaderTiers } = climateLeaderCertification;

  const dataLoading =
    netZeroLoading || climateLeaderLoading || climateWiseLoading;

  const isAnyNetZeroEarned = netZeroTiers.some(({ isEarned }) => isEarned);
  const isAnyClimateLeaderEarned = climaterLeaderTiers.some(
    ({ isEarned }) => isEarned
  );

  const { level: highestRankedNetZeroLevel } =
    findHighestRankedTier(netZeroTiers);
  const { level: highestRankedClimatLeaderLevel } =
    findHighestRankedTier(climaterLeaderTiers);

  const [selectedTab, setSelectedTab] = useState("net-zero");
  const [isInitialTabValueSet, setIsInitialTableValueSet] = useState(false);

  useEffect(() => {
    const setInitialTabValue = () => {
      if (isAnyClimateLeaderEarned && !isAnyNetZeroEarned) {
        setSelectedTab("climate-leader");
        return setIsInitialTableValueSet(true);
      }
    };

    if (!dataLoading && !isInitialTabValueSet) {
      setInitialTabValue();
    }
  }, [
    dataLoading,
    isAnyNetZeroEarned,
    isAnyClimateLeaderEarned,
    isInitialTabValueSet,
  ]);

  const netZeroTab = { label: "Net Zero Certification", value: "net-zero" };
  const climateLeaderTab = {
    label: "Climate Leader Certification",
    value: "climate-leader",
  };

  const tabsArray =
    isAnyClimateLeaderEarned && !isAnyNetZeroEarned
      ? [climateLeaderTab, netZeroTab]
      : [netZeroTab, climateLeaderTab];

  const badgeDisplay = {
    "net-zero": (
      <CertificationTiersDisplay
        {...netZeroCertification}
        isEarned={isAnyNetZeroEarned}
        isLoading={dataLoading}
        logoImage={`net-zero-${highestRankedNetZeroLevel}`}
        setSelectedTabIdx={setSelectedTabIdx}
      />
    ),
    "climate-leader": (
      <CertificationTiersDisplay
        {...climateLeaderCertification}
        isEarned={isAnyClimateLeaderEarned}
        isLoading={dataLoading}
        logoImage={`climate-leader-${highestRankedClimatLeaderLevel}`}
        setSelectedTabIdx={setSelectedTabIdx}
      />
    ),
  };

  return (
    <AccordionDisplayBlock
      step="Step 2:"
      name="Choose Your Journey"
      defaultOpen={isClimateWiseFinished}
      description="After you've completed your Climate Wise certification, it's time to choose your own climate adventure! Aclymate's Net Zero certification will not only keep your business aligned with the Paris Agreement, but it will also set your business apart from the competition. Not ready for Net Zero? Try our Climate Leader certification now. You can always head down the Net Zero path later in your journey."
    >
      {!isAnyNetZeroEarned && (
        <Tabs
          value={selectedTab}
          onChange={(_, value) => setSelectedTab(value)}
        >
          {tabsArray.map((tab, idx) => (
            <Tab key={`tab-${idx}`} {...tab} />
          ))}
        </Tabs>
      )}
      {badgeDisplay[selectedTab]}
    </AccordionDisplayBlock>
  );
};

const BadgesSlider = ({ setBadgesSliderOpen, badges }) => {
  return (
    <SlideLayout
      isSlideOpen
      setIsSlideOpen={setBadgesSliderOpen}
      header={
        <SliderHeader
          icon={<FontAwesomeIcon icon={faCertificate} />}
          title="Learn How To Earn Badges"
        />
      }
      content={
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h4" gutterBottom>
              Aclymate badges are used to track progress beyond certifications
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Clarification text explaining how to use badges or where badges
              might occur, etc...
            </Typography>
          </Grid>
          {badges.map(({ name, id, description }, idx) => (
            <Grid
              item
              container
              key={`badge-display-row-${idx}`}
              spacing={2}
              alignItems="center"
            >
              <Grid xs={3}>
                <img
                  alt="badge-logo"
                  src={`/images/badges/svg/${id}.svg`}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid xs={9}>
                <Typography variant="h5" gutterBottom>
                  {name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {description}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      }
    />
  );
};

const BadgesDisplay = () => {
  const [badges] = useBadges();

  const [badgesSliderOpen, setBadgesSliderOpen] = useState(false);

  const earnedBadges = badges.filter(({ isEarned }) => isEarned);

  return (
    <>
      {badgesSliderOpen && (
        <BadgesSlider
          setBadgesSliderOpen={setBadgesSliderOpen}
          badges={badges}
        />
      )}
      <AccordionDisplayBlock
        defaultOpen={!!earnedBadges.length}
        step="Extras:"
        name="Your Badges"
        description="Beyond the certifications you’re earning, you can also earn badges for completing various actions while on your climate journey. These badges can be earned at any point. These will be visible on your certification page for you to show off to all stakeholders."
      >
        <GrayPaper>
          <Grid container spacing={2} direction="column">
            <Grid item container spacing={2} justifyContent="center">
              {earnedBadges.length ? (
                earnedBadges.map(({ id, Badge, years }, idx) => (
                  <Grid item key={`badge-${idx}`}>
                    {Badge ? (
                      <Badge years={years} />
                    ) : (
                      <img
                        alt="badge"
                        src={`/images/badges/svg/${id}.svg`}
                        style={{ width: "150px" }}
                      />
                    )}
                  </Grid>
                ))
              ) : (
                <Grid item>
                  <Typography variant="h3">
                    Looks like you haven't earned any badges yet
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item container justifyContent="center">
              <Grid item>
                <ActionLinkButton onClick={() => setBadgesSliderOpen(true)}>
                  Learn how to earn more badges
                </ActionLinkButton>
              </Grid>
            </Grid>
          </Grid>
        </GrayPaper>
      </AccordionDisplayBlock>
    </>
  );
};

const CertificationsBasecamp = ({ setSelectedTabIdx }) => (
  <ErrorBoundary>
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h1">Certification Basecamp</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" color="textSecondary">
          Aclymate helps organizations like yours celebrate positive climate
          action. Below are certifications you can earn by achieving your
          climate goals. Each of these certifications unlocks a dedicated web
          page where we help you celebrate your accomplishments with your
          customers, employees, investors, vendors and all stakeholders.
        </Typography>
      </Grid>
      <ClimateWiseDisplay setSelectedTabIdx={setSelectedTabIdx} />
      <ClimateLeaderNetZeroTabsDisplay setSelectedTabIdx={setSelectedTabIdx} />
      <BadgesDisplay />
    </Grid>
  </ErrorBoundary>
);
export default CertificationsBasecamp;
