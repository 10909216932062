import React from "react";

import { Grid, Box } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListCheck } from "@fortawesome/pro-solid-svg-icons";

import { ucFirstLetters } from "@aclymatepackages/formatters";

import Link from "../atoms/mui/Link";
import Card from "../atoms/mui/Card";

const ReductionsBlogModule = ({ type }) => {
  const blogPosts = {
    "spend-based":
      "https://aclymate.com/blog/climate-education/reducing-spend-based-carbon-footprint",
    events:
      "https://aclymate.com/blog/climate-education/reducing-events-carbon-footprint",
    operations:
      "https://aclymate.com/blog/climate-education/reducing-operations-carbon-footprint",
    employee:
      "https://aclymate.com/blog/climate-education/reducing-employee-carbon-footprint",
    utilities:
      "https://aclymate.com/blog/climate-education/reducing-utilities-carbon-footprint",
    travel:
      "https://aclymate.com/blog/climate-education/reducing-travel-carbon-footprint",
  };

  const subtitleThirdSentence = type
    ? `We've put together a helpful resource to guide you through reducing your company's ${type} emissions.`
    : "We've put together helpful resources that outline tips on reducing each category of your carbon emissions";

  return (
    <Card
      cardType="layout"
      icon={<FontAwesomeIcon icon={faListCheck} />}
      title={`${type ? `${ucFirstLetters(type)} ` : ""}Emission Reductions`}
      subtitle={`Reducting your emissions is a vital piece of the puzzle in your climate journey. In order to reach your climate goals it is important to hit all three steps of the process: Measure, Reduce and Offset. ${subtitleThirdSentence}`}
      content={
        <Box pl={7.5}>
          {type ? (
            <Link href={blogPosts[type]}>
              Reduce your {ucFirstLetters(type)} Emissions
            </Link>
          ) : (
            <Grid container spacing={2}>
              {Object.entries(blogPosts).map(([key, value], idx) => (
                <Grid item key={`reductions-blog-link-${idx}`} sm={4}>
                  <Link href={value}>
                    Reduce your {ucFirstLetters(key)} Emissions
                  </Link>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      }
    />
  );
};
export default ReductionsBlogModule;
