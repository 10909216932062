import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";

import { faLeaf } from "@fortawesome/pro-solid-svg-icons";

import { nameToFirstName, tonsToLbs } from "@aclymatepackages/converters";
import { formatDecimal } from "@aclymatepackages/formatters";
import { getYearlyTonsCo2eValues } from "@aclymatepackages/calcs/myAclymate";
import {
  US_AVERAGE_PER_CAPITA_HOME_TONS_CO2E_PER_YEAR,
  US_AVERAGE_VEHICLE_TRAVEL_TONS_CO2E_PER_YEAR,
  US_AVERAGE_FLIGHTS_TONS_CO2E_PER_YEAR,
  US_AVERAGE_DIET_TONS_CO2E_PER_YEAR,
  US_AVERAGE_EXPENDITURES_PER_CAPITA_TONS_CO2E_PER_YEAR,
} from "@aclymatepackages/constants";
import { useLayoutHelpers, mainTheme } from "@aclymatepackages/themes";

import FootprintVideo from "./FootprintVideo";
import BlogBox from "./BlogBox";
import MyAclymateSurveyOffsetSubscriptionPurchase from "./MyAclymateSurveyOffsetSubscriptionPurchase";
import OffsetBucketsBox from "./OffsetBucketsBox";

import Link from "../atoms/mui/Link";
import MyAclymateReportTitle from "../atoms/myAclymate/MyAclymateReportTitle";
import MyAclymateReportPaper from "../atoms/myAclymate/MyAcymateReportPaper";
import Backdrop from "../atoms/mui/Backdrop";
import FootprintEquivalencies from "../modules/report/FootprintEquivalencies";
import ComparisonChart from "../modules/report/ComparisonChart";

import { fetchOurApi } from "../../helpers/utils/apiCalls";
import { isObjectEmpty } from "../../helpers/otherHelpers";
import useMyAclymateSurveyCategories from "../../helpers/components/display-lists/myAclymateSurveyCategories";
import { analyticsTrack } from "../../helpers/analytics";

const WEBFLOW_BLOG_MY_ACLYMATE_COLLECTION_ID = "6554f3816393b8edee7e5e85";

const WorkspaceCTABlock = ({ name, email, yearlyWorkRelatedTonsCo2e }) => {
  const emailBody = `Hello,\n
I was using this software to measure my personal carbon footprint today and I found out that they can do the same for businesses like ours too: https://aclymate.com/learn-about-aclymate
\nHave you thought about using software like this before? Besides being good for the environment, on their website they say it’s also good for:
\n-Setting our brand apart as a leader in our industry
\n-Impressing our customers (72% of B2B buyers are more likely to buy from socially responsible companies)
\n-Retaining employees (64% of millennials only work for environmentally-conscious companies)
\nLet me know what you think.
\nThanks,
\n${nameToFirstName(name)}`;

  return (
    <MyAclymateReportPaper
      title={`Your work-related emissions account for ${formatDecimal(
        tonsToLbs(yearlyWorkRelatedTonsCo2e)
      )} lbs of your total
                  yearly footprint.`}
      subtitle="Your employer can help you offset your work-related emissions. And ever wonder how big your company's total footprint must be? Email your boss more information to see if you can help get your company on board, too! You’ll be able to edit the email before you send it."
    >
      <Grid container direction="column">
        <Grid item container justifyContent="center">
          <Grid item>
            <Link
              href={`mailto:?subject=${encodeURIComponent(
                "Can you take a look at this?"
              )}&body=${encodeURIComponent(emailBody)}`}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  analyticsTrack("myAclymate Email My Boss Click", {
                    name,
                    email,
                  })
                }
              >
                Email My Boss
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item xs={8}>
            <img
              src="/images/platform/new-team-members.svg"
              style={{ width: "100%" }}
              alt="work-related emissions"
            />
          </Grid>
        </Grid>
      </Grid>
    </MyAclymateReportPaper>
  );
};

const MyAclymateReport = ({ individual = {} }) => {
  const { isMobile } = useLayoutHelpers();

  const { name, id: individualId, email, numPeople = 1 } = individual;

  const { palette } = useTheme();
  const [cookies, setCookie] = useCookies();
  const videoCookie = cookies["my-aclymate-video-played"];
  const [showVideo, setShowVideo] = useState(false);
  const [emissionsData, setEmissionsData] = useState({});
  const [subscriptionData, setSubscriptionData] = useState(null);
  const isEmissionsDataEmpty = isObjectEmpty(emissionsData);

  const yearlyTonsCo2eObj = getYearlyTonsCo2eValues(emissionsData);
  const { yearlyTotalTonsCo2e } = yearlyTonsCo2eObj;

  const totalAvgHomeTonsCo2e =
    US_AVERAGE_PER_CAPITA_HOME_TONS_CO2E_PER_YEAR * numPeople;
  const totalAvgVehicleTravelTonsCo2e =
    US_AVERAGE_VEHICLE_TRAVEL_TONS_CO2E_PER_YEAR * numPeople;
  const totalAvgFlightsTonsCo2e =
    US_AVERAGE_FLIGHTS_TONS_CO2E_PER_YEAR * numPeople;
  const totalAvgDietTonsCo2e = US_AVERAGE_DIET_TONS_CO2E_PER_YEAR * numPeople;
  const totalAvgExpendituresTonsCo2e =
    US_AVERAGE_EXPENDITURES_PER_CAPITA_TONS_CO2E_PER_YEAR * numPeople;

  const footprintCategoriesArray = useMyAclymateSurveyCategories({
    ...yearlyTonsCo2eObj,
    homeAverageTonsCo2e: totalAvgHomeTonsCo2e,
    travelAverageTonsCo2e: totalAvgVehicleTravelTonsCo2e,
    flightsAverageTonsCo2e: totalAvgFlightsTonsCo2e,
    dietAverageTonsCo2e: totalAvgDietTonsCo2e,
    spendingAverageTonsCo2e: totalAvgExpendituresTonsCo2e,
  });

  const comparisonChartCategoriesArray = [
    {
      label: "Total Emissions",
      icon: faLeaf,
      color: palette.primary.main,
      tonsCo2e: yearlyTotalTonsCo2e,
      averageTonsCo2e:
        totalAvgHomeTonsCo2e +
        totalAvgVehicleTravelTonsCo2e +
        totalAvgFlightsTonsCo2e +
        totalAvgDietTonsCo2e +
        totalAvgExpendituresTonsCo2e,
    },
    ...footprintCategoriesArray,
  ];

  useEffect(() => {
    if (!videoCookie) {
      setShowVideo(true);
      setCookie("my-aclymate-video-played", true, { path: "/" });
    }
  }, [videoCookie, setCookie]);

  useEffect(() => {
    if (individual && isEmissionsDataEmpty) {
      fetchOurApi({
        path: "/calcs/individuals/fetch-individual-carbon",
        method: "POST",
        data: { individual },
        callback: (res) => setEmissionsData(res),
      });
    }
  }, [individual, emissionsData, isEmissionsDataEmpty]);

  return (
    <>
      {showVideo && (
        <Backdrop
          style={{ zIndex: 1000 }}
          open={showVideo}
          onClose={() => setShowVideo(false)}
        >
          <FootprintVideo
            monthlyFootprintTons={yearlyTotalTonsCo2e / 12}
            actionButton={
              <ThemeProvider theme={mainTheme}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowVideo(false)}
                >
                  See My Report
                </Button>
              </ThemeProvider>
            }
          />
        </Backdrop>
      )}
      {subscriptionData && (
        <Backdrop
          style={{ zIndex: 1000 }}
          open={subscriptionData}
          onClose={() => setSubscriptionData(null)}
        >
          <Box
            style={{ height: "100vh" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <MyAclymateSurveyOffsetSubscriptionPurchase
              setSubscriptionData={setSubscriptionData}
              individualId={individualId}
              {...subscriptionData}
            />
          </Box>
        </Backdrop>
      )}
      <Container maxWidth="xl">
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Typography variant="h2" align="center" paragraph>
            {nameToFirstName(name)}'s Carbon Footprint Results
          </Typography>
          <MyAclymateReportPaper>
            <Grid container spacing={2}>
              <Grid item sm={9} xs={12}>
                <MyAclymateReportTitle align={isMobile ? "center" : "left"}>
                  Your Total Annual Carbon Footprint...
                </MyAclymateReportTitle>
                <Typography
                  sx={{ fontSize: "4em", fontWeight: 900 }}
                  display={isMobile ? "block" : "inline"}
                  align={isMobile ? "center" : "left"}
                >
                  {formatDecimal(tonsToLbs(yearlyTotalTonsCo2e), 0)}
                </Typography>
                <Typography
                  variant="h2"
                  display={isMobile ? "block" : "inline"}
                  align={isMobile ? "center" : "left"}
                >
                  {" "}
                  lbs of CO2e
                </Typography>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Box
                  display="flex"
                  justifyContent={isMobile ? "center" : "flex-end"}
                  style={{ width: "100%" }}
                >
                  <img
                    src="/images/my-aclymate-report-header.svg"
                    style={{
                      width: "100%",
                      maxWidth: isMobile ? "300px" : "200px",
                    }}
                    alt="My Aclymate Report Header"
                  />
                </Box>
              </Grid>
            </Grid>
          </MyAclymateReportPaper>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <MyAclymateReportPaper
                title="Your Emissions Equivalents"
                subtitle="Click the colored icons below to discover what your emissions are equivalent to."
              >
                <FootprintEquivalencies
                  footprintTons={yearlyTotalTonsCo2e}
                  exclusionCategories={["tree-sequestration", "carbon-budget"]}
                />
              </MyAclymateReportPaper>
            </Grid>
            <Grid item md={6}>
              <MyAclymateReportPaper
                title="You vs. The U.S. Average"
                subtitle="Use the dropdown to see how you compare in each emissions category."
              >
                <ComparisonChart categories={comparisonChartCategoriesArray} />
              </MyAclymateReportPaper>
            </Grid>
          </Grid>
          <OffsetBucketsBox
            name={name}
            email={email}
            setSubscriptionData={setSubscriptionData}
            emissionsData={emissionsData}
          />
          <Grid container spacing={2} alignItems="stretch">
            <Grid item md={6} xs={12}>
              <WorkspaceCTABlock
                email={email}
                name={name}
                {...yearlyTonsCo2eObj}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <BlogBox collectionId={WEBFLOW_BLOG_MY_ACLYMATE_COLLECTION_ID} />
            </Grid>
          </Grid>
          {!isMobile && (
            <MyAclymateReportPaper
              align={isMobile ? "center" : "left"}
              title="Your Personalized Carbon Footprint Video!"
              subtitle="Learn more about your carbon footprint with this personalized carbon emissions video!"
              action={
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ReplayIcon />}
                  onClick={() => setShowVideo(true)}
                >
                  Replay Video
                </Button>
              }
            />
          )}
          <Box pt={2} display="flex" flexDirection="column" alignItems="center">
            <img
              src="/images/aclymate-full-logo.png"
              alt="Aclymate Logo"
              width="300"
            />
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
            >
              All emissions calculations use the GHG Protocol, the world's most
              widely used greenhouse gas accounting standards and guidance. For
              more information, visit{" "}
              <Link href="https://ghgprotocol.org">
                https://ghgprotocol.org
              </Link>
              .
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};
export default MyAclymateReport;
