import React from "react";

import { Grid } from "@mui/material";

import { milesToKm } from "@aclymatepackages/converters";
import { TextField } from "@aclymatepackages/atoms";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";

import useMeasurementSystem from "../../helpers/hooks/measurementSystem";

const DistanceInput = ({
  distance,
  editTransaction,
  oldDbMileageValue,
  clearOtherInputs = () => {},
}) => {
  const { measurementSystem } = useMeasurementSystem();

  const isImperial = measurementSystem === "imperial";

  const distanceLabel = isImperial ? "Miles" : "Kilometers";

  const fetchDisplayValue = () => {
    if (!distance && !oldDbMileageValue) {
      return "";
    }

    if (distance) {
      return distance;
    }

    const displayValue = isImperial
      ? oldDbMileageValue
      : milesToKm(oldDbMileageValue);

    return displayValue;
  };

  const onEditDistance = (value) => {
    clearOtherInputs();
    const valueUnit = isImperial ? "miles" : "kms";
    editTransaction("distanceUnit")(valueUnit);
    return editTransaction("distance")(value);
  };

  return (
    <Grid item xs={12}>
      <TextField
        label={distanceLabel}
        value={fetchDisplayValue()}
        setValue={onEditDistance}
        helperText={
          distance && !numbersRegExpTest(distance) && "This must be a number"
        }
        error={distance && !numbersRegExpTest(distance)}
      />
    </Grid>
  );
};
export default DistanceInput;
