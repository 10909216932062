import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router";

import {
  Box,
  Grid,
  Button,
  IconButton,
  StyledEngineProvider,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/pro-solid-svg-icons";

import { AnimatedLogo } from "@aclymatepackages/atoms";
import { useLayoutHelpers } from "@aclymatepackages/themes";

import Link from "../atoms/mui/Link";

import MyAclymateReport from "./MyAclymateReport";
import MyAclymateSurvey from "./MyAclymateSurvey";

import { fetchOurApi } from "../../helpers/utils/apiCalls";

import TopBarSupportIcon from "../atoms/buttons/TopBarSupportIcon";

import { useAuth } from "../../helpers/firebase";
import { useLogout } from "../../helpers/components/accountAccess";
import { StripeCustomerContextProvider } from "../../helpers/contexts/stripeCustomer";

const MyAclymateLayout = ({ isCompletedMyAclymateOnboarding, children }) => {
  const { palette } = useTheme();
  const [user, userLoading] = useAuth();
  const { isMobile, theme, standardBoxPadding } = useLayoutHelpers();

  const logout = useLogout();

  return (
    <Box
      sx={{
        height: "100vh",
        boxSizing: "border-box",
        overflow: "hidden",
      }}
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
    >
      <Box
        py={isMobile ? 1 : 3}
        px={isMobile ? 2 : 4}
        sx={{
          backgroundColor: theme.palette.backgroundGray.dark,
          boxShadow: "0 2px 4px #475467",
          zIndex: 2,
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item>
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
              <Grid item style={{ cursor: "pointer" }}>
                <Link href="https://aclymate.com">
                  {isMobile ? (
                    <img
                      src="/images/aclymate-icon.png"
                      alt="RWT logo"
                      style={{ width: "40px" }}
                    />
                  ) : (
                    <img
                      src="/images/aclymate-full-logo.png"
                      alt="RWT logo"
                      style={{ maxWidth: "200px" }}
                    />
                  )}
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <TopBarSupportIcon
                  companyName="myAclymate Impact Report"
                  showHelpCenter={false}
                  isLightBackground
                />
              </Grid>
              {!userLoading && (
                <Grid item>
                  {!!user ? (
                    <IconButton onClick={() => logout()}>
                      <FontAwesomeIcon
                        icon={faRightFromBracket}
                        size="large"
                        style={{ color: palette.text.primary }}
                      />
                    </IconButton>
                  ) : (
                    <Button variant="contained" color="primary" href="/">
                      Login
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        id="report content block"
        flexGrow={1}
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: isCompletedMyAclymateOnboarding
            ? palette.backgroundGray.main
            : "white",
        }}
        {...standardBoxPadding}
      >
        {children}
      </Box>
    </Box>
  );
};

const SurveyLandingPage = ({ individualDataLoading, setIndividualData }) => {
  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
      }}
      boxSizing="inherit"
      display="flex"
      alignItems="center"
      position="relative"
    >
      {individualDataLoading ? (
        <Grid item container justifyContent="center">
          <AnimatedLogo />
        </Grid>
      ) : (
        <MyAclymateSurvey setIndividualData={setIndividualData} />
      )}
    </Box>
  );
};

const MyAclymatePage = () => {
  const { theme } = useLayoutHelpers();

  const { refresh } = useParams();
  const [cookies] = useCookies(["aclymate-in-slug"]);
  const slugCookie = cookies["aclymate-in-slug"];

  const [individualData, setIndividualData] = useState({});
  const [individualDataLoading, setIndividualDataLoading] = useState(true);

  const { isCompletedMyAclymateOnboarding } = individualData;

  useEffect(() => {
    const fetchIndividualData = async () => {
      if (slugCookie && !refresh) {
        const { email, ...otherIndividualData } = await fetchOurApi({
          path: `/individuals/${slugCookie}`,
          method: "GET",
          callback: (res) => res,
        });

        const { success: doesAclymateAccountAlreadyExist } = await fetchOurApi({
          path: "/onboarding/check-email",
          method: "POST",
          data: { email },
          callback: (res) => res,
        });

        setIndividualData({
          email,
          doesAclymateAccountAlreadyExist,
          ...otherIndividualData,
        });
        return setIndividualDataLoading(false);
      }

      return setIndividualDataLoading(false);
    };

    if (individualDataLoading) {
      fetchIndividualData();
    }
  }, [slugCookie, individualDataLoading, refresh]);

  return (
    <StripeCustomerContextProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MyAclymateLayout
            isCompletedMyAclymateOnboarding={isCompletedMyAclymateOnboarding}
          >
            {isCompletedMyAclymateOnboarding ? (
              <MyAclymateReport individual={individualData} />
            ) : (
              <SurveyLandingPage
                individualDataLoading={individualDataLoading}
                setIndividualData={setIndividualData}
              />
            )}
          </MyAclymateLayout>
        </ThemeProvider>
      </StyledEngineProvider>
    </StripeCustomerContextProvider>
  );
};
export default MyAclymatePage;
