import React from "react";
import dayjs from "dayjs";

import { formatDollars } from "@aclymatepackages/formatters";
import { THERMS_PER_MCF } from "@aclymatepackages/constants";
import { tonsToLbs } from "@aclymatepackages/converters";
import findSubcategory from "@aclymatepackages/subcategories";

import DownloadButton from "./DownloadButton";

const csvColumns = [
  { name: "id", field: "id" }, //in transaction obj
  { name: "Date", field: "date" }, //in transaction obj
  { name: "Name", field: "name" }, //extracted
  { name: "$ Amount", field: "value" }, //in transaction obj
  { name: "Vendor", field: "vendor" }, //in transaction obj
  { name: "Accounting Category", field: "category" }, //in transaction obj
  { name: "NAICS Code", field: "naicsCode" }, //extracted
  { name: "NAICS Description", field: "naicsTitle" }, //extracted
  { name: "Tons CO2e Per Dollar", field: "tonsCo2ePerDollar" }, //extracted
  { name: "Emissions Category", field: "subcategory" }, //in transaction obj
  { name: "Scope Three Category", field: "scopeThreeCategory" }, //in transaction obj
  { name: "Tons CO2e", field: "tonsCo2e" }, //in transaction obj
  { name: "Lbs CO2e", field: "lbsCo2" }, //extracted
  { name: "Fuel Type", field: "fuelType" }, //in transaction obj
  { name: "Gas Unit Value", field: "gasUnitValue" }, //in transaction obj
  { name: "Gas Unit", field: "gasUnit" }, //in transaction obj
  { name: "Recurring Emissions Month Fraction", field: "monthFraction" }, //in transaction obj
  { name: "Total Monthly Electric Tons CO2e", field: "totalElectricTons" }, //extracted
  { name: "Total Monthly Gas Tons CO2e", field: "totalGasTons" }, //extracted
  { name: "Total Company Office Tons CO2e", field: "totalCompanyOfficeTons" }, //extracted
  {
    name: "Total Monthly Company Office Electric Tons CO2e",
    field: "companyOfficeElectricTons", //extracted
  },
  {
    name: "Total Monthly Company Office Gas Tons CO2e",
    field: "companyOfficeGasTons", //extracted
  },
  { name: "Total Monthly Home Office Tons CO2e", field: "totalHomeOfficeTons" }, //extracted
  { name: "Monthly Scope 1", field: "scope1Tons" }, //extracted
  { name: "Monthly Scope 2", field: "scope2Tons" }, //extracted
  { name: "Monthly Scope 3", field: "scope3Tons" }, //extracted
  { name: "Scope", field: "scope" }, //in transaction obj
  { name: "Electric Kwh", field: "electricKwh" }, //in transaction obj
  { name: "Therms", field: "therms" }, //extracted
  {
    name: "Renewables Replacement Tons CO2e",
    field: "electricRenewablesReplacementTons", //in transaction obj
  },
  {
    name: "Electric Renewables Percentage",
    field: "electricRenewablesPercentage", //in transaction obj
  },
  { name: "Tons CO2e before renewables", field: "tonsCo2eBeforeRenewables" }, //extracted
  { name: "Office", field: "office" }, //in transaction obj
  { name: "Vehicle", field: "vehicle" }, //in transaction obj
  { name: "Employees", field: "employees" }, //in transaction obj
];

const splitUtilitiesEmissions = (emissions) =>
  emissions.flatMap((emission) => {
    const { subcategory, details = {}, date, source } = emission;

    if (subcategory !== "utilities" || source !== "recurring") {
      return emission;
    }

    const {
      companyOfficeElectricTons = 0,
      companyOfficeGasTons = 0,
      totalHomeOfficeTons = 0,
      defaultEmissions = 0,
    } = details;

    const monthYearName = `${dayjs(date).format("MMMM")} ${dayjs(date).year()}`;
    const totalOtherEmissionsTons = defaultEmissions + totalHomeOfficeTons;

    return [
      {
        date,
        subcategory: "gas",
        name: `${monthYearName} Office Gas Utilities`,
        tonsCo2e: companyOfficeGasTons * -1,
        lbsCo2: tonsToLbs(companyOfficeGasTons),
        scope: 1,
      },
      {
        date,
        subcategory: "electricity",
        name: `${monthYearName} Office Electric Utilities`,
        tonsCo2e: companyOfficeElectricTons * -1,
        lbsCo2: tonsToLbs(companyOfficeElectricTons),
        scope: 2,
      },
      {
        date,
        subcategory: "home-office",
        name: ` ${monthYearName} Other Utilities`,
        tonsCo2e: totalOtherEmissionsTons * -1,
        lbsCo2: tonsToLbs(totalOtherEmissionsTons),
        scope: 3,
      },
    ];
  });

const findGasTherms = (emission) => {
  const { subcategory, gasUnit, gasUnitValue } = emission;
  if (subcategory !== "gas") {
    return "";
  }
  if (gasUnit === "mcf") {
    return gasUnitValue * THERMS_PER_MCF;
  }

  if (gasUnit === "ccf") {
    return gasUnitValue * (THERMS_PER_MCF / 100);
  }

  return gasUnitValue;
};

const findTonsCo2eBeforeRenewables = (emission) => {
  const { subcategory, details, tonsCo2e } = emission;

  if (subcategory === "home-office") {
    const { homeOfficeElectricRenewablesReplacementTons = 0 } = details || {};

    return tonsCo2e - homeOfficeElectricRenewablesReplacementTons;
  }

  if (subcategory === "electricity") {
    const { companyOfficeElectricRenewablesReplacementTons = 0 } =
      details || {};

    return tonsCo2e - companyOfficeElectricRenewablesReplacementTons;
  }

  return "";
};

const formatCsvEmissions = (emissions = []) =>
  emissions.map((emission) => {
    const {
      id,
      date,
      name,
      value = "",
      vendor,
      category = "",
      knownVendor,
      naicsCode,
      naicsTitle,
      tonsCo2ePerDollar,
      subcategory,
      scopeThreeCategory,
      tonsCo2e: rawTonsCo2e,
      fuelType = "",
      gasUnitValue = "",
      gasUnit = "",
      monthFraction = "",
      details,
      scope: emissionScope,
      electricKwh = "",
      electricRenewablesReplacementTons = "",
      electricRenewablesPercentage = "",
      office,
      vehicle,
      employees,
    } = emission;

    const {
      naicsCode: vendorNaicsCode = "",
      naicsTitle: vendorNaicsTitle = "",
      tonsCo2ePerDollar: vendorTonsCo2ePerDollar = "",
    } = knownVendor || {};

    const {
      totalElectricTons = "",
      totalGasTons = "",
      totalCompanyOfficeTons = "",
      companyOfficeElectricTons = "",
      companyOfficeGasTons = "",
      totalHomeOfficeTons = "",
      scope1Tons = "",
      scope2Tons = "",
      scope3Tons = "",
    } = details || {};

    const findTonsCo2e = () => {
      if (!rawTonsCo2e || isNaN(rawTonsCo2e)) {
        return 0;
      }

      return Math.abs(rawTonsCo2e);
    };

    const tonsCo2e = findTonsCo2e();

    const handleCommas = (str = "") => `"${str}"`;

    const buildNameString = () => {
      if (knownVendor) {
        const { name } = knownVendor;
        return handleCommas(name);
      }

      if (name) {
        return handleCommas(name);
      }

      return handleCommas(vendor);
    };

    const { name: categoryName, scope: subcategoryScope } =
      findSubcategory(subcategory);

    const scope = emissionScope || subcategoryScope;

    const buildScopeThreeCategoryString = () => {
      if (subcategory === "spend-based") {
        return knownVendor?.scopeThreeCategory || scopeThreeCategory || "";
      }

      if (scope === 3) {
        const { scopeThreeCategory } = findSubcategory(subcategory);
        return scopeThreeCategory || "";
      }

      return "";
    };

    const officeString = office ? office.name : "";
    const vehicleString = vehicle ? vehicle.name : "";
    const employeesString = employees
      ? employees.map(({ name }) => name).join("|")
      : "";

    return {
      id,
      date,
      name: buildNameString(),
      value: value ? handleCommas(formatDollars(value)) : "",
      vendor: handleCommas(vendor),
      category: handleCommas(category),
      naicsCode: naicsCode || vendorNaicsCode,
      naicsTitle: handleCommas(naicsTitle || vendorNaicsTitle),
      tonsCo2ePerDollar: tonsCo2ePerDollar || vendorTonsCo2ePerDollar,
      subcategory: categoryName,
      scopeThreeCategory: buildScopeThreeCategoryString(),
      tonsCo2e,
      lbsCo2e: tonsToLbs(tonsCo2e),
      fuelType,
      gasUnitValue,
      gasUnit,
      monthFraction,
      totalElectricTons,
      totalGasTons,
      totalCompanyOfficeTons,
      companyOfficeElectricTons,
      companyOfficeGasTons,
      totalHomeOfficeTons,
      scope1Tons,
      scope2Tons,
      scope3Tons,
      scope,
      electricKwh,
      therms: findGasTherms(emission),
      electricRenewablesReplacementTons,
      electricRenewablesPercentage,
      tonsCo2eBeforeRenewables: findTonsCo2eBeforeRenewables(emission),
      office: officeString,
      vehicle: vehicleString,
      employees: employeesString,
    };
  });

const EmissionsCsvDownload = ({
  emissions,
  tooltip = "Download Emissions CSV File",
  fileName = `${dayjs().format("MMDDYYYY")}-aclymate-emissions-download.csv`,
}) => {
  const onEmissionsDownload = () => {
    const csvHeader = csvColumns.map(({ name }) => name).join(",") + "\r\n";

    const splitEmissions = splitUtilitiesEmissions(
      formatCsvEmissions(emissions)
    );

    const sortedEmissions = [...splitEmissions].sort((a, b) => b.date - a.date);

    const csvRows = sortedEmissions
      .map((emission) => Object.values(emission).join(",") + "\r\n")
      .join("");

    return new Blob([`${csvHeader}${csvRows}`], { type: "text/csv" });
  };

  return (
    <DownloadButton
      tooltip={tooltip}
      buildFile={onEmissionsDownload}
      fileName={fileName}
    />
  );
};
export default EmissionsCsvDownload;
