import React, { useState } from "react";

import { Grid, IconButton, Tooltip, useTheme } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartPie, faChartColumn } from "@fortawesome/pro-regular-svg-icons";

import { EmissionsPieChart } from "@aclymatepackages/modules";

import EmissionsChart from "../../modules/charts/EmissionsChart";

import { useAccountData } from "../../../helpers/firebase";

const useToggleableEmissionsDetailsChart = (
  emissions,
  defaultChart = "bar",
  chartProps = {},
  viewMode
) => {
  const { palette } = useTheme();
  const [{ startDate }] = useAccountData();

  const [selectedGraphType, setSelectedGraphType] = useState(defaultChart);

  const iconButtons = [
    { icon: faChartColumn, tooltip: "Bar Graph View", type: "bar" },
    { icon: faChartPie, tooltip: "Pie Chart View", type: "pie" },
  ];

  const toggles = (
    <Grid container>
      {iconButtons.map(({ icon, tooltip, type }, idx) => (
        <Grid item key={`details-chart-toggle-${idx}`}>
          <Tooltip title={tooltip}>
            <span>
              <IconButton onClick={() => setSelectedGraphType(type)}>
                <FontAwesomeIcon
                  icon={icon}
                  style={{
                    color:
                      selectedGraphType === type
                        ? palette.primary.main
                        : palette.text.secondary,
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );

  const chart =
    selectedGraphType === "bar" ? (
      <EmissionsChart
        dataArray={emissions}
        type="bar"
        startDate={startDate}
        {...chartProps}
      />
    ) : (
      <EmissionsPieChart dataArray={emissions} viewMode={viewMode} />
    );

  return { chart, toggles };
};
export default useToggleableEmissionsDetailsChart;
