import { useTheme } from "@mui/material";

import {
  faHouseUser,
  faCars,
  faTicketAirline,
  faBurgerSoda,
  faBagShopping,
} from "@fortawesome/pro-solid-svg-icons";

const useMyAclymateSurveyCategories = ({
  yearlyHomeTonsCo2e,
  yearlyTravelTonsCo2e,
  yearlyFlightsTonsCo2e,
  yearlyDietTonsCo2e,
  yearlySpendingTonsCo2e,
  homeAverageTonsCo2e,
  travelAverageTonsCo2e,
  flightsAverageTonsCo2e,
  dietAverageTonsCo2e,
  spendingAverageTonsCo2e,
}) => {
  const { palette } = useTheme();

  return [
    {
      label: "Home",
      icon: faHouseUser,
      color: palette.secondary.main,
      tonsCo2e: yearlyHomeTonsCo2e,
      averageTonsCo2e: homeAverageTonsCo2e,
    },
    {
      label: "Travel",
      icon: faCars,
      color: palette.mileage.main,
      tonsCo2e: yearlyTravelTonsCo2e,
      averageTonsCo2e: travelAverageTonsCo2e,
    },
    {
      label: "Flights",
      icon: faTicketAirline,
      color: palette.flights.main,
      tonsCo2e: yearlyFlightsTonsCo2e,
      averageTonsCo2e: flightsAverageTonsCo2e,
    },
    {
      label: "Diet",
      icon: faBurgerSoda,
      color: palette.secondary.dark,
      tonsCo2e: yearlyDietTonsCo2e,
      averageTonsCo2e: dietAverageTonsCo2e,
    },
    {
      label: "Costs",
      icon: faBagShopping,
      color: palette.vendors.main,
      tonsCo2e: yearlySpendingTonsCo2e,
      averageTonsCo2e: spendingAverageTonsCo2e,
    },
  ];
};
export default useMyAclymateSurveyCategories;
