import React, { useState } from "react";

import { Box, useTheme, Slider, Typography } from "@mui/material";

const MIN_SLIDER_RANGE = 15;

const CustomBrush = ({
  min = 0,
  max = 100,
  initialRange,
  onChange,
  step = 1,
  showTitle = false,
  brushColor,
}) => {
  const { palette } = useTheme();
  const [range, setRange] = useState(initialRange || [min, max]);

  const handleRangeChange = (event, newRange, activeThumb) => {
    if (!Array.isArray(newRange)) {
      return;
    }

    const updateStateVariables = (newRange) => {
      if (onChange) {
        onChange(newRange);
      }
      return setRange(newRange);
    };

    const handleSlideFixedSlider = () => {
      if (activeThumb === 0) {
        const clamped = Math.min(newRange[0], max - MIN_SLIDER_RANGE);
        return updateStateVariables([clamped, clamped + MIN_SLIDER_RANGE]);
      }

      const clamped = Math.max(newRange[1], min + MIN_SLIDER_RANGE);
      return updateStateVariables([clamped - MIN_SLIDER_RANGE, clamped]);
    };

    if (newRange[1] - newRange[0] < MIN_SLIDER_RANGE) {
      return handleSlideFixedSlider();
    }

    return updateStateVariables(newRange);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {showTitle && (
        <Typography variant="body2" align="center">
          Range: {range[0]} - {range[1]}
        </Typography>
      )}
      <Slider
        value={range}
        onChange={handleRangeChange}
        valueLabelDisplay="auto"
        min={min}
        max={max}
        step={step}
        disableSwap
        sx={{
          maxWidth: "500px",
          mt: 2,
          height: 8,
          color: palette.primary.main, // Customize color theme
          "& .MuiSlider-track": {
            border: "none",
            background: brushColor || "rgb(51, 153, 51)",
            height: 8,
          },
          "& .MuiSlider-thumb": {
            height: 20,
            width: 20,
            backgroundColor: "#fff",
            border: `1px solid #000`,
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            },
          },
          "& .MuiSlider-rail": {
            opacity: 0.4,
            backgroundColor: "#d6d6d6",
          },
          "& .MuiSlider-valueLabel": {
            backgroundColor: brushColor || "rgb(51, 153, 51)",
            borderRadius: "10px",
            fontSize: "0.75rem",
            "&:before": {
              display: "none", // Removes the default arrow below the value label
            },
          },
          "& .MuiSlider-mark": {
            backgroundColor: "rgba(0,0,0,0.4)",
            height: 8,
            width: 8,
            borderRadius: "50%",
            transition: "transform 0.3s ease, background-color 0.3s ease", // Smooth transition for scale and color
          },
          "& .MuiSlider-markActive": {
            backgroundColor: "primary.main", // Active mark color
            transform: "scale(1.5)", // Slightly enlarge the active mark
          },
          "& .MuiSlider-markLabel": {
            fontSize: "0.75rem",
            color: "rgba(0,0,0,0.6)", // Soft color for labels
            marginTop: 4,
          },
        }}
      />
    </Box>
  );
};
export default CustomBrush;
