import { useContext } from "react";

import { Box, StyledEngineProvider, ThemeProvider } from "@mui/material";

import { Backdrop as BackdropComponent } from "@aclymatepackages/atoms";
import { mainTheme } from "@aclymatepackages/themes";

import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";

const Backdrop = ({
  open = true,
  style = {},
  onClose,
  confirmClose,
  confirmCloseMessage = "Are you sure you want to exit this process?",
  theme = mainTheme,
  children,
  ...otherProps
}) => {
  const { activateSnackbar } = useContext(PlatformLayoutContext) || {};

  const onFormClose =
    onClose &&
    (() => {
      if (!confirmClose) {
        return onClose();
      }

      if (activateSnackbar) {
        activateSnackbar({
          message: confirmCloseMessage,
          alert: "warning",
          onClose,
        });
      }

      return;
    });

  return (
    <BackdropComponent
      open={open}
      style={style}
      onFormClose={onFormClose}
      {...otherProps}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box px={1} py={3} sx={{ height: "100vh", boxSizing: "border-box" }}>
            {children}
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    </BackdropComponent>
  );
};
export default Backdrop;
