import React from "react";

import { AccountingChart as AccountingChartModule } from "@aclymatepackages/modules";

import useMeasurementSystem from "../../../helpers/hooks/measurementSystem";

const AccountingChart = (props) => {
  const { convertCarbonUnits, carbonUnitLabel } = useMeasurementSystem();

  return (
    <AccountingChartModule
      {...props}
      convertCarbonUnits={convertCarbonUnits}
      displayUnitLabel={carbonUnitLabel}
    />
  );
};
export default AccountingChart;
