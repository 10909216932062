import { createContext, useContext } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { convertDbEmissionsToTransactions } from "@aclymatepackages/other-helpers";
import { buildCompanyAggregatedEmissionsArrays } from "@aclymatepackages/calcs/recurring";

import { useEmployeeStatuses } from "../components/employees";
import { PlatformLayoutContext } from "./platformLayout";
import { AdminDataContext } from "./adminData";

import {
  useCachedDisplayData,
  useAccountData,
  useCollectionDataListener,
} from "../firebase";
import { useDisplayEvents } from "../components/events";
import { useTransactionsMonthsData } from "../components/emissions";
import { useFindCurrentEmployeeEmissions } from "../components/employees";
import { formatVendors, buildVendorAlerts } from "../components/vendors";

dayjs.extend(isSameOrAfter);

const EmissionsContext = createContext();

export const EmissionsContextProvider = ({ children }) => {
  const { viewMode = "company" } = useContext(PlatformLayoutContext) || {};
  const { adminData } = useContext(AdminDataContext) || {};

  const { companyData: adminCompanyData } = adminData || {};
  const { startDate: adminStartDate, employeeCount: adminEmployeeCount } =
    adminCompanyData || {};

  const [accountData, accountDataLoading] = useAccountData();
  const { startDate: companyStartDate, employeeCount: companyEmployeeCount } =
    accountData;

  const [utilitiesTransactions, utilitiesTransactionsLoading] =
    useCachedDisplayData("transactions", [
      ["subcategory", "in", ["gas", "electricity", "utilities"]],
    ]);
  const [dbEmissions, dbEmissionsLoading] = useCachedDisplayData("emissions");
  const [dbEmployees, dbEmployeesLoading] = useEmployeeStatuses();
  const [offices, officesLoading] = useCachedDisplayData("offices");
  const [events, eventsLoading] = useDisplayEvents();
  const [transactionsMonthsData, transactionsMonthsDataLoading] =
    useTransactionsMonthsData();

  const [matchedVendorTransactions, matchedVendorTransactionsLoading] =
    useCollectionDataListener("matched-vendors-aggregated-transactions");
  const [unmatchedVendors, unmatchedVendorsLoading] =
    useCollectionDataListener("vendors");
  const [matchedVendors, matchVendorsLoading] =
    useCollectionDataListener("vendor-companies");

  const findCurrentEmployeeEmissions = useFindCurrentEmployeeEmissions();

  const recurringEmissionsLoading =
    dbEmployeesLoading ||
    officesLoading ||
    accountDataLoading ||
    utilitiesTransactionsLoading ||
    eventsLoading ||
    dbEmissionsLoading ||
    transactionsMonthsDataLoading;

  const employeesLoading =
    recurringEmissionsLoading || accountDataLoading || dbEmployeesLoading;

  const vendorsLoading =
    unmatchedVendorsLoading ||
    matchVendorsLoading ||
    recurringEmissionsLoading ||
    matchedVendorTransactionsLoading;

  const emissionStartDates = {
    admin: adminStartDate,
    company: companyStartDate,
  };

  const employeeCount =
    viewMode === "admin" ? adminEmployeeCount : companyEmployeeCount;

  const emissionsStartDate = emissionStartDates[viewMode];

  const buildEmissionsArraysFromExternalData = () => {
    if (recurringEmissionsLoading) {
      return {};
    }

    const { allEmissions, ...otherEmissionsArrays } =
      buildCompanyAggregatedEmissionsArrays({
        companyData: {
          ...accountData,
          startDate: emissionsStartDate,
        },
        dbEmissions,
        employees: dbEmployees,
        offices,
        events,
        utilitiesTransactions,
      });

    const dbEmissionsAsTransactions =
      convertDbEmissionsToTransactions(dbEmissions);

    return {
      ...otherEmissionsArrays,
      allEmissions: [
        ...allEmissions,
        ...dbEmissionsAsTransactions,
        ...transactionsMonthsData,
      ],
    };
  };

  const {
    emissionsMonths = [],
    monthlyCommuteEmissionsTons = [],
    monthlyUtilitiesEmissionsTons = [],
    totalRecurringEmissionsTons = [],
    averageMonthlyRecurringEmissionsTons = [],
    separateUtilitiesOfficesDefaultEmissions = [],
    allEmissions = [],
  } = buildEmissionsArraysFromExternalData();

  const formatDbEmployees = () => {
    if (employeesLoading) {
      return [];
    }

    return findCurrentEmployeeEmissions({
      employees: dbEmployees,
      employeeCount,
      emissionsMonths,
    });
  };

  const formatDbVendors = () => {
    if (vendorsLoading) {
      return { vendors: [], vendorAlerts: [] };
    }

    const vendors = formatVendors({
      matchedVendors,
      unmatchedVendors: unmatchedVendors.filter(({ archived }) => !archived),
      matchedVendorTransactions,
    });
    const vendorAlerts = buildVendorAlerts(vendors);

    return { vendors, vendorAlerts };
  };

  const employees = formatDbEmployees();

  const { vendors, vendorAlerts } = formatDbVendors();

  return (
    <EmissionsContext.Provider
      value={{
        emissionsMonths,
        monthlyCommuteEmissionsTons,
        monthlyUtilitiesEmissionsTons,
        totalRecurringEmissionsTons,
        averageMonthlyRecurringEmissionsTons,
        emissionsLoading: recurringEmissionsLoading,
        separateUtilitiesOfficesDefaultEmissions,
        allEmissions,
        employees,
        vendors,
        vendorAlerts,
      }}
    >
      {children}
    </EmissionsContext.Provider>
  );
};

const useEmissionsContext = () => useContext(EmissionsContext);
export default useEmissionsContext;
