import React from "react";

import { Grid, Button } from "@mui/material";

import { setUserCustomClaims } from "../../helpers/utils/apiCalls";
import { useAuth } from "../../helpers/firebase";
import { onLoginSetSessionAndTracking } from "../../helpers/components/accountAccess";

const ChooseAccountForm = ({
  chooseAccountFormOpen,
  setRedirect,
  hideCompanyButton,
  hideMyAclymateButton,
}) => {
  const [user, userLoading] = useAuth();

  const { company, individual } = chooseAccountFormOpen;

  const loginToChosenAccount = async (chosenLoginType) => {
    const { uid } = user;
    const { accountId, accountType } = chosenLoginType;

    await setUserCustomClaims({
      uid,
      accountId,
      accountCreationType: accountType,
    });

    await user.getIdToken(true);

    const accountIdPrefix =
      accountType === "individual" ? "individuals" : "v2-companies";
    const fullAccountId = `${accountIdPrefix}-${accountId}`;
    onLoginSetSessionAndTracking(user, fullAccountId);

    return setRedirect(
      accountType === "individual"
        ? "/myaclymate"
        : "/platform/company/dashboard"
    );
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      {!hideCompanyButton && (
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => loginToChosenAccount(company)}
            disabled={userLoading}
          >
            Company
          </Button>
        </Grid>
      )}
      {!hideMyAclymateButton && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => loginToChosenAccount(individual)}
            disabled={userLoading}
          >
            myAclymate
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default ChooseAccountForm;
