import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";

import { FREE_TRIAL_DAYS } from "@aclymatepackages/constants";

import AccountAccessLayout from "../layouts/AccountAccessLayout";

import AccountCreationFlow from "./AccountCreationFlow";

import { useExistingIndividualData } from "../../helpers/components/accountAccess";
import { analyticsPage } from "../../helpers/analytics";

const myAclymateAccountCreationBulletPoints = [
  {
    title: "Take the free myAclymate Quiz",
    subtitle:
      "Get started by taking the myAclymate Quiz to calculate your personal carbon footprint and get a breakdown of your emissions for no cost.",
  },
  {
    title: "Select your offsets",
    subtitle:
      "After you get the results from your quiz, select the cause you want to support with your offsets.",
  },
  {
    title: "Reduce your emissions",
    subtitle:
      "Using your emissions results, identify places where you can reduce emissions and update your information as you reduce.",
  },
];

const companyAccountCreationBulletPoints = [
  {
    title: `Sign up for a ${FREE_TRIAL_DAYS}-day free trial`,
    subtitle: `Our free trial allows you to explore our software for ${FREE_TRIAL_DAYS} days without commitment. No credit card required. Cancel anytime.`,
  },
  {
    title: "Confirm your company's information",
    subtitle:
      "We will do our best to fill out your company's information for you so that all you have to do is confirm it.",
  },
  {
    title: "Get your initial emissions estimate",
    subtitle:
      "Our technology will use publicy available information to estimate your company's current emissions.",
  },
];

const AccountCreation = () => {
  const { accountCreationType } = useParams();

  const [individualData, individualDataLoading] = useExistingIndividualData();

  const newAccountQueries = queryString.parse(window.location.search);
  const {
    companyId,
    inviteId,
    email: storedEmail,
    companyName,
  } = newAccountQueries || {};
  const isNewCompanyAdmin = !!companyId && !!inviteId && !!storedEmail;
  const newCompanyAdminProp = isNewCompanyAdmin && newAccountQueries;

  useEffect(() => {
    analyticsPage("account-creation");
  }, []);

  const buildPageTitleSubtitle = () => {
    if (accountCreationType !== "company") {
      return {
        title: "Track and offset your footprint",
        subtitle:
          "Create your free myAclymate account to track and offset your individual footprint.",
        imagePath: "/images/platform/login-image.jpg",
        bulletPoints: myAclymateAccountCreationBulletPoints,
      };
    }

    if (isNewCompanyAdmin) {
      return {
        title: `Join ${companyName} on Aclymate to help them save the world.`,
        subtitle: `With Aclymate you'll be able to help ${companyName} easily track, reduce, and offsets their carbon footprint.`,
        imagePath: "/images/platform/account-access-header.jpg",
        bulletPoints: companyAccountCreationBulletPoints,
      };
    }

    return {
      title: `${
        companyName ? `Welcome ${companyName}, s` : "S"
      }tart your climate journey free for ${FREE_TRIAL_DAYS} days`,
      subtitle:
        "Create your free account to track and offset your company footprint.",
      imagePath: "/images/platform/account-access-header.jpg",
      bulletPoints: companyAccountCreationBulletPoints,
    };
  };

  const { title, subtitle, imagePath, bulletPoints } = buildPageTitleSubtitle();

  const accountAccessLayoutProps = {
    formTitle: title,
    formSubtitle: subtitle,
    imagePath,
    bulletPoints,
    formDataLoading: individualDataLoading,
    accountCreationType,
    layoutType: "account-creation",
  };

  const accountCreationFormProps =
    accountCreationType === "company"
      ? {
          isNewCompanyAdmin: newCompanyAdminProp,
          companyId,
        }
      : {
          individualData,
        };

  return (
    <AccountAccessLayout {...accountAccessLayoutProps}>
      <AccountCreationFlow {...accountCreationFormProps} />
    </AccountAccessLayout>
  );
};
export default AccountCreation;
