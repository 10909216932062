import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useBetween } from "use-between";
import { mutate } from "swr";
import dayjs from "dayjs";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { emailRegExpTest } from "@aclymatepackages/reg-exp";
import { YesNoQuestion } from "@aclymatepackages/modules";
import { formatDate } from "@aclymatepackages/formatters";
import { incorrectNameCheck } from "@aclymatepackages/other-helpers";

import { useNewVehiclesForm } from "./vehicles";

import BuildWorkspacesForm from "../inputs/employees/BuildWorkspacesForm";

import DatePicker from "../atoms/mui/DatePicker";
import PlacesAutocomplete from "../inputs/autocomplete/PlacesAutocomplete";

import {
  isObjectEmpty,
  getAccountCollectionAndId,
} from "../../helpers/otherHelpers";
import { useSharedFormLoading } from "../../helpers/components/inputs";
import {
  fetchOurApi,
  findPropertyDetails,
  fetchNearestAirportFromCoordinates,
} from "../../helpers/utils/apiCalls";
import { findGoogleAddressStringDetails } from "../../helpers/utils/geography";

const setEmployeeAddress = (setEmployee) => async (place) => {
  if (place) {
    const { place_id, description } = place;
    const { zipCode, city, state, country, coordinates } =
      await findGoogleAddressStringDetails(place_id);

    findPropertyDetails({
      description,
      city,
      state,
      zipCode,
    }).then(({ error, id: realtyMolePropertyId, squareFootage }) => {
      if (!error) {
        setEmployee("homeSqFootage")(squareFootage);
        return setEmployee("realtyMolePropertyId")(realtyMolePropertyId);
      }

      return null;
    });

    await fetchNearestAirportFromCoordinates(coordinates, (res) =>
      setEmployee("defaultAirport")(res)
    );

    return setEmployee("address")({
      description: description,
      zipCode,
      state,
      country,
      coordinates,
      placeId: place_id,
    });
  }
  return setEmployee("address")(null);
};

const useNewEmployee = () => {
  const [newEmployee, setNewEmployee] = useState({});
  const [employeeVehicles, setEmployeeVehicles] = useState([]);

  return {
    newEmployee,
    setNewEmployee,
    employeeVehicles,
    setEmployeeVehicles,
  };
};

export const useSharedNewEmployee = () => useBetween(useNewEmployee);

const usePersonalInfoForm = ({
  survey,
  givenEmployee,
  setGivenEmployee,
  companyStartDate,
  mostRecentAccountingDate,
}) => {
  const { newEmployee, setNewEmployee } = useSharedNewEmployee();

  const editNewEmployee = (field) => (value) =>
    editObjectData(setGivenEmployee || setNewEmployee, field, value);

  const [employeeEmailWarningText, setEmployeeEmailWarningText] =
    useState(null);

  const [isStartDateNotNeeded, setIsStartDateNotNeeded] = useState();

  const { address, email, name, startDate } = givenEmployee || newEmployee;
  const { description } = address || {};

  const editEmployeeEmail = (email) => {
    editNewEmployee("email")(email);

    if (email && !emailRegExpTest(email)) {
      return setEmployeeEmailWarningText("This must be a valid email address.");
    }

    return setEmployeeEmailWarningText(null);
  };

  const isStartDateNeededRow = !mostRecentAccountingDate
    ? [
        {
          value: isStartDateNotNeeded !== undefined,
          input: (
            <YesNoQuestion
              question={`Did ${
                survey ? "you" : "this employee"
              } start working here before ${formatDate(companyStartDate)}?`}
              value={isStartDateNotNeeded}
              setValue={(value) => {
                setIsStartDateNotNeeded(value);

                return editNewEmployee("startDate")(null);
              }}
            />
          ),
        },
      ]
    : [];

  const startDateInputRow = !isStartDateNotNeeded
    ? [
        {
          value: startDate,
          input: (
            <DatePicker
              label={
                survey
                  ? "When did you start your job?"
                  : "When did this employee start here?"
              }
              date={startDate}
              minDate={
                mostRecentAccountingDate &&
                dayjs(mostRecentAccountingDate).add(1, "day").toDate()
              }
              maxDate={new Date()}
              editDate={editNewEmployee("startDate")}
              inputVariant="standard"
              darkTheme
            />
          ),
        },
      ]
    : [];

  return {
    personalInfoForm: [
      {
        label: survey ? "What is your name?" : "What is this employee's name?",
        editData: editNewEmployee("name"),
        value: name,
        error: name && incorrectNameCheck(name),
      },
      {
        label: survey
          ? "What is your email address?"
          : "What is this employee's email address?",
        value: email,
        error: !email,
        editData: editEmployeeEmail,
      },
      {
        value: address,
        input: (
          <PlacesAutocomplete
            place={description || ""}
            label={
              survey
                ? "What is your home address, zip code, or city that you live in?"
                : "What is this employee's home address, zip code, or city that they live in?"
            }
            editPlace={setEmployeeAddress(editNewEmployee)}
            size="small"
            variant="standard"
            id="newEmployeeAddressField"
          />
        ),
      },
      ...isStartDateNeededRow,
      ...startDateInputRow,
    ],
    employeeEmailWarningText,
  };
};

export const useNewEmployeeForm = ({
  onClose = () => {},
  existingEmployee,
  setParentStep,
  surveyThankYouStep,
  companyStartDate,
  mostRecentAccountingDate,
}) => {
  const paramsCompanyId = useParams()?.companyId;
  const fullAccountId = window.sessionStorage.getItem("accountId");
  const { id } = getAccountCollectionAndId(fullAccountId);
  const companyId = paramsCompanyId || id;

  const { newEmployee, employeeVehicles, setNewEmployee, setEmployeeVehicles } =
    useSharedNewEmployee();

  useEffect(() => {
    if (existingEmployee) {
      setNewEmployee(existingEmployee);
    }
  }, [existingEmployee, setNewEmployee]);

  const { name } = newEmployee;

  const { personalInfoForm, employeeEmailWarningText } = usePersonalInfoForm({
    survey: surveyThankYouStep,
    companyStartDate,
    mostRecentAccountingDate,
  });

  const { setFormLoading } = useSharedFormLoading();

  const newEmployeeSubmit = async (commuteSchedules) => {
    if (!surveyThankYouStep) {
      setFormLoading(true);
    }

    const vehiclesArray = isObjectEmpty(employeeVehicles[0])
      ? []
      : employeeVehicles;

    const {
      address,
      startDate,
      homeSqFootage,
      realtyMolePropertyId,
      ...otherEmployeeProps
    } = newEmployee;

    const sharedEmployeeProps = {
      employeeVehicles: vehiclesArray,
      commuteSchedules,
    };

    //This process runs in the back-end instead of the front-end to allow for unauthenticated users in the employee survey
    const confirmedEmployee = await fetchOurApi({
      accountId: `v2-companies-${companyId}`,
      path: "/individuals/new-employee",
      method: "POST",
      data: {
        employee: {
          ...otherEmployeeProps,
          startDate: startDate && startDate.toDate(),
        },
        ...sharedEmployeeProps,
        companyId,
      },
      callback: (response) => response,
    });

    if (setParentStep) {
      return setParentStep();
    }

    mutate("employees", (currentEmployees = []) => [
      ...currentEmployees,
      confirmedEmployee,
    ]);

    return onClose();
  };

  const newVehiclesForm = useNewVehiclesForm({
    newEmployeeName: name,
    newVehicles: employeeVehicles,
    setNewVehicles: setEmployeeVehicles,
    survey: surveyThankYouStep,
  });

  return [
    {
      label: "Personal Information",
      title: `Let's start with ${
        surveyThankYouStep ? `your` : `some`
      } basic personal information`,
      rows: personalInfoForm,
      formAdvanceErrorMessage: employeeEmailWarningText,
    },
    ...newVehiclesForm,
    {
      form: (
        <BuildWorkspacesForm
          onSubmit={newEmployeeSubmit}
          surveyThankYouStep={surveyThankYouStep}
        />
      ),
    },
  ];
};
