import { useState } from "react";

import { Grid } from "@mui/material";
import {
  faCalendarCheck,
  faWeightHanging,
  faLocationDot,
  faLocationArrow,
  faTruck,
  faRulerHorizontal,
} from "@fortawesome/free-solid-svg-icons";

import { Autocomplete } from "@aclymatepackages/atoms";

import useCsvUploader from "./csvUploader";
import { uploadFileToStorage } from "../../helpers/firebase";
import { getAccountCollectionAndId } from "../../helpers/otherHelpers";
import DatePicker from "../atoms/mui/DatePicker";

const fieldOptions = [
  { label: "Date", value: "date" },
  { label: "Weight", value: "weight" },
  { label: "Weight Units", value: "weightUnits" },
  { label: "Sender Postal Code", value: "senderPostalCode" },
  { label: "Recipient Postal Code", value: "recipientPostalCode" },
  { label: "Service Type", value: "serviceType" },
];

const requiredFields = [
  {
    label: "Date",
    value: "date",
    description: "(Optional) When was the item shipped?",
    icon: faCalendarCheck,
    required: false,
  },
  {
    label: "Weight",
    value: "weight",
    description: "How heavy was the shipment?",
    icon: faWeightHanging,
    required: true,
  },
  {
    label: "Sender Postal Code",
    value: "senderPostalCode",
    description: "Where did the shipment originate from?",
    icon: faLocationDot,
    required: true,
  },
  {
    label: "Recipient Postal Code",
    value: "recipientPostalCode",
    description: "Where was the shipment delivered to?",
    icon: faLocationArrow,
    required: true,
  },
  {
    label: "Service Type",
    value: "serviceType",
    description: "What service type was used?",
    icon: faTruck,
    required: true,
  },
  {
    label: "Weight Units",
    value: "weightUnits",
    description: "(Optional) Units for the weight (lbs, kgs, metric tons)",
    icon: faRulerHorizontal,
    required: false,
  },
];

const knownFields = [
  { header: "date", field: "date" },
  { header: "shipment date", field: "date" },
  { header: "weight", field: "weight" },
  { header: "mass", field: "weight" },
  { header: "weight units", field: "weightUnits" },
  { header: "unit of measure", field: "weightUnits" },
  { header: "sender postal code", field: "senderPostalCode" },
  { header: "origin postal code", field: "senderPostalCode" },
  { header: "from postal code", field: "senderPostalCode" },
  { header: "recipient postal code", field: "recipientPostalCode" },
  { header: "destination postal code", field: "recipientPostalCode" },
  { header: "to postal code", field: "recipientPostalCode" },
  { header: "service type", field: "serviceType" },
  { header: "shipping method", field: "serviceType" },
  { header: "carrier", field: "serviceType" },
];

const topShippingProviders = [
  { name: "USPS", value: "usps" },
  { name: "FedEx", value: "fedEx" },
  { name: "UPS", value: "ups" },
  { name: "DHL", value: "dhl" },
  { name: "SF Express", value: "sfExpress" },
  { name: "Royal Mail", value: "royalMail" },
  { name: "PostNL", value: "postNl" },
  { name: "Blue Dart", value: "blueDart" },
  { name: "Aramex", value: "aramex" },
  { name: "DB Schenker", value: "dbSchenker" },
  { name: "Asendia", value: "asendia" },
];

const fieldValidator = (headers) => {
  const requiredFieldValues = requiredFields
    .filter(({ required }) => required)
    .map((field) => field.value);
  const headersFieldValues = headers.map((item) => item.field);

  const fieldsBoolean = requiredFieldValues.every((requiredField) =>
    headersFieldValues.includes(requiredField)
  );

  return {
    success: fieldsBoolean,
    message:
      !fieldsBoolean &&
      `The fields Weight, Sender Postal Code, Recipient Postal Code, and Service Type are required for this CSV. Please ensure all required columns are matched.`,
  };
};

const useShippingTransactionsCsvUploader = ({ open, setOpen }) => {
  const [fileInfo, setFileInfo] = useState({});
  const [matchedHeaders, setMatchedHeaders] = useState([]);
  const [selectedShippingProvider, setSelectedShippingProvider] =
    useState(null);
  const [fallbackShippingDate, setFallbackShippingDate] = useState(null);

  const uploadShippingCsvFile = async ({
    fileInfo,
    matchedHeaders,
    originalFileObj,
  }) => {
    const { name: fileName } = fileInfo;
    const { name: shippingProvider } = selectedShippingProvider;
    const matchedHeadersMetadataObj = matchedHeaders.reduce(
      (currentObj, { field, name }) => ({
        ...currentObj,
        [field]: name,
      }),
      {}
    );
    const fallbackShippingDateObj = fallbackShippingDate
      ? {
          fallbackShippingDate: new Date(fallbackShippingDate).toISOString(),
        }
      : {};

    const { id } = getAccountCollectionAndId();
    const storagePath = `${id}/csv/shipping-transactions/${fileName
      .split(" ")
      .join("_")}`;

    return await uploadFileToStorage(storagePath, originalFileObj, {
      customMetadata: {
        ...matchedHeadersMetadataObj,
        ...fallbackShippingDateObj,
        shippingProvider,
      },
    });
  };

  const csvUploaderSteps = useCsvUploader({
    knownFields,
    fieldOptions,
    open,
    setOpen,
    fileInfo,
    setFileInfo,
    matchedHeaders,
    setMatchedHeaders,
    fieldValidator,
    docType: "shipping-transactions",
    firstStepText:
      "Upload a CSV of your shipping transactions, and we'll automatically process them.",
    requiredFields: requiredFields,
    processData: uploadShippingCsvFile,
  });

  return [
    {
      label: "Select Shipping Provider",
      input: (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Autocomplete
              value={selectedShippingProvider}
              setValue={(selectedProvider) =>
                typeof selectedProvider === "string"
                  ? setSelectedShippingProvider({ name: selectedProvider })
                  : setSelectedShippingProvider(selectedProvider)
              }
              availableOptions={topShippingProviders}
              label="Enter your shipping provider"
              size="small"
              freeSolo
              autoSelect
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="Select a fallback date"
              date={fallbackShippingDate}
              maxDate={new Date()}
              editDate={(newDate) => setFallbackShippingDate(newDate)}
              helperText="Please select a fallback date to use for any shipments that don't have a date."
              darkTheme
            />
          </Grid>
        </Grid>
      ),
      buttonDisabled: !selectedShippingProvider || !fallbackShippingDate,
    },
    ...csvUploaderSteps,
  ];
};

export default useShippingTransactionsCsvUploader;
