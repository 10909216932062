import dayjs from "dayjs";
import React from "react";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { StyledEngineProvider, TextField } from "@mui/material";
import { mergeDarkTheme } from "@aclymatepackages/themes";

const DatePicker = ({
  date,
  editDate,
  showError,
  variant,
  disableFuture,
  darkTheme = false,
  views = ["year", "month", "day"],
  format = "MM/DD/YYYY",
  inputVariant,
  minDate,
  maxDate,
  helperText,
  ...otherProps
}) => {
  const { palette } = useTheme();

  const convertToDayJsObj = (value) => {
    if (value) {
      return dayjs(value);
    }

    return null;
  };

  const datePickerDayStyles = darkTheme
    ? {}
    : {
        "&.MuiPickersDay-root.Mui-selected": {
          color: "#fff",
          backgroundColor: palette.primary.main,
          borderColor: palette.primary.main,
          ":hover": {
            color: "#fff",
            backgroundColor: palette.primary.light,
            borderColor: palette.primary.light,
          },
        },
      };

  const desktopPaperStyles = darkTheme
    ? {}
    : {
        ".MuiPickersYear-yearButton.Mui-selected": {
          color: "#fff",
        },
        ".MuiPickersMonth-monthButton.Mui-selected": {
          color: "#fff",
        },
      };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={darkTheme ? mergeDarkTheme : {}}>
        <MuiDatePicker
          autoOk
          value={convertToDayJsObj(date)}
          onChange={(date) => editDate(date)}
          inputVariant={inputVariant || "outlined"}
          variant={variant || "inline"}
          format={format}
          error={date && showError && !date}
          disableFuture={disableFuture || false}
          views={views}
          minDate={convertToDayJsObj(minDate)}
          maxDate={convertToDayJsObj(maxDate)}
          slotProps={{
            textField: { fullWidth: true, helperText },
            popper: { sx: { zIndex: 2300 } },
            day: {
              sx: {
                ...datePickerDayStyles,
              },
            },
            desktopPaper: {
              sx: {
                ...desktopPaperStyles,
              },
            },
          }}
          renderInput={(props) => <TextField {...otherProps} {...props} />}
          {...otherProps}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default DatePicker;
