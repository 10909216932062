import React from "react";

import {
  Box,
  Grid,
  Typography,
  Chip,
  Tooltip,
  Avatar,
  useTheme,
} from "@mui/material";

import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LanguageIcon from "@mui/icons-material/Language";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldCheck } from "@fortawesome/pro-duotone-svg-icons";

import { hexToRgba } from "@aclymatepackages/converters";
import { formatDollars } from "@aclymatepackages/formatters";
import { truncateText } from "@aclymatepackages/other-helpers";

import { projectIcons } from "../../../helpers/components/display-lists/projects";
import { convertCostToPricePerTon } from "../../../helpers/components/projects";

const VerifiedOffsetBadge = ({ style, size = "2x" }) => {
  const { palette } = useTheme();

  return (
    <div style={style}>
      <Tooltip title="Verification documents are available for this project">
        <span>
          <FontAwesomeIcon
            icon={faShieldCheck}
            size={size}
            style={{
              "--fa-primary-color": palette.secondary.light,
              "--fa-secondary-color": "black",
              "--fa-secondary-opacity": 1,
            }}
          />
        </span>
      </Tooltip>
    </div>
  );
};

const ProjectSummaryDisplay = ({ project, noIcons }) => {
  const theme = useTheme();

  const {
    name,
    images = [],
    registryUrl,
    categories,
    preferencesScores = [],
    location,
    totalThousandLbsCost,
  } = project;
  const [image] = images;
  const filteredPreferencesScores = preferencesScores.filter((preference) => {
    const [score] = Object.values(preference);

    return score > 1;
  });

  const costDisplayChip = totalThousandLbsCost
    ? [
        {
          name: "Cost",
          label: formatDollars(convertCostToPricePerTon(totalThousandLbsCost)),
          icon: <MonetizationOnIcon />,
          tooltip: "Cost per Ton",
        },
      ]
    : [];
  const locationDisplayChip = location
    ? [
        {
          name: "Location",
          icon: <LanguageIcon />,
          label: location,
          tooltip: "Project Location",
        },
      ]
    : [];

  const displayChips = [...costDisplayChip, ...locationDisplayChip];

  const findPreferenceIcon = (preference) => {
    const [preferenceName] = Object.keys(preference);

    const [{ icon }] = projectIcons.filter(({ tags }) =>
      tags.includes(preferenceName)
    );

    const generatePreferenceName = () => {
      if (preferenceName === "unSdgs") {
        return "UN SDGs";
      }

      if (preferenceName === "verificationStatus") {
        return "verification status";
      }

      return preferenceName;
    };

    return {
      title: `Your ${generatePreferenceName()} preference matched this project`,
      icon,
    };
  };

  const filteredPreferencesWithIcons = filteredPreferencesScores.map(
    (preference) => findPreferenceIcon(preference)
  );

  return (
    <>
      <Box
        style={{
          paddingTop: "56.25%",
          position: "relative",
          backgroundImage: `url('${image}')`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        {!noIcons && (
          <Grid
            container
            item
            spacing={1}
            style={{ position: "absolute", top: "10px", left: "10px" }}
          >
            {categories.map(({ icon, name }, idx) => (
              <Grid item key={`category-avatar-${idx}`}>
                <Tooltip title={name}>
                  <Avatar
                    style={{
                      height: `${theme.spacing(6)}px`,
                      width: `${theme.spacing(6)}px`,
                      backgroundColor: theme.palette.secondary.main,
                    }}
                  >
                    <FontAwesomeIcon icon={icon} size="lg" />
                  </Avatar>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        )}
        {!noIcons && registryUrl && (
          <VerifiedOffsetBadge
            style={{ position: "absolute", right: "10px", top: "10px" }}
            size="3x"
          />
        )}
        {!!filteredPreferencesWithIcons?.length && (
          <Box
            position="absolute"
            style={{
              bottom: "55px",
              right: "10px",
              background: "black",
              opacity: 0.7,
              borderRadius: "5px",
            }}
            p={1}
          >
            <Grid container alignItems="center" spacing={2}>
              {filteredPreferencesWithIcons.map(({ title, icon }, idx) => (
                <Grid key={`icon-${idx}`} item style={{ color: "white" }}>
                  <Tooltip title={title}>{icon}</Tooltip>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        <Box
          position="absolute"
          style={{
            left: 0,
            right: 0,
            bottom: 0,
            background: `linear-gradient(135deg, ${hexToRgba(
              theme.palette.primary.main,
              0.95
            )} 0%, ${hexToRgba(theme.palette.secondary.main, 0.5)} 100%)`,
            color: "white",
          }}
          p={1}
        >
          <Typography variant="h4" color="inherit" noWrap>
            {name}
          </Typography>
        </Box>
      </Box>
      {!!displayChips.length && (
        <Box p={2}>
          <Grid container spacing={1}>
            {displayChips.map(({ icon, label, tooltip }, idx) => (
              <Grid item key={`details-chip-${idx}`}>
                <Tooltip title={tooltip}>
                  <Chip icon={icon} label={truncateText(label, 40)} />
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};
export default ProjectSummaryDisplay;
