import React, { useState, useEffect, useRef, useMemo } from "react";
import CountUp from "react-countup";
import Typewriter from "typewriter-effect";

import { Box, Grid, Typography, Paper, Fade } from "@mui/material";

import { AnimatedLogo } from "@aclymatepackages/atoms";
import { useLayoutHelpers } from "@aclymatepackages/themes";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import {
  tonsToLbs,
  convertFootprintToDriving,
  convertFootprintToIce,
  convertFootprintToTrees,
} from "@aclymatepackages/converters";
import { letterSBoolean, formatDecimal } from "@aclymatepackages/formatters";

const OnboardingFootprintLayout = ({ children }) => {
  return (
    <Box
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      {children}
    </Box>
  );
};

const VideoFootprintDisplay = ({ monthlyFootprintTons, onAdvanceSlide }) => {
  const { isMobile } = useLayoutHelpers();

  const [showCaption, setShowCaption] = useState(false);
  useEffect(() => {
    const advanceSlide = setTimeout(onAdvanceSlide, 8500);
    return () => clearTimeout(advanceSlide);
  }, [onAdvanceSlide]);

  return (
    <OnboardingFootprintLayout>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <CountUp
            delay={0}
            start={0}
            startOnMount
            end={Math.round(tonsToLbs(monthlyFootprintTons))}
            separator=","
            decimal="."
            suffix=" lbs."
            duration={1.5}
            onEnd={() => setShowCaption(true)}
          >
            {({ countUpRef }) => (
              <Typography
                ref={countUpRef}
                variant={isMobile ? "h4" : "h1"}
                align="center"
                color="textPrimary"
              />
            )}
          </CountUp>
        </Grid>
        {showCaption && (
          <Grid item>
            <Paper sx={{ padding: 1 }}>
              <Typography
                variant={isMobile ? "h6" : "h3"}
                align="center"
                color="textSecondary"
              >
                <Typewriter
                  options={{
                    strings: [
                      "This is our estimate of your monthly carbon emissions.",
                    ],
                    autoStart: true,
                    delay: 50,
                    deleteSpeed: 100000,
                  }}
                />
              </Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
    </OnboardingFootprintLayout>
  );
};

const FootprintVideoIntermediateStep = ({ label, onAdvanceSlide }) => {
  const { isMobile } = useLayoutHelpers();

  useEffect(() => {
    const advanceSlide = setTimeout(onAdvanceSlide, 8000);
    return () => clearTimeout(advanceSlide);
  }, [onAdvanceSlide]);

  return (
    <OnboardingFootprintLayout video="logging">
      <Paper sx={{ padding: 1 }}>
        <Typography
          variant={isMobile ? "h5" : "h3"}
          align="center"
          color="textSecondary"
        >
          <Typewriter
            options={{
              strings: [label],
              autoStart: true,
              delay: 50,
              deleteSpeed: 100000,
            }}
          />
        </Typography>
      </Paper>
    </OnboardingFootprintLayout>
  );
};

const FootprintVideoFinal = ({ actionButton, onVideoEnd }) => {
  const { isMobile } = useLayoutHelpers();

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const onShowButton = setTimeout(() => {
      setShowButton(true);
      if (onVideoEnd) {
        onVideoEnd();
      }
    }, 9000);

    return () => clearTimeout(onShowButton);
  }, [onVideoEnd]);

  return (
    <OnboardingFootprintLayout>
      <Grid container direction="column" spacing={2} alignItems="center">
        <Grid item>
          <Paper sx={{ padding: 1 }}>
            <Typography
              variant={isMobile ? "h6" : "h3"}
              align="center"
              color="textSecondary"
            >
              <Typewriter
                onInit={(typewriter) =>
                  typewriter
                    .changeDelay(50)
                    .typeString("But all of that ends now.")
                    .pauseFor(1500)
                    .typeString(" Welcome to your climate journey.")
                    .start()
                }
              />
            </Typography>
          </Paper>
        </Grid>
        <Fade in={showButton}>
          <Grid item>{actionButton}</Grid>
        </Fade>
      </Grid>
    </OnboardingFootprintLayout>
  );
};

const FootprintVideo = ({
  monthlyFootprintTons,
  footprintLoading,
  actionButton,
  onVideoEnd,
}) => {
  const { isMobile } = useLayoutHelpers();

  const [videoStep, setVideoStep] = useState(0);
  const [loadedVideos, setLoadedVideos] = useState({
    pollution: false,
    driving: false,
    logging: false,
    glacier: false,
    monthly: false,
    mountains: false,
  });
  const [showVideos, setShowVideos] = useState(false);

  const onVideoLoaded = (video) => editObjectData(setLoadedVideos, video, true);

  const pollutionVideoRef = useRef();
  const drivingVideoRef = useRef();
  const loggingVideoRef = useRef();
  const glacierVideoRef = useRef();
  const monthlyVideoRef = useRef();
  const mountainsVideoRef = useRef();

  const videoRefsArray = useMemo(
    () => [
      pollutionVideoRef,
      drivingVideoRef,
      loggingVideoRef,
      glacierVideoRef,
      monthlyVideoRef,
      mountainsVideoRef,
    ],
    []
  );

  useEffect(() => {
    const areAllVideosLoaded = Object.values(loadedVideos).reduce(
      (acc, isLoaded) => isLoaded && acc,
      true
    );
    if (areAllVideosLoaded && !footprintLoading) {
      setShowVideos(true);
    }
  }, [loadedVideos, footprintLoading]);

  useEffect(() => {
    if (
      pollutionVideoRef.current &&
      loggingVideoRef.current &&
      glacierVideoRef.current &&
      drivingVideoRef.current &&
      monthlyVideoRef.current &&
      mountainsVideoRef.current &&
      showVideos
    ) {
      videoRefsArray[videoStep]?.current?.play();
    }
    // eslint-disable-next-line
  }, [videoStep, showVideos]);

  useEffect(() => {
    videoRefsArray.forEach((ref) => {
      if (ref.current) {
        ref.current.playsInline = true;
        ref.current.setAttribute("playsinline", "true");
      }
    });
  }, [videoRefsArray]);

  const onAdvanceSlide = () => setVideoStep((currentStep) => currentStep + 1);

  const intermediateStepLabels = [
    `That's as much carbon as driving ${convertFootprintToDriving(
      monthlyFootprintTons
    )} miles.`,
    `Or cutting down more than ${convertFootprintToTrees(
      monthlyFootprintTons
    )} 50-year-old tree${letterSBoolean(
      convertFootprintToTrees(monthlyFootprintTons)
    )}.`,
    `It's enough to melt more than ${formatDecimal(
      convertFootprintToIce(monthlyFootprintTons)
    )} square feet of Arctic sea ice that will never come back.`,
    "And you emit this much carbon every single month.",
  ];

  const onboardingFootprintSteps = [
    <VideoFootprintDisplay
      monthlyFootprintTons={monthlyFootprintTons}
      onAdvanceSlide={onAdvanceSlide}
    />,
    ...intermediateStepLabels.map((label, idx) => (
      <FootprintVideoIntermediateStep
        label={label}
        onAdvanceSlide={onAdvanceSlide}
        key={`video-intermediate-step-${idx}`}
      />
    )),
    <FootprintVideoFinal actionButton={actionButton} onVideoEnd={onVideoEnd} />,
  ];

  const buildVideoProps = (idx) => ({
    autoPlay: !idx,
    style: {
      position: "absolute",
      top: 0,
      objectFit: "cover",
      objectPosition: "center center",
      opacity: showVideos && idx === videoStep ? 1 : 0,
      transition: "opacity 1s",
      marginBottom: "-10px",
      width: "calc(100% + 1px)",
    },
  });

  const buildSrcProps = (video) => ({
    src: `/videos/${video}.mp4`,
    type: "video/mp4",
  });

  const videos = [
    "pollution",
    "driving",
    "logging",
    "glacier",
    "monthly",
    "mountains",
  ];

  return (
    <Box
      sx={{ height: "100vh" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Paper style={{ overflow: "hidden", width: isMobile ? "95vw" : "80vw" }}>
        <div
          style={{
            position: "relative",
            paddingTop: "56.25%",
          }}
        >
          {!showVideos && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                position: "absolute",
                inset: 0,
              }}
            >
              <AnimatedLogo />
            </Box>
          )}
          {videos.map((video, idx) => (
            <video
              key={`footprint-video-${idx}`}
              muted
              playsInline
              {...{ "webkit-playsinline": "true" }}
              autoPlay={!idx}
              {...buildVideoProps(idx)}
              ref={videoRefsArray[idx]}
              onLoadedData={() => onVideoLoaded(video)}
              {...{ "pointer-events": "none" }}
            >
              <source {...buildSrcProps(video)} />
            </video>
          ))}
          {onboardingFootprintSteps[videoStep]}
        </div>
      </Paper>
    </Box>
  );
};
export default FootprintVideo;
