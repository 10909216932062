import React from "react";

import {
  Box,
  Grid,
  Typography,
  IconButton,
  Collapse,
  useTheme,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { hexToRgba } from "@aclymatepackages/converters";

const EmissionsDetailsAccordionBlock = ({
  type = "primary",
  title,
  subtitle,
  details,
  expanded,
  onChange,
  blockAction,
  avatar,
}) => {
  const { palette } = useTheme();

  return (
    <Box
      style={{
        backgroundColor: hexToRgba(palette[type]?.main, 0.1),
      }}
    >
      <Box display="flex" alignItems="center" flexWrap="nowrap">
        <Box flexGrow={1} p={2}>
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            {avatar && <Grid item>{avatar}</Grid>}
            <Grid item>
              <Typography variant="h4">{title}</Typography>
              {subtitle && (
                <Typography variant="subtitle2" color="textSecondary">
                  {subtitle}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box flexGrow={0}>
          <Grid container alignItems="center" wrap="nowrap">
            {expanded && blockAction && <Grid item>{blockAction}</Grid>}
            <Grid item>
              <IconButton onClick={onChange} size="large">
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Collapse in={expanded}>{details}</Collapse>
    </Box>
  );
};
export default EmissionsDetailsAccordionBlock;
