import { useState, useEffect } from "react";

import { useCookies } from "react-cookie";

import dayjs from "dayjs";
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import queryString from "query-string";

import { fetchOurApi } from "../utils/apiCalls";

import {
  analyticsTrack,
  analyticsIdentify,
  triggerGoogleEvent,
} from "../analytics";
import { referralPartners } from "../../components/account-access/Partners";

import { useAuth, signOut } from "../../helpers/firebase";

export const excludedPersonalEmailProviders = [
  "gmail.",
  "yahoo.",
  "hotmail.",
  "aol.",
  "msn.",
  "mac.",
  "comcast.",
  "outlook.",
  "googlemail.",
];

export const useLogout = () => {
  const [user] = useAuth();
  const { email } = user || {};

  return () =>
    signOut().then(() => {
      analyticsTrack("User Sign Out", { email });
      return window.sessionStorage.clear();
    });
};

export const isEmailPersonal = (email) =>
  excludedPersonalEmailProviders.some((excludedDomain) =>
    email.includes(excludedDomain)
  );

export const createUpdateMyAclymateAccount = async (email) =>
  await fetchOurApi({
    path: "/onboarding/create-update-my-aclymate-account",
    method: "POST",
    data: { email },
  });

export const useExistingIndividualData = () => {
  const [individualDataLoading, setIndividualDataLoading] = useState(true);
  const [individualData, setIndividualData] = useState({});

  const newAccountQueries = queryString.parse(window.location.search);
  const { individualId } = newAccountQueries || {};

  useEffect(() => {
    if (individualId && individualDataLoading) {
      fetchOurApi({
        method: "GET",
        path: `/individuals/${individualId}`,
        callback: (res) => {
          setIndividualDataLoading(false);
          setIndividualData(res);
        },
      });
    }
  }, [individualId, individualDataLoading]);

  return [individualData, individualId ? individualDataLoading : false];
};

export const useReferralCookie = () => {
  const REFERRAL_COOKIE_NAME = "aclymate-referral-partner";
  const [cookies, setCookie] = useCookies([REFERRAL_COOKIE_NAME]);

  const referralCookie = cookies[REFERRAL_COOKIE_NAME];
  const referralPartner =
    referralPartners.find(({ slug }) => slug === referralCookie)?.name || "";

  const setReferralCookie = (slug) => {
    if (referralCookie) {
      return;
    }

    return setCookie(REFERRAL_COOKIE_NAME, slug, {
      path: "/",
      expires: dayjs().add(1, "year").toDate(),
    });
  };

  return {
    setReferralCookie,
    referralPartner,
  };
};

export const onSignInWithEmailAndPassword = async ({
  email,
  password,
  callback,
  setUserLoading,
  setErrorMsg,
}) => {
  const auth = getAuth();

  await setPersistence(auth, browserLocalPersistence);

  return signInWithEmailAndPassword(auth, email, password)
    .then(({ user }) => {
      if (callback) {
        return callback(user);
      }
    })
    .catch((e) => {
      console.log("firebase error: ", e);
      if (setUserLoading) {
        setUserLoading(false);
      }
      if (e.code === "auth/user-not-found") {
        return setErrorMsg("User not found. Please use the correct email.");
      }
      if (e.code === "auth/wrong-password") {
        return setErrorMsg(
          "Incorrect password. Maybe try signing in with Google or Microsoft instead."
        );
      }

      return setErrorMsg("An error occured logging into your account.");
    });
};

export const onLoginSetSessionAndTracking = (user, accountId) => {
  const { email } = user || {};

  window.sessionStorage.setItem("accountId", accountId);
  analyticsIdentify(user, accountId);
  analyticsTrack("User Sign In", {
    accountId,
    email,
  });
  return triggerGoogleEvent("login");
};

export const getUserAccountData = async ({ email, uid }) =>
  await fetchOurApi({
    path: "/onboarding/get-user-account-data",
    method: "POST",
    data: {
      email,
      uid,
    },
    callback: (res) => res,
  });

export const handleLoggedInUser = async ({
  user,
  setRedirect,
  setLoginLoading,
  setDialogOpen,
  setChooseAccountFormOpen,
  setAccountAccessError,
  existingIndividualEmail,
}) => {
  const { email, uid } = user;

  if (setLoginLoading) {
    setLoginLoading(true);
  }

  if (existingIndividualEmail) {
    await createUpdateMyAclymateAccount(email);
  }

  const { companyId, individualId } = await user
    .getIdTokenResult()
    .then(({ claims: { company, individual } }) => ({
      companyId: company,
      individualId: individual,
    }));

  const handleTokenIdDoesntExist = async () => {
    if (setLoginLoading) {
      setLoginLoading(false);
    }
    await signOut();
    return setAccountAccessError(
      "Account not found. Please try creating an account instead"
    );
  };

  if (!companyId && !individualId) {
    return await handleTokenIdDoesntExist();
  }

  const { companyData, individualData } = await getUserAccountData({
    email,
    uid,
  });
  if (!!companyData && !!individualData) {
    return setChooseAccountFormOpen({
      company: {
        accountId: companyData.id,
        accountType: "company",
      },
      individual: {
        accountId: individualData.id,
        accountType: "individual",
      },
    });
  }

  const fullAccountId = individualId
    ? `individuals-${individualId}`
    : `v2-companies-${companyId}`;

  onLoginSetSessionAndTracking(user, fullAccountId);

  if (setDialogOpen) {
    return setDialogOpen(false);
  }

  if (individualId) {
    document.cookie = `aclymate-in-slug=${individualId}; path=/`;
  }

  const redirectUrl = individualId
    ? "myaclymate"
    : "platform/company/dashboard";

  return setRedirect(redirectUrl);
};

export const sendExistingCompanyAdminEmail = async (companyData, userData) => {
  const { users: companyAdmins, id: companyId } = companyData;
  const { displayName, uid, email } = userData;

  const activatedAdmins = companyAdmins.filter(({ uid }) => uid);

  return await Promise.all(
    activatedAdmins.map(
      async ({ email: adminEmail }) =>
        await fetchOurApi({
          path: "/sendgrid/send-auth-email",
          method: "POST",
          data: { adminEmail, companyId, displayName, uid, userEmail: email },
        })
    )
  );
};
