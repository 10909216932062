import React, { useState, useEffect, useContext } from "react";

import { Box, Grid, Typography, Button } from "@mui/material";

import { AnimatedLogo } from "@aclymatepackages/atoms";

import PurchaseConfirmation from "./PurchaseConfirmation";
import PurchaseVolumeSelectionInput from "./PurchaseVolumeSelectionInput";
import PurchaseFlowPaymentInfoBox from "./PurchaseFlowPaymentInfoBox";

import PurchaseDialogLayout from "../../layouts/PurchaseDialogLayout";
import Link from "../../atoms/mui/Link";

import { googlePurchaseTracking } from "../../../helpers/components/purchase";
import { StripeCustomerContext } from "../../../helpers/contexts/stripeCustomer";
import { PurchasingContextProvider } from "../../../helpers/contexts/purchasing";

const PurchaseSuccessBox = ({
  project,
  purchaseDollars,
  lbsToPurchase,
  transactionId,
  isCNaughtPurchase,
}) => {
  const { images = [] } = project;
  const { paymentMethod } = useContext(StripeCustomerContext);
  const { us_bank_account } = paymentMethod || {};

  useEffect(() => {
    if (!isCNaughtPurchase) {
      googlePurchaseTracking(
        "purchase",
        { project, purchaseDollars, lbsToPurchase },
        {
          transaction_id: transactionId,
          value: purchaseDollars,
          currency: "dollars",
        }
      );
    }
  }, [
    project,
    purchaseDollars,
    lbsToPurchase,
    transactionId,
    isCNaughtPurchase,
  ]);

  const bankPurchaseText =
    "When the bank payment is successful, this offset will show up in your account and we will send you an email receipt.";

  return (
    <PurchaseDialogLayout
      title="Congratulations! You've helped the planet."
      subtitle={`You've taken a meaningful step towards addressing your Climate Footprint and becoming net zero. ${
        us_bank_account ? bankPurchaseText : ""
      }`}
    >
      <Box p={2} position="relative">
        <Grid container spacing={2} alignItems="stretch">
          <Grid item sm={4}>
            <Box
              style={{
                backgroundImage: `url(${
                  isCNaughtPurchase
                    ? "/images/partner-logos/cnaught.svg"
                    : images[0]
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                paddingTop: "100%",
              }}
            />
          </Grid>
          <Grid item sm={8}>
            <Typography variant="h6" color="textPrimary">
              Buying offsets allows you to earn higher levels of Aclymate
              certifications
            </Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              We recommend offsetting all of your emissions every month
            </Typography>
            <Grid container justifyContent="center">
              <Grid item>
                <Link href="/platform/company/badges">
                  <Button variant="contained" color="secondary">
                    Learn More
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </PurchaseDialogLayout>
  );
};

const OffsetPurchaseFlowBlocks = ({
  project = {},
  projectLoading,
  isCNaughtPurchase,
}) => {
  const { paymentMethod, isPaymentMethodLoading } = useContext(
    StripeCustomerContext
  );
  const { paymentMethodId } = paymentMethod || {};

  const [purchaseStep, setPurchaseStep] = useState("");
  const [transactionId, setTransactionId] = useState("");
  // const [inputData, setInputData] = useState({});
  // const { purchaseDollars, lbsToPurchase } = inputData;

  if (isPaymentMethodLoading || projectLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <AnimatedLogo />
      </Box>
    );
  }

  if (purchaseStep === "success") {
    return (
      <PurchaseSuccessBox
        project={project}
        // purchaseDollars={purchaseDollars}
        // lbsToPurchase={lbsToPurchase}
        transactionId={transactionId}
        isCNaughtPurchase={isCNaughtPurchase}
      />
    );
  }

  const isReadyToPurchase = purchaseStep === "ready-to-purchase";
  if (isReadyToPurchase && !paymentMethodId) {
    return <PurchaseFlowPaymentInfoBox />;
  }

  if (isReadyToPurchase) {
    return (
      <PurchaseConfirmation
        setPurchaseStep={setPurchaseStep}
        project={project}
        setTransactionId={setTransactionId}
        isCNaughtPurchase={isCNaughtPurchase}
      />
    );
  }

  return (
    <PurchaseVolumeSelectionInput
      setPurchaseStep={setPurchaseStep}
      project={project}
      isCNaughtPurchase={isCNaughtPurchase}
    />
  );
};

const OffsetPurchaseFlow = (props) => (
  <PurchasingContextProvider>
    <OffsetPurchaseFlowBlocks {...props} />
  </PurchasingContextProvider>
);

export default OffsetPurchaseFlow;
