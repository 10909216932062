import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  CircularProgress,
  Card,
  CardContent,
  Typography,
  IconButton,
  useTheme,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { hexToRgba } from "@aclymatepackages/converters";
import { truncateText } from "@aclymatepackages/other-helpers";

import Link from "../atoms/mui/Link";
import GrayPaper from "../atoms/GrayPaper";
import MyAclymateReportPaper from "../atoms/myAclymate/MyAcymateReportPaper";

import { fetchOurApi } from "../../helpers/utils/apiCalls";
const BlogCard = ({ fieldData }) => {
  const { name, slug } = fieldData;

  const { url: imageUrl } = fieldData["main-image"] || {};
  const description = fieldData["seo-meta-description"];

  return (
    <Card style={{ height: "100%", width: "100%" }}>
      <Box p={1}>
        <Box
          style={{
            backgroundImage: `url("${imageUrl}")`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            paddingTop: "56.25%",
          }}
        />
      </Box>
      <CardContent
        style={{
          height: "100%",
        }}
      >
        <Grid container spacing={1} wrap="nowrap" alignItems="center">
          <Grid item>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              {name}
            </Typography>
          </Grid>
          <Grid item>
            <Link href={`https://aclymate.com/blog/myaclymate/${slug}`}>
              <IconButton size="small">
                <OpenInNewIcon />
              </IconButton>
            </Link>
          </Grid>
        </Grid>
        <Typography variant="body2" color="textSecondary">
          {truncateText(description, 100)}
        </Typography>
      </CardContent>
    </Card>
  );
};

const BlogBox = ({ collectionId }) => {
  const { palette } = useTheme();

  const [blogData, setBlogData] = useState([]);
  const [blogDataLoading, setBlogDataLoading] = useState(true);
  const [selectedBlogIdx, setSelectedBlogIdx] = useState(0);

  useEffect(() => {
    if (!blogData?.length) {
      fetchOurApi({
        path: `/webflow/${collectionId}`,
        method: "GET",
        callback: ({ blogPosts }) => {
          const publishedPosts = blogPosts.filter(
            ({ lastPublished }) => lastPublished
          );
          setBlogData(publishedPosts);
          return setBlogDataLoading(false);
        },
      });
    }
  }, [blogData, collectionId]);

  const onLeftClick = () =>
    setSelectedBlogIdx((currentIdx) => {
      if (!currentIdx) {
        return blogData.length - 1;
      }
      return currentIdx - 1;
    });

  const onRightClick = () =>
    setSelectedBlogIdx((currentIdx) => {
      if (currentIdx === blogData.length - 1) {
        return 0;
      }

      return currentIdx + 1;
    });

  return (
    <MyAclymateReportPaper
      title="Reduce Your Emissions"
      subtitle="Reduce your carbon footprint by taking action and making lifestyle changes: big or small"
      style={{ height: "100%" }}
    >
      {blogDataLoading || !blogData ? (
        <Grid container item justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <GrayPaper>
          <Box
            display="flex"
            flexDirection="column"
            rowGap={2}
            alignItems="center"
          >
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item>
                <IconButton size="small" onClick={onLeftClick}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Grid>
              <Grid item sm={8} xs={10}>
                <BlogCard {...blogData[selectedBlogIdx]} />
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={onRightClick}>
                  <KeyboardArrowRightIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              {[...new Array(blogData.length)].map((_, idx) => (
                <Grid item key={`carousel-dot-${idx}`}>
                  <Box
                    sx={{
                      height: idx === selectedBlogIdx ? 15 : 10,
                      width: idx === selectedBlogIdx ? 15 : 10,
                      borderRadius: "100%",
                      backgroundColor: hexToRgba(
                        palette.backgroundGray.dark,
                        idx === selectedBlogIdx ? 1 : 0.5
                      ),
                    }}
                  ></Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </GrayPaper>
      )}
    </MyAclymateReportPaper>
  );
};
export default BlogBox;
