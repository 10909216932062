import React from "react";

import { Avatar, Tooltip, useTheme } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

import { scopeThreeSpendBasedCategories } from "../../../helpers/components/vendors";

const ScopeThreeCategoryAvatar = ({ scopeThreeCategory, size }) => {
  const { palette } = useTheme();

  const sizePx = size === "small" ? "30px" : "40px";

  const {
    icon,
    subcategory = "spend-based",
    label,
  } = scopeThreeSpendBasedCategories.find(
    ({ value }) => value === scopeThreeCategory
  ) || {};

  return (
    <Tooltip title={label}>
      <Avatar
        style={{
          backgroundColor: palette[subcategory]
            ? palette[subcategory].main
            : palette.backgroundGray.main,
          height: sizePx,
          width: sizePx,
        }}
        size={size}
      >
        <FontAwesomeIcon icon={icon || faQuestion} style={{ color: "white" }} />
      </Avatar>
    </Tooltip>
  );
};
export default ScopeThreeCategoryAvatar;
