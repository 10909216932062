import React, { useState, useEffect } from "react";

import {
  Box,
  Container,
  Grid,
  TableRow,
  TableCell,
  Chip,
  Tooltip,
  Typography,
  IconButton,
  CircularProgress,
  Avatar,
  Rating,
  Menu,
  useTheme,
} from "@mui/material";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import { faIndustry } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import mainTheme from "@aclymatepackages/themes";
import {
  Checkbox,
  CategoriesAvatar,
  TextField,
  LoadingButton,
  DefaultPaper,
} from "@aclymatepackages/atoms";
import { formatDecimal, letterSBoolean } from "@aclymatepackages/formatters";
import findSubcategory from "@aclymatepackages/subcategories";
import { truncateText } from "@aclymatepackages/other-helpers";

import VendorDetailsSlider from "./VendorDetailsSlider";
import EditVendorDetails from "./EditVendorDetails";
import VendorsGraphs from "./VendorsGraph";
import SettingsObjectDetailsSlider from "../../SettingsObjectDetailsSlider";
import EditRowTransactions from "../../EditRowTransactions";

import ScopeThreeCategoryAvatar from "../../../../atoms/icons/ScopeThreeCategoryAvatar";
import CopyToClipboardIcon from "../../../../atoms/buttons/CopyToClipboardIcon";
import ButtonMenu from "../../../../atoms/buttons/ButtonMenu";
import ErrorBoundary from "../../../../atoms/ErrorBoundary";

import ReductionsBlogModule from "../../../../modules/ReductionsBlogModule";
import SortableTable, {
  makeColumnObj,
} from "../../../../modules/tables/SortableTable";
import DashboardViewLayout from "../../../../layouts/DashboardViewLayout";
import { MissingVendors } from "../../../../layouts/missingSettingsLayouts";
import VendorInputForm from "../../../../inputs/vendors/VendorInputForm";
import EmissionsCategorySelect from "../../../../inputs/vendors/EmissionsCategorySelect";
import ScopeThreeCategorySelect from "../../../../inputs/vendors/ScopeThreeCategorySelect";
import useStatusFilterChips from "../../../../hooks/statusFilterChips";

import {
  vendorStatuses,
  useOnVendorSave,
  updateVendorTransactions,
} from "../../../../../helpers/components/vendors";
import { useCachedFirebaseCrud } from "../../../../../helpers/firebase";
import useEmissionsContext from "../../../../../helpers/contexts/emissions";
import { getAccountCollectionAndId } from "../../../../../helpers/otherHelpers";
import useMeasurementSystem from "../../../../../helpers/hooks/measurementSystem";

const VendorCategoryChip = ({ emissionCategory, naicsTitle = "" }) => {
  const { name: categoryName, color } = findSubcategory(emissionCategory) || {};

  const TEXT_TRUNCATE_LENGTH = 20;

  return (
    <Tooltip
      disableHoverListener={naicsTitle?.length <= TEXT_TRUNCATE_LENGTH}
      title={naicsTitle}
    >
      <Chip
        style={{
          backgroundColor: emissionCategory
            ? color
            : mainTheme.palette.error.main,
          color: "white",
        }}
        avatar={
          emissionCategory && (
            <CategoriesAvatar subcategory={emissionCategory} size="small" />
          )
        }
        icon={!emissionCategory && <ErrorIcon style={{ color: "white" }} />}
        label={
          !emissionCategory
            ? "Uncategorized Vendor"
            : emissionCategory === "spend-based"
            ? truncateText(naicsTitle, TEXT_TRUNCATE_LENGTH)
            : categoryName
        }
      />
    </Tooltip>
  );
};

const ScopeThreeSubcategorySelectButton = ({ vendor }) => {
  const { id, scopeThreeCategory } = vendor;

  const [anchorEl, setAnchorEl] = useState(null);

  const { onVendorSave } = useOnVendorSave(() => setAnchorEl(null));

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          return setAnchorEl(e.currentTarget);
        }}
      >
        <ScopeThreeCategoryAvatar scopeThreeCategory={scopeThreeCategory} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={(e) => {
          e.stopPropagation();
          setAnchorEl(null);
        }}
      >
        <Box p={1} style={{ width: "450px" }}>
          <ScopeThreeCategorySelect
            scopeThreeCategory={scopeThreeCategory}
            setScopeThreeCategory={(scopeThreeCategory) =>
              onVendorSave({
                ...vendor,
                scopeThreeCategory,
                vendorIdAssociatedWithTransactions: id,
              })
            }
          />
        </Box>
      </Menu>
    </>
  );
};

const VendorsTableRow = ({
  vendor,
  setSelectedVendor,
  checkedVendors,
  setCheckedVendors,
}) => {
  const {
    id,
    status,
    surveyStatus,
    name: vendorName,
    tonsCo2e,
    avgTonsCo2ePerTransaction,
    transactionsCount,
    vendorScore,
  } = vendor;

  const {
    icon: statusIcon,
    color: statusColor,
    tooltip: statusTooltip,
  } = vendorStatuses[status] || {};

  const { convertCarbonUnits } = useMeasurementSystem();

  const onCheckVendor = (isChecked) => {
    if (isChecked) {
      return setCheckedVendors((currentlyCheckedVendors) => [
        ...currentlyCheckedVendors,
        vendor,
      ]);
    }

    return setCheckedVendors((currentlyCheckedVendors) =>
      currentlyCheckedVendors.filter((vendor) => vendor.id !== id)
    );
  };

  const tableCells = [
    <Checkbox
      disabled={["has-account", "survey-completed"].includes(surveyStatus)}
      value={!!checkedVendors.find((vendor) => vendor.id === id)}
      editValue={onCheckVendor}
    />,
    <Tooltip title={statusTooltip}>
      <span>
        <FontAwesomeIcon
          icon={statusIcon}
          size="2x"
          style={{ color: statusColor }}
        />
      </span>
    </Tooltip>,
    vendorName,
    <VendorCategoryChip {...vendor} />,
    <ScopeThreeSubcategorySelectButton vendor={vendor} />,
    formatDecimal(convertCarbonUnits(tonsCo2e)),
    avgTonsCo2ePerTransaction
      ? formatDecimal(convertCarbonUnits(avgTonsCo2ePerTransaction))
      : "N/A",
    formatDecimal(transactionsCount),
    <Rating value={vendorScore / 30} readOnly max={3} />,
  ];
  return (
    <TableRow hover onClick={() => setSelectedVendor(vendor)}>
      {tableCells.map((cell, idx) => (
        <TableCell key={`vendor-table-row-cell-${idx}`}>{cell}</TableCell>
      ))}
    </TableRow>
  );
};

const CheckedVendorsActionBlock = ({
  checkedVendors,
  setCheckedVendors,
  setMergeVendors,
  setBulkEditVendors,
}) => {
  const { palette } = useTheme();
  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const [deleteLoading, setDeleteLoading] = useState(false);

  const onBulkVendorsDelete = async () => {
    setDeleteLoading(true);
    await Promise.all(
      checkedVendors.map(
        async ({ id }) =>
          await updateCollectionDoc("vendors", id, { archived: true })
      )
    );
    return setCheckedVendors([]);
  };

  const areAnyVendorsConfirmed = checkedVendors.find(
    ({ status }) => status === "confirmed"
  );

  const vendorsCountString = `${checkedVendors.length} vendor${letterSBoolean(
    checkedVendors
  )}`;

  const isAnyCheckedVendorMatched = !!checkedVendors.find(
    ({ status }) => status !== "unmatched"
  );

  return (
    <DefaultPaper>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item>
          <Typography variant="h6">Edit {vendorsCountString}</Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            {checkedVendors.length > 1 && (
              <Grid item>
                <Tooltip title={`Edit ${checkedVendors.length} vendors`}>
                  <IconButton onClick={() => setBulkEditVendors(true)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {checkedVendors.length > 1 && !isAnyCheckedVendorMatched && (
              <Grid item>
                <Tooltip
                  title={`Merge ${checkedVendors.length} vendor${letterSBoolean(
                    checkedVendors
                  )}`}
                >
                  <IconButton onClick={() => setMergeVendors(true)}>
                    <CallMergeIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {!areAnyVendorsConfirmed && (
              <Grid item>
                {deleteLoading ? (
                  <CircularProgress />
                ) : (
                  <Tooltip title={`Delete ${vendorsCountString}`}>
                    <IconButton onClick={onBulkVendorsDelete}>
                      <DeleteIcon style={{ color: palette.error.main }} />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            )}
            <Grid item>
              <Tooltip title="Clear Selection">
                <IconButton onClick={() => setCheckedVendors([])}>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DefaultPaper>
  );
};

const BulkVendorsEditor = ({
  checkedVendors,
  warning,
  onSave,
  cta,
  alternateName,
  initialVendor = {},
  setSelectedObject,
}) => {
  const [bulkVendorData, setBulkVendorData] = useState(initialVendor);
  const [saveLoading, setSaveLoading] = useState(false);

  const { emissionCategory, naicsCode, scopeThreeCategory, name } =
    bulkVendorData;

  const editBulkVendorData = (field) => (value) =>
    editObjectData(setBulkVendorData, field, value);

  const isSaveEnabled = () => {
    if (!(name || alternateName) || !emissionCategory) {
      return false;
    }

    if (emissionCategory !== "spend-based") {
      return true;
    }

    const doAllVendorsHaveNaicsCodes = checkedVendors.every(
      ({ naicsCode }) => !!naicsCode
    );

    if ((doAllVendorsHaveNaicsCodes || naicsCode) && scopeThreeCategory) {
      return true;
    }

    return false;
  };

  return (
    <SettingsObjectDetailsSlider
      type="vendors"
      settingsObject={bulkVendorData}
      objectName={alternateName || name}
      editNameDisabled={!!alternateName}
      editSettingsObject={editBulkVendorData}
      setSelectedObject={setSelectedObject}
      leftContent={
        <Container maxWidth="sm">
          <Grid container direction="column" spacing={2}>
            {checkedVendors.map(({ name, emissionCategory }, idx) => (
              <Grid
                item
                key={`merge-vendor-${idx}`}
                container
                spacing={2}
                alignItems="center"
              >
                <Grid item>
                  <CategoriesAvatar subcategory={emissionCategory} />
                </Grid>
                <Grid item>
                  <Typography variant="h3" style={{ color: "white" }}>
                    {name}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Container>
      }
      views={[
        {
          label: "Details",
          value: "details",
          content: (
            <EditVendorDetails
              editVendor={editBulkVendorData}
              vendor={bulkVendorData}
            />
          ),
          footer: (
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              wrap="nowrap"
            >
              {warning && (
                <Grid item>
                  <Typography variant="body2" color="error" align="right">
                    {warning}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <LoadingButton
                  isLoading={saveLoading}
                  onClick={() => onSave(bulkVendorData, setSaveLoading)}
                  variant="contained"
                  color="primary"
                  label={`${cta} ${
                    checkedVendors.length
                  } vendor${letterSBoolean(checkedVendors)}`}
                  disabled={!isSaveEnabled()}
                />
              </Grid>
            </Grid>
          ),
        },
      ]}
    />
  );
};

const EditBulkVendors = ({
  checkedVendors,
  setCheckedVendors,
  setBulkEditVendors,
}) => {
  const { onVendorSave } = useOnVendorSave();

  const onBulkVendorSave = async (bulkVendorData, setSaveLoading) => {
    setSaveLoading(true);
    await Promise.all(
      checkedVendors.map(
        async (vendor) =>
          await onVendorSave({
            ...vendor,
            ...bulkVendorData,
            vendorIdAssociatedWithTransactions: vendor.id,
          })
      )
    );

    setBulkEditVendors(false);
    return setCheckedVendors([]);
  };

  const buildInitialVendor = () => {
    const uniqueCheckedVendorsSubcategories = [
      ...new Set(
        checkedVendors.map(({ emissionCategory }) => emissionCategory)
      ),
    ];

    if (uniqueCheckedVendorsSubcategories.length > 1) {
      return {};
    }

    const {
      naicsCode: initialNaicsCode,
      scopeThreeCategory: initialScopeThreeCategory,
      emissionCategory: initialEmissionCategory,
    } = checkedVendors[0];

    const uniqueCheckedVendorsNaicsCodes = [
      ...new Set(checkedVendors.map(({ naicsCode }) => naicsCode)),
    ];
    const uniqueCheckedVendorsScopeThreeCategories = [
      ...new Set(
        checkedVendors.map(({ scopeThreeCategory }) => scopeThreeCategory)
      ),
    ];

    const initialNaicsCodeObj =
      uniqueCheckedVendorsNaicsCodes.length === 1
        ? { naicsCode: initialNaicsCode }
        : {};

    const initialScopeThreeCategoryObj =
      uniqueCheckedVendorsScopeThreeCategories.length === 1
        ? { scopeThreeCategory: initialScopeThreeCategory }
        : {};

    return {
      emissionCategory: initialEmissionCategory,
      ...initialNaicsCodeObj,
      ...initialScopeThreeCategoryObj,
    };
  };

  return (
    <BulkVendorsEditor
      alternateName={`Bulk Edit ${checkedVendors.length} Vendors`}
      checkedVendors={checkedVendors}
      onSave={onBulkVendorSave}
      initialVendor={buildInitialVendor()}
      cta="Edit"
      setSelectedObject={setBulkEditVendors}
    />
  );
};

const MergeVendors = ({
  checkedVendors,
  setCheckedVendors,
  setMergeVendors,
}) => {
  const { newCollectionDoc, updateCollectionDoc } = useCachedFirebaseCrud();

  const uniqueSelectedEmissionCategories = [
    ...new Set(checkedVendors.map(({ emissionCategory }) => emissionCategory)),
  ];

  const onMergeVendors = async (mergedVendor, setSaveLoading) => {
    const {
      emissionCategory,
      naicsCode,
      naicsTitle,
      scopeThreeCategory,
      name,
      id: mergedVendorId,
    } = mergedVendor;

    setSaveLoading(true);

    const aliases = [
      ...new Set(
        checkedVendors.flatMap(({ name, aliases = [] }) => [name, ...aliases])
      ),
    ];
    const newVendorId = await newCollectionDoc("vendors", {
      emissionCategory,
      naicsCode,
      naicsTitle,
      scopeThreeCategory,
      name,
      aliases,
      archived: false,
    });

    await Promise.all([
      updateVendorTransactions({
        ...mergedVendor,
        vendorIdAssociatedWithTransactions: mergedVendorId,
        vendorIdToUpdateInTransactions: newVendorId,
      }),
      ...checkedVendors.map(
        async ({ id: vendorId }) =>
          await updateCollectionDoc("vendors", vendorId, { archived: true })
      ),
    ]);

    setCheckedVendors([]);
    return setMergeVendors(false);
  };

  return (
    <BulkVendorsEditor
      checkedVendors={checkedVendors}
      warning={
        uniqueSelectedEmissionCategories.length > 1 &&
        "Warning: you are merging multiple transactions with different categories."
      }
      onSave={onMergeVendors}
      initialVendor={checkedVendors[0]}
      cta="Merge"
      setSelectedObject={setMergeVendors}
    />
  );
};

const NewVendorsButtonMenu = ({ setShowNewVendorInput }) => {
  const [popperOpen, setPopperOpen] = useState(false);

  const { id: accountId } = getAccountCollectionAndId();

  const surveyLink = `https://${window.location.host}/vendor-survey?buyerId=${accountId}`;

  return (
    <Box position="relative">
      <ButtonMenu
        color="primary"
        menuOptions={[
          {
            label: "Add new vendors",
            onClick: () => setShowNewVendorInput(true),
          },
          {
            label: "Send a Survey",
            onClick: (e) => setPopperOpen(true),
          },
        ]}
      />
      {popperOpen && (
        <DefaultPaper
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1000,
          }}
        >
          <Grid container direction="column" spacing={2} wrap="nowrap">
            <Grid
              item
              container
              justifyContent="space-between"
              spacing={2}
              wrap="nowrap"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h4">
                  Have your vendors take a survey
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  When your vendors take a survey using the link below, their
                  emissions will automatically be added to your accounting.
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => setPopperOpen(false)}>
                  <CancelIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item container spacing={2} wrap="nowrap" alignItems="center">
              <Grid item>
                <CopyToClipboardIcon text={surveyLink} />
              </Grid>
              <Grid item>
                <Typography variant="caption" color="textSecondary" noWrap>
                  {surveyLink}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DefaultPaper>
      )}
    </Box>
  );
};

const singleVendorsLoop = ({
  vendors,
  filterByChips,
  filterSubcategories,
  searchString,
}) => {
  let vendorsDisplayObj = {
    vendorsTonsCo2e: 0,
    chartVendors: [],
    filteredVendors: [],
    availableSubcategories: [],
  };

  if (!vendors.length) {
    return vendorsDisplayObj;
  }

  vendors.forEach((vendor) => {
    const { tonsCo2e, emissionCategory, name } = vendor || {};

    if (tonsCo2e) {
      vendorsDisplayObj.vendorsTonsCo2e += tonsCo2e;
      vendorsDisplayObj.chartVendors.push(vendor);
    }

    if (!vendorsDisplayObj.availableSubcategories.includes(emissionCategory)) {
      vendorsDisplayObj.availableSubcategories.push(emissionCategory);
    }

    const filterBySubcategory = () => {
      if (!filterSubcategories.length) {
        return true;
      }

      const filterSubcategoriesArray = filterSubcategories.map(
        ({ value }) => value
      );

      if (!emissionCategory) {
        return filterSubcategoriesArray.includes("uncategorized");
      }

      return filterSubcategoriesArray.includes(emissionCategory);
    };

    const filterBySearchString = () => {
      if (!searchString) {
        return true;
      }

      return name.toLowerCase().includes(searchString.toLowerCase());
    };

    if (
      filterByChips(vendor) &&
      filterBySubcategory() &&
      filterBySearchString()
    ) {
      vendorsDisplayObj.filteredVendors.push(vendor);
    }
  });

  return vendorsDisplayObj;
};

const VendorsViewBlock = ({ viewLoading }) => {
  const { emissionsLoading, vendors, vendorAlerts } = useEmissionsContext();
  const { carbonUnitLabel, convertCarbonUnits } = useMeasurementSystem();

  const { filterChips, filterByChips } = useStatusFilterChips(
    vendors,
    vendorStatuses
  );

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [checkedVendors, setCheckedVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorSearchString, setVendorSearchString] = useState("");
  const [filterSubcategories, setFilterSubcategories] = useState([]);
  const [showNewVendorInput, setShowNewVendorInput] = useState(false);
  const [mergeVendors, setMergeVendors] = useState(false);
  const [bulkEditVendors, setBulkEditVendors] = useState(false);
  const [tablePage, setTablePage] = useState(0);

  const tableColumns = [
    makeColumnObj("SELECT", "isChecked"),
    makeColumnObj("STATUS", "severity", true),
    makeColumnObj("NAME", "name", true),
    makeColumnObj("EMISSION CATEGORY", "emissionCategory", true),
    makeColumnObj("SCOPE 3 CATEGORY", "scopeThreeCategory", true),
    makeColumnObj(
      `TOTAL ${carbonUnitLabel.toUpperCase()} CO2`,
      "tonsCo2e",
      true
    ),
    makeColumnObj(
      `AVG. ${carbonUnitLabel.toUpperCase()} PER TRANSACTION`,
      "avgTonsCo2ePerTransaction",
      true
    ),
    makeColumnObj("TOTAL # OF TRANSACTIONS", "transactionsCount", true),
    makeColumnObj("SCORE", "vendorScore", true),
  ];

  const {
    vendorsTonsCo2e,
    chartVendors,
    filteredVendors,
    availableSubcategories,
  } = singleVendorsLoop({
    vendors,
    filterByChips,
    filterSubcategories,
    searchString: vendorSearchString,
  });

  useEffect(() => {
    if (tablePage * 50 > filteredVendors.length) {
      setTablePage(0);
    }
  }, [filteredVendors.length, tablePage]);

  const selectVendorFromGraph = (id) =>
    setSelectedVendor(chartVendors.find((vendor) => vendor.id === id));

  const buildFilterRows = () => {
    const availableSubcategoriesArray = availableSubcategories
      .filter((subcategory) => subcategory)
      .map((subcategory) => findSubcategory(subcategory));

    return [
      {
        icon: faIndustry,
        title: "Emission Category",
        type: "subcategory",
        filterComponent: (
          <EmissionsCategorySelect
            multiple
            value={filterSubcategories}
            setValue={setFilterSubcategories}
            availableSubcategories={availableSubcategoriesArray}
            additionalSubcategories={[
              {
                label: "Uncategorized",
                value: "uncategorized",
                avatar: (
                  <Avatar style={{ height: 30, width: 30 }}>
                    <QuestionMarkIcon />
                  </Avatar>
                ),
              },
            ]}
          />
        ),
      },
    ];
  };

  return (
    <>
      {showNewVendorInput && (
        <VendorInputForm setOpen={setShowNewVendorInput} />
      )}
      {bulkEditVendors && (
        <EditBulkVendors
          checkedVendors={checkedVendors}
          setCheckedVendors={setCheckedVendors}
          setBulkEditVendors={setBulkEditVendors}
        />
      )}
      {mergeVendors && (
        <MergeVendors
          checkedVendors={checkedVendors}
          setCheckedVendors={setCheckedVendors}
          setMergeVendors={setMergeVendors}
        />
      )}
      {selectedVendor && (
        <VendorDetailsSlider
          selectedVendor={selectedVendor}
          setSelectedVendor={setSelectedVendor}
          setSelectedTransaction={setSelectedTransaction}
        />
      )}
      {selectedTransaction && (
        <EditRowTransactions
          transaction={selectedTransaction || {}}
          setIsSlideOpen={setSelectedTransaction}
          saveButtonText="Save Changes"
        />
      )}
      {!!vendors.length ? (
        <DashboardViewLayout
          type="vendors"
          viewLoading={emissionsLoading || viewLoading}
          title="Vendors"
          subtitle={
            vendors.length
              ? `You have ${
                  vendors.length
                } vendors that have contributed ${formatDecimal(
                  convertCarbonUnits(vendorsTonsCo2e)
                )} ${carbonUnitLabel} to your balance`
              : "`You haven't loaded any vendors yet"
          }
          chips={filterChips}
          filterPopperProps={{
            filterRows: buildFilterRows(),
            resetFilters: () => setFilterSubcategories([]),
            title: "Filter Vendors",
          }}
          primaryAction={
            <NewVendorsButtonMenu
              setShowNewVendorInput={setShowNewVendorInput}
            />
          }
          premiumActions={[
            <TextField
              label="Search Vendors"
              value={vendorSearchString}
              setValue={setVendorSearchString}
            />,
          ]}
          alerts={vendorAlerts}
          graph={
            !!chartVendors.length && (
              <VendorsGraphs
                vendors={[...chartVendors].sort(
                  (a, b) => a.tonsCo2e - b.tonsCo2e
                )}
                setSelectedVendor={selectVendorFromGraph}
              />
            )
          }
          tableAction={
            !!checkedVendors.length && (
              <CheckedVendorsActionBlock
                setBulkEditVendors={setBulkEditVendors}
                setMergeVendors={setMergeVendors}
                checkedVendors={checkedVendors}
                setCheckedVendors={setCheckedVendors}
              />
            )
          }
          table={
            <SortableTable
              rows={[...filteredVendors].sort(
                (a, b) => b.severity - a.severity
              )}
              rowComponent={(vendor) => (
                <VendorsTableRow
                  checkedVendors={checkedVendors}
                  setCheckedVendors={setCheckedVendors}
                  vendor={vendor}
                  setSelectedVendor={setSelectedVendor}
                />
              )}
              columns={tableColumns}
              currentPage={tablePage}
              setCurrentPage={setTablePage}
            />
          }
          footer={<ReductionsBlogModule type="spend-based" />}
        />
      ) : (
        <MissingVendors setShowNewVendorInput={setShowNewVendorInput} />
      )}
    </>
  );
};

const VendorsView = (props) => (
  <ErrorBoundary>
    <VendorsViewBlock {...props} />
  </ErrorBoundary>
);
export default VendorsView;
