import { useContext, useState } from "react";
import dayjs from "dayjs";
import { Promise as bluebird } from "bluebird";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import {
  faCalendarCheck,
  faLocationCrosshairs,
} from "@fortawesome/free-solid-svg-icons";
import { faSeatAirline } from "@fortawesome/pro-solid-svg-icons";

import useCsvUploader from "./csvUploader";
import { PlatformLayoutContext } from "../../helpers/contexts/platformLayout";

import {
  createErrorObj,
  doesFieldExist,
  useSharedFormLoading,
} from "../../helpers/components/inputs";
import { fetchOurApi } from "../../helpers/utils/apiCalls";

import { analyticsTrack } from "../../helpers/analytics";
import { letterSBoolean } from "@aclymatepackages/formatters";
import { useAccountData } from "../../helpers/firebase";

const fieldOptions = [
  { label: "Date", value: "date" },
  { label: "Departure", value: "from" },
  { label: "Arrival", value: "to" },
  { label: "Departure/Arrival", value: "fromTo" },
  { label: "Distance (km)", value: "distanceKm" },
  { label: "Distance (mi)", value: "distanceMile" },
  { label: "Class", value: "class" },
  { label: "Airfare Class Code", value: "airfareCode" },
];

const requiredFields = [
  {
    label: "Date",
    description: "When did you take this flight?",
    icon: faCalendarCheck,
  },
  {
    label: "Distance",
    description: "Either To/from airport codes OR a distance",
    icon: faLocationCrosshairs,
  },
  {
    label: "Passenger Class",
    description: "Either the passenger class OR the airfare class code",
    icon: faSeatAirline,
  },
];

const knownFields = [
  { header: "date", field: "date" },
  { header: "class", field: "class" },
  { header: "kilometers", field: "distanceKm" },
  { header: "km", field: "distanceKm" },
  { header: "distanceKm", field: "distanceKm" },
  { header: "miles", field: "distanceMile" },
  { header: "mi", field: "distanceMile" },
  { header: "distanceMile", field: "distanceMile" },
  { header: "departure", field: "from" },
  { header: "arrival", field: "to" },
  { header: "departure/arrival", field: "fromTo" },
  { header: "airfare class code", field: "airfareCode" },
];

const fieldValidator = (headers) => {
  if (!doesFieldExist(headers, "date")) {
    return createErrorObj('Your CSV must include a "date" field');
  }

  if (
    !(
      (doesFieldExist(headers, "from") && doesFieldExist(headers, "to")) ||
      doesFieldExist(headers, "fromTo") ||
      doesFieldExist(headers, "distanceKm") ||
      doesFieldExist(headers, "distanceMile")
    )
  ) {
    return createErrorObj(
      'Your CSV must include "Departure" and "Arrival" fields or " a Distance" field'
    );
  }

  if (
    !(
      doesFieldExist(headers, "class") || doesFieldExist(headers, "airfareCode")
    )
  ) {
    return createErrorObj(
      'Your CSV must include "Class" or "Airfare Code" fields'
    );
  }
  return { success: true };
};

const useProcessFlights = ({
  fileInfo,
  setFileInfo,
  setFlightsResponseCount,
  setTotalFlightsCount,
  setOpen,
}) => {
  const { csvId, name: fileName } = fileInfo;
  const { setFormLoading } = useSharedFormLoading();
  const [{ startDate }] = useAccountData();

  const { activateSnackbar } = useContext(PlatformLayoutContext);

  const sendFlightsToBackend = async (flight) => {
    const accountId = window.sessionStorage.getItem("accountId");

    return await fetchOurApi({
      path: "/transactions/flight-sort",
      method: "POST",
      data: {
        flight,
        accountId,
      },
      callback: (response) => {
        setFlightsResponseCount((currentCount) => currentCount + 1);
        const { success, flight: responseFlight } = response;
        if (success) {
          const dummyId = Math.random() * Date.now();
          return { ...responseFlight, id: dummyId };
        }
        if (!success) {
          console.log("no success");
          return null;
        }
      },
    });
  };

  const sortOrExcludeFlights = async (flight) => {
    const { date } = flight;

    if (startDate && dayjs(date).isBefore(startDate)) {
      setFlightsResponseCount((currentCount) => currentCount + 1);
      return { ...flight, beforeStartDate: true };
    }

    return sendFlightsToBackend(flight);
  };

  return async ({ data: flights }) => {
    setFormLoading(true);

    editObjectData(setFileInfo, "csvId", csvId);

    analyticsTrack("Flights Loaded", {
      numberOfFlights: flights.length,
    });

    setTotalFlightsCount(flights.length);

    const flightsAdditionalData = flights.map((flight) => {
      const { date } = flight;
      const name = `${dayjs(new Date(date)).format(
        "MM/DD/YYYY"
      )} Flights Emissions`;

      return {
        ...flight,
        source: "csv-upload",
        csvId,
        csvName: fileName,
        status: "confirmed",
        subcategory: "flights",
        vendor: name,
        name,
      };
    });

    const newFlights = await bluebird.map(
      flightsAdditionalData,
      sortOrExcludeFlights,
      { concurrency: 10 }
    );

    const snackbarMessage = `${
      newFlights.length
    } flight emission${letterSBoolean(newFlights)} were added to your account`;
    activateSnackbar({ message: snackbarMessage, alert: "success" });

    setOpen(false);
    return setFormLoading(false);
  };
};

const useFlightsCsvUploader = ({ open, setOpen }) => {
  const [fileInfo, setFileInfo] = useState({});
  const [matchedHeaders, setMatchedHeaders] = useState([]);
  const [totalFlightsCount, setTotalFlightsCount] = useState(0);
  const [flightsResponseCounts, setFlightsResponseCount] = useState(0);

  const processData = useProcessFlights({
    fileInfo,
    setFileInfo,
    setFlightsResponseCount,
    setTotalFlightsCount,
    setOpen,
  });

  const csvUploaderSteps = useCsvUploader({
    knownFields,
    fieldOptions,
    open,
    setOpen,
    fileInfo,
    setFileInfo,
    matchedHeaders,
    setMatchedHeaders,
    processData,
    fieldValidator,
    docType: "flights",
    firstStepText:
      "Upload a CSV of your transactions and we'll automatically sort out the ones that you can offset.",
    requiredFields,
  });

  return [csvUploaderSteps, (flightsResponseCounts / totalFlightsCount) * 100];
};
export default useFlightsCsvUploader;
