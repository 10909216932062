import React, { useState, createContext } from "react";

export const PlatformLayoutContext = createContext();

export const PlatformLayoutContextProvider = ({ children }) => {
  const LSsideNavOpen = window.localStorage.getItem("sideNavOpen");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});
  const [sideNavOpen, setSideNavOpen] = useState(LSsideNavOpen === "true");

  const [viewMode, setViewMode] = useState(
    window.localStorage.getItem("aclymate-view-mode") || "company"
  );

  const activateSnackbar = (props) => {
    setSnackbarProps(props);
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 5000);
  };

  return (
    <PlatformLayoutContext.Provider
      value={{
        snackbarOpen,
        setSnackbarOpen,
        snackbarProps,
        activateSnackbar,
        sideNavOpen,
        setSideNavOpen,
        viewMode,
        setViewMode,
      }}
    >
      {children}
    </PlatformLayoutContext.Provider>
  );
};
