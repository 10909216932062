import React, { useState } from "react";
import { useParams } from "react-router";

import {
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Bar,
  BarChart,
  Tooltip,
} from "recharts";

import { Box, useTheme } from "@mui/material";

import { formatDecimal } from "@aclymatepackages/formatters";

import CustomChartBrush from "./CustomChartBrush";
import useChartWarningLabels from "./useChartWarningLabels";

import useMeasurementSystem from "../../../helpers/hooks/measurementSystem";

const ViewBarChart = ({
  data,
  setSelectedObject,
  yLabel = "Average Monthly Emissions",
  tooltipComponent,
  barAreasArray,
  dataType,
}) => {
  const { palette } = useTheme();
  const { carbonUnitLabel } = useMeasurementSystem();
  const params = useParams();
  const { view } = params || {};

  const { warningLabels, labelSetter } = useChartWarningLabels({
    data,
    warningField: "status",
    barSumField: "emissionsSumTons",
  });

  const [range, setRange] = useState([0, 50]);
  const [startIndex, endIndex] = range;

  const handleBarClick = (event) => {
    if (!event?.activePayload || event.activePayload.length === 0) {
      return;
    }

    const { activePayload } = event;

    if (dataType && dataType === "events") {
      return setSelectedObject(activePayload[0].payload.name);
    }

    return setSelectedObject(activePayload[0].payload.id);
  };

  const filteredData = data.slice(startIndex, endIndex + 1);

  const shouldShowBrush = data.length > 30;

  return (
    <Box position="relative">
      <ResponsiveContainer width="100%" aspect={4}>
        <BarChart
          width={500}
          height={300}
          data={filteredData}
          style={{ cursor: "pointer" }}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: shouldShowBrush ? 10 : 5,
          }}
          onClick={(event) => handleBarClick(event)}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis
            width={70}
            name={yLabel}
            tickFormatter={(tick) =>
              `${formatDecimal(tick)} ${carbonUnitLabel}`
            }
          />
          <Tooltip wrapperStyle={{ zIndex: 99 }} content={tooltipComponent} />
          {barAreasArray.map(({ name, dataKey, color }, idx) => (
            <Bar
              key={`view-chart-bar-${idx}`}
              name={name}
              dataKey={dataKey}
              fill={color}
              stackId="a"
              isAnimationActive={false}
            >
              {labelSetter}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
      {shouldShowBrush && (
        <CustomChartBrush
          min={0}
          max={data.length}
          onChange={setRange}
          initialRange={data.length > 50 ? [0, 50] : [0, data.length]}
          step={1}
          brushColor={palette[view].main || palette.primary.main}
        />
      )}
      {warningLabels}
    </Box>
  );
};
export default ViewBarChart;
