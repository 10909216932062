import { useState } from "react";

import {
  faCalendarCheck,
  faShop,
  faSmog,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";

import useCsvUploader from "./csvUploader";

const fieldOptions = [
  {
    label: "Date",
    value: "date",
    description: "When did this transaction happen?",
    icon: faCalendarCheck,
    required: true,
  },
  {
    label: "Vendor",
    value: "vendor",
    description: "Who did you buy it from?",
    icon: faShop,
    required: true,
  },
  {
    label: "Amount",
    value: "value",
    description: "How much did it cost?",
    icon: faDollarSign,
    required: true,
  },
  {
    label: "Category",
    value: "category",
    description: "(Optional) What type of purchase was it?",
    icon: faSmog,
    required: false,
  },
];

const knownFields = [
  { header: "date", field: "date" },
  { header: "description", field: "vendor" },
  { header: "vendor", field: "vendor" },
  { header: "memo", field: "vendor" },
  { header: "value", field: "value" },
  { header: "total", field: "value" },
  { header: "amount", field: "value" },
  { header: "category", field: "category" },
  { header: "description", field: "category" },
];

const fieldValidator = (headers) => {
  const fieldsBoolean = fieldOptions
    .filter(({ required }) => required)
    .map(({ value }) => value)
    .reduce(
      (booleanAcc, requiredField) =>
        booleanAcc &&
        headers.find(({ field }) => requiredField.toLowerCase() === field),
      true
    );

  return {
    success: fieldsBoolean,
    message:
      !fieldsBoolean &&
      `The fields Date, Vendor, and Value are required for this CSV.`,
  };
};

const useTransactionsUploader = ({ open, setOpen }) => {
  const [fileInfo, setFileInfo] = useState({});
  const [matchedHeaders, setMatchedHeaders] = useState([]);

  const csvUploaderSteps = useCsvUploader({
    knownFields,
    fieldOptions,
    open,
    setOpen,
    fileInfo,
    setFileInfo,
    matchedHeaders,
    setMatchedHeaders,
    fieldValidator,
    docType: "transactions",
    firstStepText:
      "Upload a CSV of your transactions and we'll automatically convert them into emissions.",
    requiredFields: fieldOptions,
  });

  return csvUploaderSteps;
};
export default useTransactionsUploader;
