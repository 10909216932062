import React, { useState } from "react";

import { Box, Grid, ButtonBase, Typography } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSteeringWheel,
  faTrees,
  faIcicles,
  faHamburger,
  faPiggyBank,
  faMobile,
} from "@fortawesome/pro-solid-svg-icons";

import {
  kgsToTons,
  tonsToLbs,
  convertFootprintToDriving,
  convertFootprintToIce,
  footprintToHamburgers,
} from "@aclymatepackages/converters";
import { formatDecimal } from "@aclymatepackages/formatters";
import { useLayoutHelpers } from "@aclymatepackages/themes";

//source: Google Drive: Aclymate Team\Climate Accounting\Data\Tree Carbon Calculator\CarbonCalculator31.xls
const convertFootprintToTreeYears = (footprintTons) => {
  const treeYearsDecimal = footprintTons / kgsToTons(160.6);
  return formatDecimal(treeYearsDecimal.toFixed(1));
};

//source: https://shrinkthatfootprint.com/carbon-targets-for-your-footprint/
const footprintToCarbonBudgetPercent = (footprintTons) =>
  `${formatDecimal((footprintTons / 4.1) * 100)}%`;

//SOURCE:  https://news.energysage.com/how-many-watts-does-a-phone-charger-use/
//US average carbon intensity comes from carbon intensity data in app backend
const footprintToCellphoneYears = (footprintTons) => {
  const US_AVG_TONS_CO2E_PER_KWH = 0.00043222913699413057;
  const CELLPHONE_KWH_PER_YEAR = 1.83;
  const footprintYears =
    footprintTons / US_AVG_TONS_CO2E_PER_KWH / CELLPHONE_KWH_PER_YEAR;

  return formatDecimal(footprintYears.toFixed(1));
};

const FootprintEquivalencies = ({
  footprintTons,
  exclusionCategories = [],
}) => {
  const { theme, isMedium } = useLayoutHelpers();

  const [selectedCategoryIdx, setSelectedCategoryIdx] = useState(0);

  const footprintEquivalentsCategories = [
    {
      type: "driving-emissions",
      icon: faSteeringWheel,
      buildString: (carbon, equivalency) =>
        `Your ${carbon} lbs. of carbon is like driving a car ${equivalency} miles.`,
      equivalency: convertFootprintToDriving(footprintTons),
      color: "vehicles",
      backgroundOpacity: 0.5,
    },
    {
      type: "tree-sequestration",
      icon: faTrees,
      buildString: (carbon, equivalency) =>
        `It would take an average oak tree ${equivalency} years to absorb your ${carbon} lbs. of carbon`,
      equivalency: convertFootprintToTreeYears(footprintTons),
      color: "secondary",
      backgroundOpacity: 0.3,
    },
    {
      type: "melting-polar-bear",
      icon: faIcicles,
      buildString: (carbon, equivalency) =>
        `Your ${carbon} lbs. of carbon is enough to permanently melt ${equivalency} square ${
          equivalency === "1" ? "foot" : "feet"
        } of Arctic sea ice`,
      equivalency: formatDecimal(convertFootprintToIce(footprintTons)),
      color: "primary",
      backgroundOpacity: 0.3,
    },
    {
      type: "eating-hamburgers",
      icon: faHamburger,
      buildString: (carbon, equivalency) =>
        `Your ${carbon} lbs. of carbon is equivalent to the emissions of eating ${equivalency} hamburgers`,
      equivalency: formatDecimal(footprintToHamburgers(footprintTons)),
      color: "summary",
      backgroundOpacity: 0.4,
    },
    {
      type: "carbon-budget",
      icon: faPiggyBank,
      buildString: (carbon, equivalency) =>
        `Your ${carbon} lbs. of carbon is ${equivalency} of the annual carbon budget that we all need to achieve to reach our global climate goals.`,
      equivalency: footprintToCarbonBudgetPercent(footprintTons),
      color: "offices",
      backgroundOpacity: 0.5,
    },
    {
      type: "cell-phone-charging",
      icon: faMobile,
      buildString: (carbon, equivalency) =>
        `Your ${carbon} lbs. of carbon is enough to keep your cellphone charged for ${equivalency} years`,
      equivalency: footprintToCellphoneYears(footprintTons),
      color: "rides",
      backgroundOpacity: 0.5,
    },
  ];

  const filteredEquivalentCategories = footprintEquivalentsCategories.filter(
    ({ type }) => !exclusionCategories.includes(type)
  );

  const { type, buildString, equivalency } =
    filteredEquivalentCategories[selectedCategoryIdx];

  return (
    <Grid container spacing={2} alignItems="stretch" justifyContent="center">
      <Grid
        item
        xs={2}
        container
        direction="column"
        spacing={2}
        justifyContent={isMedium ? "space-around" : "center"}
        wrap="nowrap"
      >
        {filteredEquivalentCategories.map(({ icon, color }, idx) => (
          <Grid item key={`grid-equivalency-carousel-icon-${idx}`}>
            <ButtonBase onClick={() => setSelectedCategoryIdx(idx)}>
              <Box
                style={{
                  height: "50px",
                  width: "50px",
                  backgroundColor:
                    idx === selectedCategoryIdx
                      ? theme.palette[color].main
                      : "white",
                  borderRadius: "50%",
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <FontAwesomeIcon
                  icon={icon}
                  size="2x"
                  style={{
                    color:
                      idx === selectedCategoryIdx
                        ? "white"
                        : theme.palette[color].main,
                  }}
                />
              </Box>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={10} container direction="column">
        <Grid item>
          <img
            alt={`${type}`}
            src={`https://aclymate.app/images/footprint-equivalencies/${type}.svg`}
            style={{ width: "100%", maxHeight: "250px" }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h6" align="center">
            {buildString(formatDecimal(tonsToLbs(footprintTons)), equivalency)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FootprintEquivalencies;
