import React, { useState } from "react";

import { Grid, Typography } from "@mui/material";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { ToggleButtons } from "@aclymatepackages/atoms";
import { fuelCarbonFromGallons } from "@aclymatepackages/calcs/travel";

import FuelVolumeInput from "../FuelVolumeInput";
import RentalOrCompanyVehicleButtons from "../../atoms/buttons/RentalOrCompanyVehicleButtons";

import {
  buildInitialTransactionInputValueFromSchema,
  convertVolumeToGallons,
} from "../../../helpers/components/inputs";

const FuelInputBlock = ({
  editInputData,
  purchaseType,
  setPurchaseType,
  billGallons,
  fuelType,
  fuelVolume,
  volumeUnit,
}) => {
  return (
    <Grid
      item
      container
      justifyContent="space-around"
      spacing={3}
      direction="column"
      wrap="nowrap"
    >
      <RentalOrCompanyVehicleButtons
        purchaseType={purchaseType}
        setPurchaseType={setPurchaseType}
      />
      <Grid item>
        <Typography variant="h6">Gallons of Fuel Purchased</Typography>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid item>
          <ToggleButtons
            size="small"
            value={fuelType}
            onChange={editInputData("fuelType")}
            buttons={[
              {
                value: "gas",
                name: "Gasoline",
                id: "gas",
              },
              {
                value: "diesel",
                name: "Diesel",
                id: "diesel",
              },
            ]}
          />
        </Grid>
      </Grid>
      <Grid item>
        <FuelVolumeInput
          billGallons={billGallons}
          fuelVolume={fuelVolume}
          volumeUnit={volumeUnit}
          editTransaction={editInputData}
        />
      </Grid>
    </Grid>
  );
};

const useFuelInput = ({ transaction = {}, onSave }) => {
  const { vehicle } = transaction;
  const { fuelType: vehicleFuelType } = vehicle || {};

  const inputSchema = [
    { field: "purchaseType", defaultValue: "company-vehicle" },
    { field: "fuelCost" },
    { field: "gasStation" },
    { field: "billGallons" },
    { field: "fuelType", defaultValue: "gas" },
    { field: "fuelVolume" },
    { field: "volumeUnit" },
  ];

  const [inputData, setInputData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );

  const {
    purchaseType,
    billGallons,
    fuelType: inputFuelType,
    fuelVolume,
    volumeUnit,
  } = inputData;

  const editInputData = (field) => (value) =>
    editObjectData(setInputData, field, value);

  const fuelType = vehicleFuelType || inputFuelType;

  const inputBlock = (
    <FuelInputBlock
      {...transaction}
      {...inputData}
      fuelType={fuelType}
      editInputData={editInputData}
      purchaseType={purchaseType}
      setPurchaseType={editInputData("purchaseType")}
    />
  );

  const convertFuelVolumeToGallons = () => {
    if (fuelVolume && volumeUnit) {
      return convertVolumeToGallons(fuelVolume, volumeUnit);
    }

    return billGallons || 0;
  };

  const tonsCo2e = fuelCarbonFromGallons(
    convertFuelVolumeToGallons(),
    fuelType
  );

  const onTransactionSave = () =>
    onSave({
      ...inputData,
      tonsCo2e,
    });

  const saveEnabled = !!billGallons
    ? numbersRegExpTest(billGallons) && billGallons < 10000
    : !!tonsCo2e;

  return {
    inputBlock,
    onTransactionSave,
    saveEnabled,
    tonsCo2e,
  };
};
export default useFuelInput;
