import React, { useState } from "react";

import {
  Grid,
  Button,
  Typography,
  Tooltip,
  IconButton,
  Collapse,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  useTheme,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { TextField } from "@aclymatepackages/atoms";
import {
  removeDataRow,
  editRowData,
  addDataRow,
} from "@aclymatepackages/array-immutability-helpers";

import VehicleForm from "./VehicleForm";

import WarningPopup from "../../atoms/notifications/WarningPopup";

import { sendEmployeeSurvey } from "../../../helpers/components/employees";
import { useEditableVehicle } from "../../hooks/vehicles";

import { generateSecureId } from "../../../helpers/otherHelpers";

const ArchivePersonalVehicleInput = ({
  survey,
  archivePersonalVehicleHandler,
  sendEmployeeSurvey,
  createNewCommuteScheduleStepHandler,
  setIsAboutToDelete,
}) =>
  survey ? (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>
        <Button
          variant="contained"
          onClick={() => createNewCommuteScheduleStepHandler()}
        >
          Yes
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={() => setIsAboutToDelete(false)}>
          No
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>
        <Button
          variant="contained"
          onClick={() => archivePersonalVehicleHandler()}
        >
          I'll fix this employee's commute schedule myself
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          onClick={() => {
            sendEmployeeSurvey();
            return archivePersonalVehicleHandler();
          }}
        >
          Send a survey to this employee to update their current commute
          schedule
        </Button>
      </Grid>
    </Grid>
  );

const VehicleNameInput = ({ name, editVehicleHandler }) => (
  <Grid item>
    <TextField
      fullWidth
      variant="standard"
      value={name || ""}
      setValue={editVehicleHandler("name")}
      id={`vehicleNameInput-0`}
    />
  </Grid>
);

const VehicleDetailsCard = ({
  vehicle,
  setVehicle,
  ownerName,
  employee,
  displayVehicles,
  setDisplayVehicles,
  survey,
  companyId,
  companyData,
  removeRecentlyCreatedVehicle,
  setIsBuildNewCommuteScheduleStep,
  address,
}) => {
  const { id, name, make, model, year, isNewAndUnsaved } = vehicle || {};

  const {
    savePersonalVehiclesChanges,
    archivePersonalVehicle,
    isVehicleEdited,
    doesEmployeeUseVehicleForCommuting,
    editVehicleHandler,
    availableFuelTypes,
  } = useEditableVehicle({
    newVehicle: vehicle,
    employee,
    setVehicle,
    ownerName,
    address,
    displayVehicles,
    setDisplayVehicles,
    survey,
    companyId,
    companyData,
  });

  const { palette } = useTheme();

  const [isVehicleEditable, setIsVehicleEditable] = useState(
    survey || isNewAndUnsaved || false
  );
  const [isAboutToDelete, setIsAboutToDelete] = useState(false);

  const saveVehiclesChangesHandler = () => {
    savePersonalVehiclesChanges();
    return setIsVehicleEditable(false);
  };

  const archivePersonalVehicleHandler = () => {
    isNewAndUnsaved ? removeRecentlyCreatedVehicle() : archivePersonalVehicle();
    return setIsAboutToDelete(false);
  };

  const createNewCommuteScheduleStepHandler = () => {
    setDisplayVehicles((displayVehicles) =>
      displayVehicles.filter(({ id: vehicleId }) => vehicleId !== id)
    );
    setIsAboutToDelete(false);
    return setIsBuildNewCommuteScheduleStep(true);
  };

  const archivePersonalVehicleWarning = {
    title: survey
      ? "Archiving this vehicle will cause your current commute schedule to become incomplete. Would you like to create a new current commute schedule after you are done editing your vehicles?"
      : "Archiving this vehicle will cause this employee's current commute schedule to become incomplete. How would you like to fix this?",
    input: (
      <ArchivePersonalVehicleInput
        survey={survey}
        archivePersonalVehicleHandler={archivePersonalVehicleHandler}
        sendEmployeeSurvey={() => sendEmployeeSurvey({ employee })}
        setIsAboutToDelete={setIsAboutToDelete}
        createNewCommuteScheduleStepHandler={
          createNewCommuteScheduleStepHandler
        }
      />
    ),
  };

  const buildCardHeaderActions = () => {
    if (
      isAboutToDelete &&
      !doesEmployeeUseVehicleForCommuting(vehicle, employee)
    ) {
      return (
        <Grid container>
          <Grid item container direction="row">
            <Grid item>
              <Tooltip title="Yes, I want to archive this vehicle">
                <span>
                  <IconButton
                    onClick={() => archivePersonalVehicleHandler()}
                    size="large"
                  >
                    <CheckIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="No, I do not want to archive this vehicle">
                <span>
                  <IconButton
                    onClick={() => setIsAboutToDelete(false)}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        {!survey && (
          <Tooltip title="Edit this vehicle's information">
            <span>
              <IconButton
                onClick={() =>
                  setIsVehicleEditable(
                    (isVehicleEditable) => !isVehicleEditable
                  )
                }
                size="large"
              >
                {isVehicleEditable ? <ExpandLessIcon /> : <EditIcon />}
              </IconButton>
            </span>
          </Tooltip>
        )}
        <Tooltip title="Archive this vehicle">
          <span>
            <IconButton
              onClick={() =>
                setIsAboutToDelete((isAboutToDelete) => !isAboutToDelete)
              }
              size="large"
            >
              <DeleteForeverIcon />
            </IconButton>
          </span>
        </Tooltip>
      </>
    );
  };

  const buildCardHeaderTitle = () => {
    if (
      isAboutToDelete &&
      !doesEmployeeUseVehicleForCommuting(vehicle, employee)
    ) {
      return `Are you sure you would like to ${
        isNewAndUnsaved ? `delete` : `archive`
      } this vehicle?`;
    }

    if (isVehicleEditable) {
      return (
        <VehicleNameInput name={name} editVehicleHandler={editVehicleHandler} />
      );
    }

    return name || "";
  };

  const buildCardSubheader = () => {
    if (
      isVehicleEditable ||
      (isAboutToDelete &&
        !doesEmployeeUseVehicleForCommuting(vehicle, employee))
    ) {
      return null;
    }

    return `${year || ""} ${make || ""} ${model || ""}`;
  };

  return (
    <Card
      style={{
        backgroundColor: survey ? "white" : palette.backgroundGray.main,
      }}
    >
      <CardHeader
        action={buildCardHeaderActions()}
        title={buildCardHeaderTitle()}
        subheader={buildCardSubheader()}
      />
      <Collapse
        in={
          isAboutToDelete &&
          doesEmployeeUseVehicleForCommuting(vehicle, employee)
        }
        timeout="auto"
        unmountOnExit
      >
        <CardContent>
          <WarningPopup
            warningTitle={archivePersonalVehicleWarning.title}
            warningInput={archivePersonalVehicleWarning.input}
          />
        </CardContent>
      </Collapse>
      <Collapse in={isVehicleEditable} timeout="auto" unmountOnExit>
        <CardContent>
          <VehicleForm
            {...vehicle}
            availableFuelTypes={availableFuelTypes}
            editVehicleHandler={editVehicleHandler}
          />
        </CardContent>
        <CardActions>
          {!survey && (
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Tooltip title="Save changes to this vehicle">
                  <span>
                    <IconButton
                      onClick={() => saveVehiclesChangesHandler()}
                      color="primary"
                      disabled={
                        !isVehicleEdited || !name || !make || !model || !year
                      }
                      size="large"
                    >
                      <SaveIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </CardActions>
      </Collapse>
    </Card>
  );
};

const EditableVehiclesCards = ({
  vehicles = [],
  setVehicles,
  employee,
  survey,
  companyData,
  companyId,
  setIsBuildNewCommuteScheduleStep,
}) => {
  const { name: employeeName, address: employeeAddress } = employee || {};
  const { name: companyName, geography } = companyData || {};
  const { address: companyAddress } = geography || {};

  const addAnotherVehicle = () =>
    addDataRow(setVehicles, { id: generateSecureId(), isNewAndUnsaved: true });

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      spacing={2}
    >
      {vehicles.length ? (
        <Grid
          item
          container
          spacing={2}
          justifyContent={vehicles.length > 3 ? "flex-start" : "center"}
          alignItems="center"
        >
          {vehicles.map((vehicle, idx) => (
            <Grid item key={idx} xs={survey ? 4 : 12}>
              <VehicleDetailsCard
                vehicle={vehicle}
                setVehicle={editRowData(idx, setVehicles)}
                removeRecentlyCreatedVehicle={removeDataRow(idx, setVehicles)}
                ownerName={employeeName || companyName}
                address={employeeAddress || companyAddress}
                employee={employee}
                displayVehicles={vehicles}
                setDisplayVehicles={setVehicles}
                survey={survey}
                companyData={companyData}
                companyId={companyId}
                setIsBuildNewCommuteScheduleStep={
                  setIsBuildNewCommuteScheduleStep
                }
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid item>
          <Typography variant="h6" align="center">
            {survey
              ? "You have no personal vehicles"
              : "This employee has no personal vehicles."}
          </Typography>
        </Grid>
      )}
      {survey && (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => addAnotherVehicle()}
          >
            Add Another Vehicle
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default EditableVehiclesCards;
