import { useAccountData } from "../firebase";

import { carbonUnitTypes } from "../otherHelpers";

const useMeasurementSystem = () => {
  const [{ measurementSystem }] = useAccountData();

  const carbonUnit = measurementSystem === "imperial" ? "lbs" : "tons";
  const { converter, name } = carbonUnitTypes.find(
    ({ short }) => short === carbonUnit
  );

  return {
    measurementSystem,
    carbonUnit,
    carbonUnitLabel: name,
    convertCarbonUnits: converter,
  };
};
export default useMeasurementSystem;
