import React, { useState } from "react";

import { StyledEngineProvider, ThemeProvider } from "@mui/material";

import { mergeDarkTheme } from "@aclymatepackages/themes";

import MultiPartForm from "../flows/MultipartForm";

import CircularProgressWithLabel from "../../atoms/loading/CircularProgressWithLabel";
import useTransactionsUploader from "../../hooks/transactionsCsvUploader";
import useMileageCsvUploader from "../../hooks/mileageCsvUploader";
import useFlightsCsvUploader from "../../hooks/flightsCsvUploader";
import useUtilitiesCsvUploader from "../../hooks/utilitiesCsvUploader";
import useShippingTransactionsCsvUploader from "../../hooks/shippingCsvUploader";

const MultipleTransactionsUploader = ({ open, setOpen }) => {
  const transactionsCsvUploaderSteps = useTransactionsUploader({
    open,
    setOpen,
  });
  const [mileageCsvUploaderSteps, mileageCsvUploaderLoadingValue] =
    useMileageCsvUploader({ open, setOpen });
  const [flightsCsvUploaderSteps, flightsCsvUploaderLoadingValue] =
    useFlightsCsvUploader({ open, setOpen });
  const [utilitiesCsvUploaderSteps, utilitiesCsvUploaderLoadingValue] =
    useUtilitiesCsvUploader({ open, setOpen });
  const shippingCsvUploaderSteps = useShippingTransactionsCsvUploader({
    open,
    setOpen,
  });

  const [selectedOption, setSelectedOption] = useState("");

  const forms = {
    transactions: transactionsCsvUploaderSteps,
    mileage: mileageCsvUploaderSteps,
    flights: flightsCsvUploaderSteps,
    utilities: utilitiesCsvUploaderSteps,
    shipping: shippingCsvUploaderSteps,
  };
  const formsLoadingValues = {
    mileage: mileageCsvUploaderLoadingValue,
    flights: flightsCsvUploaderLoadingValue,
    utilities: utilitiesCsvUploaderLoadingValue,
  };

  return (
    <MultiPartForm
      type="transactions"
      forms={forms[selectedOption]}
      setOption={setSelectedOption}
      selectedOption={selectedOption}
      onClose={() => setOpen(false)}
      submitLoadingText="Please wait while we upload your CSV..."
      loadingAnimation={
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mergeDarkTheme}>
            <CircularProgressWithLabel
              value={formsLoadingValues[selectedOption]}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      }
    />
  );
};

export default MultipleTransactionsUploader;
