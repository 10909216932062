import React from "react";
import { Grid, Typography, Button, Divider, Link } from "@mui/material";

import { TextField } from "@aclymatepackages/atoms";
import { emailRegExpTest } from "@aclymatepackages/reg-exp";

import PasswordInput from "./PasswordInput";
import OAuthButtons from "./OAuthButtons";

import {
  onSignInWithEmailAndPassword,
  getUserAccountData,
} from "../../helpers/components/accountAccess";
import { deleteOauthUser } from "../../helpers/utils/apiCalls";

const SignInForm = ({
  userEmail,
  setUserEmail,
  password,
  setPassword,
  setAccountAccessError,
  setDialogOpen,
  loginLoading,
  setLoginLoading,
  existingEmail,
  onLogin,
  hideCta,
}) => {
  const onOauthSignIn = async (user) => {
    const { companyData, individualData } = await getUserAccountData(user);

    if (!companyData && !individualData) {
      const { email } = user;
      await deleteOauthUser(email);
      setLoginLoading(false);
      return setAccountAccessError(
        "Account doesn't exist. Please try creating an account instead."
      );
    }

    return await onLogin(user);
  };

  return (
    <>
      <Grid item>
        {existingEmail ? (
          <Typography variant="body1" align="center">
            {`Login to your existing Aclymate account (${existingEmail}) to link your new myAclymate account.`}
          </Typography>
        ) : (
          <TextField
            label="Email Address"
            value={userEmail}
            setValue={setUserEmail}
          />
        )}
      </Grid>
      <Grid item>
        <PasswordInput password={password} setPassword={setPassword} />
        {!setDialogOpen && (
          <Typography variant="caption">
            Forgot your password?{" "}
            <Link
              color="inherit"
              underline="always"
              href="/forgot-password"
              styleProps={{ cursor: "pointer" }}
            >
              Click here
            </Link>{" "}
            to reset it. We will never sell or share your data and will only use
            it to calculate your carbon emissions as described in our{" "}
            <Link href="https://aclymate.com/privacy-policy">
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link href="https://aclymate.com/terms-and-conditions">
              Terms of Use
            </Link>
          </Typography>
        )}
      </Grid>
      <Grid container item justifyContent="center" alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={
              loginLoading ||
              !emailRegExpTest(userEmail) ||
              password.length < 12
            }
            onClick={() =>
              onSignInWithEmailAndPassword({
                email: userEmail,
                password,
                setErrorMsg: setAccountAccessError,
                callback: onLogin,
                setUserLoading: setLoginLoading,
              })
            }
          >
            Login
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <OAuthButtons
        callback={onOauthSignIn}
        setErrorMsg={setAccountAccessError}
      />
      {(!setDialogOpen || hideCta) && (
        <Grid item container direction="column" spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="body1" display="inline">
              First time here?
            </Typography>
          </Grid>
          <Grid item>
            <Link href="/account-creation/company">
              <Typography variant="body2">Create a company account</Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link href="/myaclymate">
              <Typography variant="body2">
                Create a myAclymate account
              </Typography>
            </Link>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default SignInForm;
