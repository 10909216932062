import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Paper,
  Typography,
  Tabs,
  Tab,
  Divider,
  Button,
  Avatar,
  Tooltip,
  IconButton,
  Alert,
  useTheme,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { hexToRgba } from "@aclymatepackages/converters";
import { AnimatedLogo } from "@aclymatepackages/atoms";
import { formatDecimal } from "@aclymatepackages/formatters";
import GoogleMap from "@aclymatepackages/google-maps";
import { useLayoutHelpers } from "@aclymatepackages/themes";

import Link from "../../atoms/mui/Link";
import SlideLayout from "../../layouts/SlideLayout";

import { googlePurchaseTracking } from "../../../helpers/components/purchase";
import { CNAUGHT_PROJECT_DESCRIPTION } from "../../../helpers/components/projects";

const VerificationTab = ({
  registry,
  registryUrl,
  registryImage,
  label,
  buttonText,
}) => {
  const showImage = () => {
    if (!registryUrl) {
      return "no-registry.svg";
    }
    if (registryImage) {
      return registryImage;
    }
    return `/images/registries/${
      registryUrl
        ? registryImage ||
          `${registry.replace(".", "-").toLowerCase().split(" ").join("-")}.jpg`
        : "no-registry.svg"
    }`;
  };

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <img
          alt={registryUrl ? registry : "No registry found"}
          src={showImage()}
          style={{ width: "350px" }}
        />
      </Grid>
      <Grid item>
        {registryUrl ? (
          <Typography variant="h6" align="center">
            {label || `This project is verified by the ${registry}`}
          </Typography>
        ) : (
          <Typography variant="subtitle1" align="center">
            We haven't tracked down the verification documents for this project.
            If you want, let us know that you're looking for them and we'll
            prioritize finding them for you.
          </Typography>
        )}
      </Grid>
      {registryUrl && (
        <Grid item>
          <Link href={registryUrl}>
            <Button variant="contained" color="primary">
              {buttonText || "View verification documents"}
            </Button>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

const OverviewImagesCarousel = ({ images = [] }) => {
  const [carouselStep, setCarouselStep] = useState(0);

  if (images?.length === 1) {
    const [image] = images;

    return (
      <Paper style={{ overflow: "hidden" }}>
        <Box
          style={{
            height: 250,
            backgroundImage: `url('${image}')`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        />
      </Paper>
    );
  }

  return (
    <Grid item>
      <Paper style={{ overflow: "hidden", position: "relative" }}>
        <Box
          style={{
            height: 250,
            backgroundImage: `url('${images[carouselStep]}')`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        />
        <Box
          p={1}
          style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
        >
          <Grid container justifyContent="center">
            {[...new Array(images.length)].map((_, idx) => (
              <Grid item key={`icon-button-${idx}`}>
                <IconButton onClick={() => setCarouselStep(idx)} size="small">
                  <Box
                    p={1}
                    style={{
                      borderRadius: "50%",
                      backgroundColor: hexToRgba(
                        "#ffffff",
                        idx === carouselStep ? 1 : 0.7
                      ),
                    }}
                  />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

const ProjectOverviewTab = ({ project }) => {
  const {
    distanceMi,
    city,
    state,
    country,
    images,
    tagline,
    mechanism = "",
  } = project;

  const buildLocationString = () => {
    const baseString = `This project is located in ${city ? `${city}, ` : ""}${
      state ? `${state} ` : ""
    } ${country}`;
    const distanceMiString = `${formatDecimal(
      Math.round(distanceMi)
    )} miles from your primary office`;

    if (!distanceMi) {
      return `${baseString}.`;
    }

    return `${baseString}, ${distanceMiString}.`;
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle2">{`${tagline} This is an emissions ${mechanism.toLowerCase()} project.`}</Typography>
      </Grid>
      <Grid item>
        <OverviewImagesCarousel images={images} />
      </Grid>
      <Grid item>
        <Paper style={{ overflow: "hidden", position: "relative" }}>
          <GoogleMap height="250px" markers={[project]} />
          <Box
            p={2}
            style={{
              background:
                "linear-gradient(135deg, rgba(39,170,225,1) 0%, rgba(57,181,74,1) 100%)",
              color: "white",
            }}
          >
            <Typography variant="body1">{buildLocationString()}</Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

const CNaughtOverviewTab = () => (
  <Grid container direction="column" spacing={2}>
    <Grid item container justifyContent="center">
      <Grid item>
        <img
          src="/images/partner-logos/cnaught.svg"
          width={150}
          alt="cnaught logo"
        />
      </Grid>
    </Grid>
    <Grid item>
      <Typography variant="body1" paragraph>
        We partner with CNaught to purchase a diversified portfolio of
        high-quality carbon offsets that is designed to maximize impact,
        mitigate risk, and foster innovation. A portfolio approach to offsets is
        recommended by the World Economic Forum, and the CNaught portfolio
        embodies the science-based best practices laid out in Oxford’s
        Principles for Carbon Offsetting.
      </Typography>
    </Grid>
  </Grid>
);

const UnSdgsTab = ({ unSdgs = [] }) => {
  if (unSdgs.length) {
    return (
      <Grid container spacing={1} justifyContent="space-around">
        {unSdgs.map((number) => (
          <Grid item key={`sdg-${number}`}>
            <Link
              href={`https://sdgs.un.org/goals/goal${number}`}
              target="_blank"
              rel="noopener"
            >
              <img
                src={`https://aclymate.app/images/un-sdgs/${number}.png`}
                alt={`UN SDG ${number}`}
                width={150}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Typography variant="h4" align="center">
          This project has not registered any UN SDG goals with Aclymate.
        </Typography>
      </Grid>
    </Grid>
  );
};

const OffsetDetailsPurchaseSlider = ({
  project = {},
  projectLoading,
  ProjectPurchaseFlow,
  purchaseFlowProps = {},
  onSlideClose,
  isCNaughtPurchase,
}) => {
  const theme = useTheme();
  const { isMedium } = useLayoutHelpers();

  const { type, name, description = [], categories } = project;

  const [selectedViewIdx, setSelectedViewIdx] = useState(0);

  useEffect(() => {
    googlePurchaseTracking("view_item", project);
  }, [project]);

  const mobilePurchaseTab =
    isMedium && ProjectPurchaseFlow
      ? [
          {
            label: "Purchase",
            value: "purchase",
            content: (
              <ProjectPurchaseFlow
                project={project}
                projectLoading={projectLoading}
                isCNaughtPurchase={isCNaughtPurchase}
                {...purchaseFlowProps}
              />
            ),
          },
        ]
      : [];

  const buildProjectDescription = () => {
    if (isCNaughtPurchase) {
      return (
        <Typography variant="body2">{CNAUGHT_PROJECT_DESCRIPTION}</Typography>
      );
    }

    if (Array.isArray(description)) {
      return description.map((paragraph, idx) => (
        <Typography
          key={`description-paragraph-${idx}`}
          variant="body2"
          paragraph
        >
          {paragraph}
        </Typography>
      ));
    }

    return <Typography variant="body2">{description}</Typography>;
  };

  const sdgsContent = isCNaughtPurchase
    ? { unSdgs: [11, 12, 13, 14, 15, 16] }
    : project;

  const verificationContent = isCNaughtPurchase
    ? {
        registryUrl: "https://www.cnaught.com/science-backed-portfolio",
        registryImage: "/images/partner-logos/cnaught.svg",
        label:
          "Cnaught uses their own methodology to vet the projects included in their curated portfolio",
        buttonText: "Learn More",
      }
    : project;

  const views = [
    ...mobilePurchaseTab,
    {
      label: "Overview",
      value: "overview",
      content: isCNaughtPurchase ? (
        <CNaughtOverviewTab />
      ) : (
        <ProjectOverviewTab project={project} />
      ),
    },
    {
      label: "story",
      value: "description",
      content: buildProjectDescription(),
    },
    {
      label: "UN SDGs",
      value: "sdgs",
      content: <UnSdgsTab {...sdgsContent} />,
    },
    {
      label: "verification",
      value: "verification",
      content: <VerificationTab {...verificationContent} />,
    },
  ];

  const onTabClick = (value) => {
    const tabValueIndex = views.findIndex((tab) => tab.value === value);
    return setSelectedViewIdx(tabValueIndex);
  };

  const { value, content } = views[selectedViewIdx] || {};

  return (
    <>
      {!projectLoading && (
        <SlideLayout
          isSlideOpen
          setIsSlideOpen={onSlideClose}
          header={
            <Grid container direction="column" spacing={1}>
              <Grid
                item
                container
                spacing={2}
                alignItems="center"
                wrap="nowrap"
              >
                {categories && (
                  <Grid item>
                    <Grid container spacing={1}>
                      {categories.map(({ icon, name }, idx) => (
                        <Grid item key={`category-avatar-${idx}`}>
                          <Tooltip title={name}>
                            <Avatar
                              style={{
                                height: `${theme.spacing(4)}px`,
                                width: `${theme.spacing(4)}px`,
                                backgroundColor: theme.palette.secondary.main,
                              }}
                            >
                              <FontAwesomeIcon icon={icon} size="lg" />
                            </Avatar>
                          </Tooltip>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                {isCNaughtPurchase && (
                  <Grid item>
                    <img
                      src="/images/partner-logos/cnaught.svg"
                      alt="cnaught logo"
                      style={{ width: "32px" }}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Typography variant="h6" component="h1">
                    {isCNaughtPurchase ? "CNaught Project Portfolio" : type}
                  </Typography>
                  {name && <Typography variant="body2">{name}</Typography>}
                </Grid>
              </Grid>
            </Grid>
          }
          tagInputs={
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Alert
                  severity="success"
                  icon={<InfoIcon fontSize="inherit" />}
                  action={
                    <Link
                      href="https://aclymate.com/about-offsets"
                      target="_blank"
                      rel="noopener"
                      underline="none"
                    >
                      <Button color="secondary">Learn More</Button>
                    </Link>
                  }
                >
                  Get more information about offsets and how to select the right
                  project for your business.
                </Alert>
              </Grid>
              <Grid item>
                <Tabs
                  value={value}
                  onChange={(_, value) => onTabClick(value)}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {views.map(({ label, value }, idx) => (
                    <Tab
                      label={label}
                      value={value}
                      key={`object-details-view-tab-${idx}`}
                    />
                  ))}
                </Tabs>
                <Divider />
              </Grid>
            </Grid>
          }
          content={
            projectLoading ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <AnimatedLogo />
              </Box>
            ) : (
              content
            )
          }
          footer={
            value === "sdgs" && (
              <Grid
                container
                justifyContent="center"
                spacing={2}
                direction="row"
              >
                <Grid item>
                  <img
                    alt="UN SDGs Banner"
                    src="https://aclymate.app/images/un-sdgs/un_sdgs_banner.png"
                    style={{ width: "350px" }}
                  />
                </Grid>
                <Grid item>
                  <Link
                    href="https://sdgs.un.org/goals"
                    target="_blank"
                    rel="noopener"
                    underline="none"
                  >
                    <Button variant="contained" color="secondary">
                      Learn More
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            )
          }
          footerColor={
            value === "sdgs" && hexToRgba(theme.palette.secondary.main, 0.1)
          }
          leftContent={
            !isMedium &&
            ProjectPurchaseFlow && (
              <ProjectPurchaseFlow
                project={project}
                projectLoading={projectLoading}
                isCNaughtPurchase={isCNaughtPurchase}
                {...purchaseFlowProps}
              />
            )
          }
        />
      )}
    </>
  );
};
export default OffsetDetailsPurchaseSlider;
