import React, { useState } from "react";

import { Grid } from "@mui/material";

import DetailsActionAlert from "../../atoms/notifications/DetailsActionAlert";
import EmissionsDetailsAccordionBlock from "../../layouts/EmissionsDetailsAccordionBlock";
import FetchEmissionsTableBlock from "../../modules/tables/FetchEmissionsTableBlock";

import useToggleableEmissionsDetailsChart from "../../modules/charts/useToggleableEmissionsDetailsChart";

const EmissionsGraphAndPieChart = ({
  type,
  emissions,
  showCharts,
  onChange,
}) => {
  const { toggles, chart } = useToggleableEmissionsDetailsChart(emissions);

  return (
    <EmissionsDetailsAccordionBlock
      expanded={showCharts}
      onChange={onChange}
      type={type}
      title="Emissions Charts"
      blockAction={toggles}
      details={chart}
    />
  );
};

const EmissionsDetailsBlock = ({
  closeSelectedObjectSlider,
  setSelectedTransaction,
  emissions,
  type,
  warning,
  name,
  chartEmissions,
  id,
}) => {
  const removedZeroTonsFormattedEmissions = emissions.filter(
    ({ tonsCo2e, electricRenewablesPercentage }) =>
      tonsCo2e || electricRenewablesPercentage === 100
  );

  const [showCharts, setShowCharts] = useState(true);
  const onAccordionClick = () => setShowCharts((currentState) => !currentState);

  return (
    <Grid container item spacing={2} direction="column">
      {warning && <DetailsActionAlert {...warning} />}
      <Grid item>
        <EmissionsGraphAndPieChart
          emissions={chartEmissions || removedZeroTonsFormattedEmissions}
          type={type}
          showCharts={showCharts}
          onChange={onAccordionClick}
        />
      </Grid>
      <Grid item>
        <FetchEmissionsTableBlock
          type={type}
          emissions={removedZeroTonsFormattedEmissions}
          id={id}
          name={name}
          showCharts={showCharts}
          setSelectedTransaction={setSelectedTransaction}
          closeSelectedObjectSlider={closeSelectedObjectSlider}
          onAccordionClick={onAccordionClick}
        />
      </Grid>
    </Grid>
  );
};
export default EmissionsDetailsBlock;
