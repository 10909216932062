import React from "react";

import LabeledEmissionsChart from "./LabeledEmissionsChart";
import { useEmissionsChartData } from "../../../helpers/components/emissions";

const EmissionsChart = ({
  dataArray: emissions,
  type,
  viewMode = "subcategories",
  graphPeriod,
  displayUnit,
  aspect,
  isPercentageChart,
  showTooltip = true,
  startDate,
  branding,
  netZeroPercentage,
  onBarClick,
  selectedBarIdx,
  ...otherProps
}) => {
  const { chartData, chartArray } = useEmissionsChartData({
    emissions,
    viewMode,
    graphPeriod,
    branding,
    isPercentageChart,
  });

  return (
    <LabeledEmissionsChart
      dataArray={chartData}
      type={type}
      isPercentageChart={isPercentageChart}
      displayUnit={displayUnit}
      chartArray={chartArray}
      aspect={aspect}
      showTooltip={showTooltip}
      graphPeriod={graphPeriod}
      netZeroPercentage={netZeroPercentage}
      onBarClick={onBarClick}
      selectedBarIdx={selectedBarIdx}
      {...otherProps}
    />
  );
};
export default EmissionsChart;
